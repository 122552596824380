import kebabCase from 'lodash/kebabCase';
import { PrimaryEngagementType, PrimaryFormOfCompensation } from 'shared/dist/src/types/talent/commercialEngagements';
import { enums, types } from 'shared';
const { EngagementsType } = enums

export const getAvailableEngagementTypes = (t) => {
  const prefixForT = 'commercial-engagements.form.primary-engagement-type.options';
  return [
    {
      value: PrimaryEngagementType.AMBASSADOR_SPOKESPERSON,
      label: t(`${prefixForT}.ambassador-spokesperson`),
    },
    {
      value: PrimaryEngagementType.PRODUCT_PLACEMENT,
      label: t(`${prefixForT}.product-placement`),
    },
    {
      value: PrimaryEngagementType.SPORTS_TOURNAMENT,
      label: t(`${prefixForT}.sports-tournament`),
    },
    {
      value: PrimaryEngagementType.CAUSE_RELATED_MARKETING,
      label: t(`${prefixForT}.cause-related-marketing`),
    },
    {
      value: PrimaryEngagementType.SOCIAL_MEDIA_CAMPAIGN,
      label: t(`${prefixForT}.social-media-campaign`),
    },
    {
      value: PrimaryEngagementType.PRODUCT_ENDORSEMENT,
      label: t(`${prefixForT}.product-endorsement`),
    },
    {
      value: PrimaryEngagementType.EVENT_APPEARANCE,
      label: t(`${prefixForT}.event-appearance`),
    },
    {
      value: PrimaryEngagementType.EMCEE_SPEAKER,
      label: t(`${prefixForT}.emcee-speaker`),
    },
    {
      value: PrimaryEngagementType.ARTISTIC_PERFORMANCE,
      label: t(`${prefixForT}.artistic-performance`),
    },
    {
      value: PrimaryEngagementType.VIDEO_NARRATION,
      label: t(`${prefixForT}.video-narration`),
    },
  ];
};

export const getPrimaryEngagementFormOfCompensation = (t) => {
  const prefixForT = 'commercial-engagements.form.primary-form-of-compensation.options';
  return [
    {
      value: PrimaryFormOfCompensation.ONE_TIME_PAYMENT,
      label: t(`${prefixForT}.one-time-payment`),
    },
    {
      value: PrimaryFormOfCompensation.EQUITY_SHARING,
      label: t(`${prefixForT}.equity-sharing`),
    },
    {
      value: PrimaryFormOfCompensation.REVENUE_SHARING,
      label: t(`${prefixForT}.revenue-sharing`),
    },
    {
      value: PrimaryFormOfCompensation.LICENSED_ENDORSEMENT,
      label: t(`${prefixForT}.licensed-endorsement`),
    },
    {
      value: PrimaryFormOfCompensation.DISCOUNTS_OR_FREE_PRODUCT,
      label: t(`${prefixForT}.discounts-of-free-product`),
    },
    {
      value: PrimaryFormOfCompensation.COMBINATION_OF_THE_ABOVE,
      label: t(`${prefixForT}.combination-of-the-above`),
    },
  ];
};

export const getPrimaryEngagementTypeOptions = t => {
  const prefixForT = 'commercial-engagements.form.primary-engagement-type.options';
  return [
    {
      value: EngagementsType.SPORTS_TOURNAMENTS,
      label: t(`${prefixForT}.${kebabCase(EngagementsType.SPORTS_TOURNAMENTS)}`)
    },
    {
      value: EngagementsType.SPORTS_TOURNAMENTS_2,
      label: t(`${prefixForT}.${kebabCase(EngagementsType.SPORTS_TOURNAMENTS_2)}`)
    }
  ];
};

export const geHiringEntitiesOptions = t => {
  const prefixForT = 'commercial-engagements.form.primary-engagement-type.options';
  return [
    {
      value: types.talent.commercialEngagements.HiringEntity.COMPANY,
      label: t(`${prefixForT}.company`)
    },
    {
      value: types.talent.commercialEngagements.HiringEntity.PERSON,
      label: t(`${prefixForT}.person`)
    },
    {
      value: types.talent.commercialEngagements.HiringEntity.CHARITY,
      label: t(`${prefixForT}.charity`)
    }
  ];
}
export const submitAdapter = (data) => ({
  ...data,
  phone: data.phone.length ? data.phone : null,
  minimumBudget: parseInt(data.minimumBudget) || null,
  maximumBudget: parseInt(data.maximumBudget) || null,
  primaryEngagementType: data.primaryEngagementType
    ? data.primaryEngagementType.value
    : null,
  primaryFormOfCompensation: data.primaryFormOfCompensation
    ? data.primaryFormOfCompensation.value
    : null,
});
