import * as t from '_legacy/components/Modal/modals-types';
import ChangeRouteModal from '_legacy/components/Modal/ChangeRouteModal.js'
import SelectJoinToTalentModal from '_legacy/components/SelectJoinToTalent';
import JoinToTalentModal from '_legacy/components/JoinToTalentModal';
import ModalCustomSelects from '_legacy/containers/PromoCodes/components/Custom/ModalCustomSelects';
import { ApplicableOfferingsHint, DiscountHint, ExpirationDateHint, MaximumOfUsesHint } from '_legacy/components/Modal/PromoCodes';
import PromotionalMediaAskModal from './PromotionalMedia/PromotionalMediaAskModal'
import PromotionalMediaLibraryModal from './PromotionalMedia/PromotionalMediaLibraryModal'
import TermServices from './Terms/TermServices';
import TermPrivacyPolice from './Terms/TermPrivacyPolice';
import PurchaseConfirm from './PurchaseConfirm';
import RestorePasswordModal from './RestorePasswordModal';
import PaymentModal from './PaymentModal';
import SelectPromotionalMedialModal from './SelectPromotionalMedialModal'
import RemoveMediaModal from './RemoveMediaModal'


const modalsConfig = {
  [t.SELECT_JOIN_TO_TALENT_MODAL]: SelectJoinToTalentModal,
  [t.JOIN_TO_TALENT_MODAL]: JoinToTalentModal,
  [t.CHANGE_ROUTE_MODAL]: ChangeRouteModal,
  [t.SELECT_JOIN_TO_TALENT_MODAL]: SelectJoinToTalentModal,
  [t.JOIN_TO_TALENT_MODAL]: JoinToTalentModal,
  [t.PROMO_CODES_DISCOUNT_HIT]: DiscountHint,
  [t.PROMO_CODES_APPLICABLE_OFFERINGS_HIT]: ApplicableOfferingsHint,
  [t.PROMO_CODES_EXPIRATION_DATE_HIT]: ExpirationDateHint,
  [t.PROMO_CODES_MAXIMUM_OF_USES_HIT]: MaximumOfUsesHint,
  [t.PROMO_CODES_SELECTS_OFFERINGS]: ModalCustomSelects,
  [t.PROMOTIONAL_MEDIA_ASK_MODAL]: PromotionalMediaAskModal,
  [t.PROMOTIONAL_MEDIA_LIBRARY_MODAL]: PromotionalMediaLibraryModal,
  [t.TERM_SERVICES]: TermServices,
  [t.TERM_PRIVACY_POLICE]: TermPrivacyPolice,
  [t.PURCHASE_CONFIRM]: PurchaseConfirm,
  [t.RESTORE_PASSWORD]: RestorePasswordModal,
  [t.PAYMENT_MODAL]: PaymentModal,
  [t.UPLOAD_MEDIA]: RestorePasswordModal,
  [t.SELECT_PROMOTIONAL_MEDIA_MODAL]: SelectPromotionalMedialModal,
  [t.REMOVE_MEDIA_MODAL]: RemoveMediaModal,
}

export default modalsConfig;
