import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components';
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import DefaultPicture from '_legacy/components/Library/components/Picture/Picture'
import DefaultBackdrop from '_legacy/components/Library/components/Backdrop/Backdrop'
import ModalButton from '_legacy/components/Library/components/Modal/ModalButton'
import Loader from '_legacy/components/Common/Loader';

import { useFileEntityById } from 'store/hooks/globalState/useFileEntites'

const SingleSelectLibraryButton = ({
  name,
  onClick,
  maxSize = 200,
  customBackdrop: CustomBackdrop,
  customPicture: CustomPicture,
  isLibraryOpen,
}) => {
  const { watch, errors } = useFormContext()
  const error = !isEmpty(get(errors, name))
  const fileToken = watch(name)
  const file = useFileEntityById(fileToken)
  const Backdrop = CustomBackdrop || DefaultBackdrop
  const Picture = CustomPicture || DefaultPicture
  if (file?.isLoading) {
    return (
      <StyledLoaderWrapper className='loader-wrapper'>
        <Loader size={32} fill='#636583' />
      </StyledLoaderWrapper>
    )
  }

  return (
    <ModalButton error={error} className="single-select__library-button" onClick={onClick}>
      {fileToken && !isLibraryOpen
        ? <Picture name={name} file={file} />
        : <Backdrop maxSize={maxSize} />
      }
    </ModalButton>
  )
}

const StyledLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #BABBCD;
`

SingleSelectLibraryButton.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  isLibraryOpen: PropTypes.bool,
  customPicture: PropTypes.element,
  customBackdrop: PropTypes.element,
}

export default SingleSelectLibraryButton
