import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import compose from 'lodash/fp/compose'
import isEmpty from 'lodash/isEmpty'
import prop from 'lodash/fp/prop'

import { useEditDraftProfile, useFetchEditProfile, useRegisterProfileFromDraft } from 'requests/edit-profile'
import { useUser } from 'store/hooks/globalState/useUser'
import { useEditProfile, useSetEditProfile } from 'store/hooks/globalState/useEditProfile'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useSetPathToNavigate } from 'store/hooks/globalState/useSwitchNavigation'
import { useTalentCategories } from 'store/hooks/globalState/useCategories'
import { getTalentId } from 'utils/user'

import { Container, Title } from '_legacy/common'
import RegistrationFormTalent from '_legacy/components/RegisterForms/Talent'
import defaultValues from '_legacy/components/RegisterForms/Talent/utils/defaultValues'
import submitAdapter from '_legacy/components/RegisterForms/Talent/utils/submitAdapter'
import getAdapter from '_legacy/components/RegisterForms/Talent/utils/getAdapter'
import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler'
import { getDirtyValues } from '_legacy/components/RegisterForms/ProfileEdit/utils/getDirtyValues'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'

const RegistrationTalentDraft = () => {
  useSetPathToNavigate({ talentRoute: '/', agentRoute: '/' })

  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const fetchProfile = useFetchEditProfile()
  const allCategories = useTalentCategories()
  const talentDraft = useEditProfile(prop('data.talent'))
  const clearDraft = useSetEditProfile(() => null)

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    shouldUnregister: false,
  })

  const getDiff = allValues => getDirtyValues(methods.formState.dirtyFields, allValues)

  const completeRegisterAsTalent = useRegisterProfileFromDraft()
  const completeRegisterAsTalentHandler = compose(completeRegisterAsTalent, submitAdapter, getDiff)

  const editDraftProfile = useEditDraftProfile()
  const editDraftProfileHandler = compose(editDraftProfile, submitAdapter, getDiff, methods.getValues)

  // fetch profile
  React.useEffect(() => {
    fetchProfile()
  }, [talentId])

  // set profile to form
  React.useEffect(() => {
    if (talentDraft) {
      methods.reset(getAdapter(talentDraft, t, allCategories), { dirtyFields: false, isDirty: false })
    }
  }, [talentDraft])

  React.useEffect(() => () => {
    clearDraft()
  }, [clearDraft])


  return (
    <>
      <ChangeRouteHandler
        shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
        onDraft={editDraftProfileHandler}
      />
      <StyledWrapper>
        <Container>
          <Title className="title">{t('register.talent.header')}</Title>
          <RegistrationFormTalent
            methods={methods}
            onSubmit={completeRegisterAsTalentHandler}
            onDraft={editDraftProfileHandler}
          />
        </Container>
      </StyledWrapper>
    </>
  )
}

const StyledWrapper = styled.div`
  margin-top: 50px;
`

export default withLibrary(RegistrationTalentDraft)
