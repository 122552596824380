// TODO: refactor component (Formatting, remove Styled, module structure)

import React from 'react';
import styled from 'styled-components';

import TermsModal from '_legacy/components/Modal/TermsModal';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const LegalTermsList = () => {
  const t = useGetTranslate();
  const [modalState, setModalState] = React.useState(false);

  const handleOpenLegalModal = () => setModalState(true);

  const handleCloseLegalModal = () => setModalState(false);

  return (
    <StyledLegalTerms>
      <ul className="list">
        <li className="list__item">{t('vmcu.information.content.valid')}</li>
        <li className="list__item">{t('vmcu.information.content.re-takes')}</li>
        <li className="list__item">
          {t('vmcu.information.content.marketing')}
        </li>
        <li className="list__item">{t('vmcu.information.content.formats')}</li>
        <li className="list__item">
          {t('vmcu.information.content.customer')}
        </li>
        <li className="list__item">
          {t('vmcu.information.content.other')}
        </li>
      </ul>
      <button type="button" onClick={handleOpenLegalModal} className="button">
        {t('vmcu.information.content.legal-button')}
      </button>
      <TermsModal
        closeModal={handleCloseLegalModal}
        isModalOpen={modalState}
        title="shared.terms.legal.title"
      />
    </StyledLegalTerms>
  );
};

const StyledLegalTerms = styled.div`
  .list {
    margin-left: 14px;
    list-style-type: disc;

    &__inner {
      margin-top: 12px;
      margin-left: 14px;
      list-style-type: circle;
    }

    &__item {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #626473;
      position: relative;
      //padding-left: 14px;
      &::marker {
        font-size: 14px;
        color: #626473;
      }

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  .button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.62;
    color: #272835;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin-top: 12px;
    cursor: pointer;
  }
`;

export default LegalTermsList;
