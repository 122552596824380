import React from 'react';
import PropTypes from 'prop-types';
import BookNowButton from '../../../components/BookNowButton';
import BasicMobileOverlay from '../../../Overlay/BasicMobileOverlay';

const Overlay = ({ onBookNowClick }) => {
  return (
    <BasicMobileOverlay withGradientOverhead={true}>
      <div className="tbv-slider-slide-overlay-live-experience">
        <BookNowButton onClick={onBookNowClick} />
      </div>
    </BasicMobileOverlay>
  );
};

Overlay.propTypes = {
  onBookNowClick: PropTypes.func.isRequired,
};

export default Overlay;
