import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PublicSubmitButton from './PublicSubmitButton';

const PublicInfoForm = ({ pricePerParticipant, textBelow, children, onSubmit, isSubmitting, buttonProps }) => {
  return (
    <StyledExperienceInfo className="wrapper content-wrapper" onSubmit={onSubmit}>
      {children}
      <PublicSubmitButton
        {...buttonProps}
        isSubmitting={isSubmitting}
        pricePerParticipant={pricePerParticipant}
        textBelow={textBelow}
      />
    </StyledExperienceInfo>
  );
};

export const StyledExperienceInfo = styled.form`
  font-family: Playfair Display;
  font-weight: 900;
  margin: 40px auto;
  h3 {
    font-size: 2.625em;
    line-height: 54px;
    color: #ffffff;
    text-align: center;
    margin: 90px 0 12px;
    &.alternate {
      margin: 90px 0 24px;
    }
    @media only screen and (max-width: 1024px) {
      margin: 68px 0;
      font-size: 1.75em;
    }
    @media only screen and (max-width: 767px) {
      font-size: 1.375em;
      margin: 0 0 68px 0;
    }
  }
`;

PublicInfoForm.propTypes = {
  pricePerParticipant: PropTypes.number,
  textBelow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  participantsCount: PropTypes.number,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
}

export default PublicInfoForm;
