import { OrderStatus } from 'shared'

import { getMenuConfig } from './index'
import { useUser } from 'store/hooks/globalState/useUser'
import {
  getShouldShowRegisterTalentLink,
  getTalentPlaqueNickname,
  getUserPlaqueNickname,
  isUserHasTalent,
} from 'utils/user'
import { useHasUnreadChatMessages } from 'store/hooks/globalState/useChats'
import { useStateModal } from 'store/hooks/globalState/useModal'
import { SELECT_JOIN_TO_TALENT_MODAL } from '_legacy/components/Modal/modals-types'
import { useSetNotificationsTableFilters } from 'store/hooks/globalState/useNotificationsState'
import { useSetMyBookingsFilters } from 'components/MyBookings/state/store'
import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'
import { useUserLang } from 'store/hooks/globalState/useTranslates'
import { useCurrencyCode } from 'store/hooks/globalState/useCurrency'

import { getIsMobile } from 'utils/isMobile'
import { getCurrencyAndLanguageLabel } from 'utils/currencyAndLanguage/currencyAndLanguage'

export function useNavigationMenu() {
  const userLang = useUserLang()
  const currencyCode = useCurrencyCode()
  const isTalent = useUser(isUserHasTalent)
  const userNameLink = useUser(getTalentPlaqueNickname)
  const plaqueNickname = useUser(getUserPlaqueNickname)
  const hasUnreadChatMessages = useHasUnreadChatMessages()
  const inviteManagerHandler = useStateModal(SELECT_JOIN_TO_TALENT_MODAL)
  const setNotificationTableFilter = useSetNotificationsTableFilters((prev, type) => ({ ...prev, type }))
  const setMyBookingsTableFilter = useSetMyBookingsFilters((prev, orderStatusIn) => ({ ...prev, orderStatusIn }))
  const isMobile = useWindowDimensions(getIsMobile)
  const shouldShowRegisterTalentLink = useUser(getShouldShowRegisterTalentLink)
  const languageAndCurrencyLabel = getCurrencyAndLanguageLabel(userLang, currencyCode)

  return getMenuConfig({
    isTalent,
    userNameLink,
    isMobile,
    inviteManagerHandler,
    hasUnreadChatMessages,
    plaqueNickname,
    languageAndCurrencyLabel,
    remindersHandler: () => setNotificationTableFilter('reminders'),
    accountRelatedHandler: () => setNotificationTableFilter('accountRelated'),
    communityCommunicationHandler: () => setNotificationTableFilter('communityCommunication'),
    underReviewHandler: () => setMyBookingsTableFilter(OrderStatus.UNDER_REVIEW),
    approvedHandler: () => setMyBookingsTableFilter(OrderStatus.APPROVED),
    rejectedHandler: () => setMyBookingsTableFilter(OrderStatus.REJECTED),
    bookedHandler: () => setMyBookingsTableFilter(OrderStatus.BOOKED),
    adminReviewHandler: () => setMyBookingsTableFilter(OrderStatus.ADMIN_REVIEW),
    completedHandler: () => setMyBookingsTableFilter(OrderStatus.COMPLETED),
    cancelledHandler: () => setMyBookingsTableFilter(OrderStatus.CANCELED),
    closedHandler: () => setMyBookingsTableFilter(OrderStatus.CLOSED),
    shouldShowRegisterTalentLink
  })
}
