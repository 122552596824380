// Modules
import React from 'react'
import { isEqual, isFuture } from 'date-fns'
import filter from 'lodash/fp/filter'

// Assets
import Icon from 'assets/icons/Icon'

// Store (Hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useModal } from 'store/hooks/useModal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useAcceptOrderRequest,
  useFetchLiveInPersonLessonOrderDetails,
  useRejectOrderRequest,
  useUpdateLiveInPersonOccurrenceTime,
} from './state/request'
import { useLiveInPersonOrderDetails, useSetLiveInPersonOrderDetails } from './state/store'

// Utils
import { getSaveFullName, getUserLink, getUserTimeZone } from 'utils/user'
import { getLocationLabel, getLocationPlace } from 'utils/location'
import { getDateTimeProps, getMonthDayOnly, getTimeRange } from 'utils/date'
import { getTimeZoneFromOccurrenceLocation } from 'utils/forms/adapters/timezone'
import { prepareDate } from 'utils/services/timezone'
import { getSteps } from 'components/OfferingDetails/components/StatusBar/utils/getSteps'
import { getCharitiesTitle, getComplaintText, isOccurrenceFullyBooked } from 'components/OfferingDetails/utils'
import { getLiveVirtualLessonOrderConfig } from './utils'
import { toOrderPageCalendarEvents } from 'components/Calendars/utils/adapters'
import { talentCalendarSidebarFilters } from 'components/Calendars/configs/filters'

// Components
import StatusBar from 'components/OfferingDetails/components/StatusBar'
import PendingAction from 'components/OfferingDetails/components/PendingAction'
import BookingCalendar from 'components/Calendars/BookingCalendar/BookingCalendar'
import OrderUploadFiles from 'components/OfferingDetails/components/OrderUploadFiles'
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'
import OfferingsDetailsHeaderHold from 'components/OfferingDetails/components/OfferingsDetailsHeaderHold'
import OfferingDetailsGenericStatus from 'components/OfferingDetails/components/OfferingDetailsGenericStatus'
import OfferingDetailsReviewStars from 'components/OfferingDetails/components/OfferingDetailsReviewStars'
import OfferingDetailsEscrowFunds from 'components/OfferingDetails/components/OfferingDetailsEscrowFunds'
import RejectModal from 'components/OfferingDetails/components/RejectModal'
import ComplaintInfo from 'components/OfferingDetails/components/ComplaintInfo'
import CancelOrderButton from 'components/OfferingDetails/components/CancelOrderButton'

// Legacy components
import Button from '_legacy/components/Button'
import UserInfo from '_legacy/components/UserInfo'
import PageLoader from '_legacy/common/Template/PageLoader'
import DashButton from '_legacy/components/Button/DashButton'
import PopperTooltip from '_legacy/components/Tooltip/PopperTooltip'
import TalentPublicViewCalendar from 'components/Calendars/TalentPublicViewCalendar'


const LiveInPersonLessonOrderDetails = () => {
  const t = useGetTranslate()
  const authorizedUser = useUser()
  const userTimeZone = useUser(getUserTimeZone)

  const [orderSlot, setOrderSlot] = React.useState(null)
  const occurrence = useLiveInPersonOrderDetails()
  const clearOccurrence = useSetLiveInPersonOrderDetails(() => null)
  const { review, order } = occurrence || {}

  // requests
  const fetchOccurrence = useFetchLiveInPersonLessonOrderDetails()
  const acceptOrderRequest = useAcceptOrderRequest()
  const rejectOrderRequest = useRejectOrderRequest()
  const updateLiveInPersonOccurrenceTime = useUpdateLiveInPersonOccurrenceTime()

  // modals
  const [isOpenCalendarModal, openCalendarModal, closeCalendarModal] = useModal(false)
  const [isOpenRejectModal, openRejectModal, closeRejectModal] = useModal(false)
  const [isOpenTalentCalendar, openTalentCalendarModal, closeTalentCalendarModal] = useModal(false)

  // effects
  React.useEffect(() => {
    fetchOccurrence()
  }, [])

  React.useEffect(() => {
    if (occurrence) {
      setOrderSlot({
        title: occurrence.title,
        start: occurrence.startTime,
        end: occurrence.endTime,
      })
    }
  }, [occurrence])

  React.useEffect(() => () => {
    clearOccurrence()
  }, [])

  if (!occurrence) return <PageLoader />

  const { title, user, userUploads, location, lengthOfTime, pendingAction, totalCharityFee } = occurrence || {}

  const timeZone = getTimeZoneFromOccurrenceLocation(occurrence)
  const { formattedDate, formattedTimeRange, startTime, endTime } = getDateTimeProps({ ...occurrence, timeZone })

  const onCalendarConfirm = formData => {
    updateLiveInPersonOccurrenceTime({
      startTime: prepareDate(formData?.start, timeZone),
      endTime: prepareDate(formData?.end, timeZone)
    })
    closeCalendarModal()
  }

  const beforeTransform = filter(slot => !isEqual(new Date(slot.start), new Date(occurrence.startTime)))

  const c = getLiveVirtualLessonOrderConfig(occurrence)

  const cancelOrderButton = <CancelOrderButton orderId={order.id} onSuccess={fetchOccurrence} />
  const complaintText = getComplaintText({ complaints: occurrence.complaints })

  return (
    <div className="video-voice-over-lesson-order od__margins">
      <OfferingDetailsTitle
        typeHeader={t('shared.offers.live-in-person-lesson')}
        title={`${t('shared.words.order')} #${order.plaqueNumber}`}
      />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <StatusBar steps={getSteps(occurrence, userTimeZone)} />
          {pendingAction && <PendingAction action={pendingAction} title={t('shared.offers.live-in-person-lesson')} />}

          <div className="od__inner-wrapper">
            <div className="od__row od__column-m">
              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('order.video-message.aficionado')}</h6>
                <UserInfo user={user} />

                <div className="od__column mt-32">
                  {startTime && endTime && (
                    <div className="od__row">
                      <OfferingsDetailsHeaderItem
                        icon={<Icon.Calendar />}
                        item={formattedDate}
                        subitem={formattedTimeRange}
                      />
                      {isFuture(new Date(startTime)) && isOccurrenceFullyBooked(occurrence) && (
                        <div className="od__column">
                          <DashButton
                            className="mr-16"
                            onClick={openCalendarModal}
                            text={t('order-details.virtual-chats.change-date')}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {location.place && (
                    <OfferingsDetailsHeaderItem
                      icon={<Icon.Pin />}
                      item={getLocationPlace(location.place)}
                      subitem={getLocationLabel(location.place)}
                    />
                  )}
                </div>
              </div>

              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">
                  {t('order-details.live-virtual-lesson.submitted-by')} {user?.firstName}
                </h6>
                <p className="od--main-text od--accented">
                  <OrderUploadFiles files={userUploads} />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="od--shadow-box od--centered">
            {c.isShowWaitingForApprove && (
              <div className="od--with-padding">
                <h3 className="od--uppercase-title mb-32">{t('order.header.accept-or-reject')}</h3>
                <button
                  type="button"
                  className="od__answer-calendar-btn od--uppercase-title"
                  onClick={openTalentCalendarModal}
                >
                  <Icon.ViewCalendar className="od__answer-calendar-btn__icon" />
                  {t('order.answer.button.view-my-calendar')}
                </button>
                <Button
                  stretch
                  className="mb-16"
                  paddingVertical={16}
                  text={t('shared.words.accept')}
                  handleOnClick={() => acceptOrderRequest([order.id])}
                />
                <Button
                  stretch
                  primary
                  paddingVertical={16}
                  text={t('shared.words.reject')}
                  handleOnClick={() => openRejectModal()}
                />
              </div>
            )}

            {c.isShowHold && (
              <OfferingsDetailsHeaderHold
                title={t('shared.offers.live-in-person-lesson')}
                date={getMonthDayOnly(startTime)}
                duration={getTimeRange([startTime, endTime])}
                locationMain={getLocationPlace(location.place)}
                locationFull={getLocationLabel(location.place)}
                typeText={t('shared.offers.live-in-person-lesson')}
              >
                {cancelOrderButton}
              </OfferingsDetailsHeaderHold>
            )}

            {c.isShowCalendar && (
              <div className="od--with-padding">
                <h3 className="od--uppercase-title mb-12">{t('shared.offers.live-in-person-lesson')}</h3>
                <PopperTooltip isStretch Component={t('order-details.tooltip.date-time')}>
                  <Button
                    stretch
                    paddingVertical={16}
                    iconComponent={<Icon.ViewCalendar fill="#fff" />}
                    text={t('shared.words.purpose-date')}
                    handleOnClick={openCalendarModal}
                    className="mb-24"
                  />
                </PopperTooltip>
                {cancelOrderButton}
              </div>
            )}

            {c.isShowRejected && (
              <OfferingDetailsGenericStatus
                title={t('shared.offers.live-in-person-lesson')}
                type={t('order.header.rejected')}
              />
            )}

            {c.isShowCanceled && (
              <OfferingDetailsGenericStatus
                title={t('shared.offers.live-in-person-lesson')}
                type={t('order.header.cancelled')}
                reasonText={complaintText}
              />
            )}

            {c.isShowInProgress && (
              <OfferingDetailsGenericStatus
                title={t('shared.offers.live-in-person-lesson')}
                type={t('order.header.in-progress')}
              />
            )}

            {c.isShowCompleted && (
              <React.Fragment>
                <OfferingDetailsEscrowFunds
                  title={t('shared.offers.live-in-person-lesson')}
                  subtitle={t('order.description.funds')}
                  price={order?.totalPrice}
                  description={t('order.description.live-in-person-lesson')}
                />
                <OfferingDetailsReviewStars
                  review={review}
                  orderId={order.id}
                  onSuccess={fetchOccurrence}
                  userLink={getUserLink(user)}
                  userFullName={getSaveFullName(user)}
                  offerType={t('shared.offers.live-in-person-lesson')}
                  reviewType={'order.answer.rate.with'}
                />
              </React.Fragment>
            )}

            {c.isShowClosed && (
              <div className="od--centered">
                <OfferingDetailsEscrowFunds
                  title={t('shared.offers.live-in-person-lesson')}
                  subtitle={t('order.description.received')}
                  price={order?.totalPrice}
                />
                {!!totalCharityFee && order?.totalPrice && (
                  <OfferingDetailsEscrowFunds
                    className="od--bordered"
                    subtitle={getCharitiesTitle(t, order?.totalPrice, totalCharityFee)}
                    price={totalCharityFee}
                    description={t('order.description.charities.text')}
                  />
                )}
                <OfferingDetailsReviewStars
                  onSuccess={fetchOccurrence}
                  review={review}
                  orderId={order.id}
                  userLink={getUserLink(user)}
                  userFullName={getSaveFullName(user)}
                  offerType={t('shared.offers.live-in-person-lesson')}
                  reviewType={'order.answer.rate.with'}
                />
              </div>
            )}
          </div>

          <ComplaintInfo
            orderId={order?.id}
            onSuccess={fetchOccurrence}
            userLink={getUserLink(user)}
            userName={getSaveFullName(user)}
          />
        </div>
      </div>

      <BookingCalendar
        isOpen={isOpenCalendarModal}
        onClose={closeCalendarModal}
        onConfirm={onCalendarConfirm}
        timeZone={timeZone}
        eventDuration={lengthOfTime}
        talentId={authorizedUser.talent.id}
        eventTitle={title}
        minBookingTimeBeforeExperience={0}
        orderSlot={orderSlot}
        setOrderSlot={setOrderSlot}
        beforeTransform={beforeTransform}
      />

      <TalentPublicViewCalendar
        isOpen={isOpenTalentCalendar}
        onClose={closeTalentCalendarModal}
        talentId={authorizedUser.talent.id}
        header={getSaveFullName(authorizedUser.talent)}
        slotMapper={toOrderPageCalendarEvents}
        options={talentCalendarSidebarFilters}
      />

      <RejectModal
        orderId={order.id}
        isOpen={isOpenRejectModal}
        onClose={closeRejectModal}
        rejectHandler={rejectOrderRequest}
      />
    </div>
  )
}

export default LiveInPersonLessonOrderDetails
