import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import BulkActionDropdown from '../common/BulkActionDropdown'

export default function BulkHeader ({ bulkActionsConfig }) {
  const t = useGetTranslate()

  const { getFilters, actions } = bulkActionsConfig
  const selectedCount = getFilters(current => get(current, 'selected', []).length)
  const [isFixed, setIsFixed] = React.useState(false)
  const ref = React.useRef()

  React.useEffect(() => {
    function handlerScroll () {
      if (!ref.current) return
      if (ref.current.getBoundingClientRect().top <= 0) return setIsFixed(true)
      setIsFixed(false)
    }
    handlerScroll()
    window.addEventListener('scroll', handlerScroll)
    return () => window.removeEventListener('scroll', handlerScroll)
  }, [setIsFixed])

  if (!selectedCount) return null

  if (isFixed) {
    return (
      <>
        <StyledStaticWrapper ref={ref}>
          <div className='count'>
            {`${selectedCount} ${t('table.header.selected')}`}
          </div>
          <BulkActionDropdown
            getFilters={getFilters}
            actions={actions}
          />
        </StyledStaticWrapper>
        <StyledFixedWrapper>
          <div className='count'>
            {`${selectedCount} ${t('table.header.selected')}`}
          </div>
          <BulkActionDropdown
            getFilters={getFilters}
            actions={actions}
          />
        </StyledFixedWrapper>
      </>
    )
  }

  return (
    <StyledStaticWrapper ref={ref}>
      <div className='count'>
        {`${selectedCount} ${t('table.header.selected')}`}
      </div>
      <BulkActionDropdown
        getFilters={getFilters}
        actions={actions}
      />
    </StyledStaticWrapper>
  )
}

const StyledStaticWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  padding: 12px 16px;
  margin-bottom: 12px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
  .count {
    font-size: 13px;
    font-weight: 500;
  }
`

const StyledFixedWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 12px 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
  z-index: 9999;
  .count {
    font-size: 13px;
    font-weight: 500;
  }
`
