import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'

import BasicMobileOverlay from '_legacy/components/TalentPublicView/Slider/Overlay/BasicMobileOverlay';

const Overlay = ({ date, name, isVideo }) => {
  const classes = cx('tbv-slider-slide-overlay-live-experience', {
    'tbv-slider-slide-overlay-live-experience--video': isVideo
  })

  return (
    <BasicMobileOverlay withGradientOverhead>
      <div className={classes}>
        <h3 className="tbv-slider-slide-overlay-live-experience-name">
          {name}
        </h3>
        <h4 className="tbv-slider-slide-overlay-live-experience-date">
          {date}
        </h4>
      </div>
    </BasicMobileOverlay>
  );
};

Overlay.propTypes = {
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isVideo: PropTypes.bool
};

export default Overlay;
