import React from 'react';
import compact from 'lodash/compact';
import { useFormContext, useWatch } from 'react-hook-form';

import { errorsConfigWithPartOfCred, getValidationConfig } from 'utils/services/validators/passwordValidators';
import PasswordBlock from './index';

const getEmailUsername = email => email.slice(0, email.indexOf('@'));

const RegisterPasswordBlock = () => {
  const { control } = useFormContext();
  const firstName = useWatch({ control, name: 'firstName', defaultValue: '' });
  const lastName = useWatch({ control, name: 'lastName', defaultValue: '' });
  const email = useWatch({ control, name: 'email', defaultValue: '' });
  const forbiddenWords = compact([
    firstName,
    lastName,
    getEmailUsername(email),
  ]);

  return (
    <PasswordBlock
      errorsConfig={errorsConfigWithPartOfCred}
      passwordValidationConfig={getValidationConfig(forbiddenWords)}
    />
  );
};

export default RegisterPasswordBlock;
