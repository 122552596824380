import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form'

import Button from '_legacy/components/Button/BookNowButton';
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import Price from '_legacy/components/Price'
import { StyledCheckbox } from '_legacy/common/Checkbox/Checkbox'

const PublicSubmitButton = ({ textBelow, pricePerParticipant, isSubmitting, disabled, ...props }) => {
  const t = useGetTranslate();
  const { watch } = useFormContext()
  const [acceptedValue, setAcceptedValue] = React.useState(false)

  const participantsToPay = Number(watch('includedFriends', 0))
  // const participantsTheyPay = Number(watch('invitedFriends', 0))

  const price = Number(pricePerParticipant) * (participantsToPay + 1)
  // const participantsCount = (participantsToPay + participantsTheyPay) || 0

  const isButtonDisabled = !acceptedValue || disabled

  return (
    <StyledExperienceInfoCta>
      <h4>{t('experience.info.price')}</h4>

      <StyledExperienceInfoButton>
        <StyledCheckbox>
          <span><Trans tKey='experience.info.terms-conditions' /></span>
          <input type='checkbox' value={acceptedValue} onChange={() => setAcceptedValue(prev => !prev)} />
          <i />
        </StyledCheckbox>

        <div className='wrapper'>
          <StyledExperienceInfoPrice>
            <Price price={price} withCurrency />
            {/*<span className='friends'>{`${participantsCount} friends included`}</span>*/}
          </StyledExperienceInfoPrice>
          <Button
            {...props}
            disabled={isButtonDisabled}
            loader={isSubmitting}
            typeButton='submit'
            text={t('experience.info.book-now')}
            paddingVertical="13"
            paddingHorizontal="30"
          />
        </div>
      </StyledExperienceInfoButton>
      <StyledExperienceInfoCheck>
        {textBelow ?
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="15"
              viewBox="0 0 17 15"
              fill="none"
            >
              <path
                d="M2 8.5L5.84848 12.5L15 1"
                stroke="white"
                strokeWidth="3"
              ></path>
            </svg>
            {textBelow}
          </>
          : null }
      </StyledExperienceInfoCheck>
    </StyledExperienceInfoCta>
  )
};

export const StyledExperienceInfoCta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    font-family: Playfair Display;
    font-weight: 900;
    font-size: 23px;
    line-height: 29px;
    text-align: center;
    font-feature-settings: 'case' on, 'liga' off;
    color: #ffffff;
    margin: 0 16px 16px;
  }
`;

export const StyledExperienceInfoButton = styled.div`
  padding: 26px 40px;
  width: -moz-fit-content;
  width: fit-content;
  background: #ffffff;
  margin: 18px auto;
  box-shadow: 15px 15px 30px #737275, -15px -15px 40px #959696;
  
  ${StyledCheckbox} {
    margin-bottom: 8px;
  }
  
  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 540px) {
      flex-direction: column;
      width: 100%;
      text-align: center;
      .button {
        margin-top: 12px;
      }
    }
  }
`

export const StyledExperienceInfoPrice = styled.div`
  margin-right: 40px;

  ._price {
    margin-right: 44px;
   
    &__value {
      font-size: 32px;
    }
  }
  .friends {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    color: ${({ theme }) => theme.colors.blue};
  }
  @media only screen and (max-width: 767px) {
    ._price {
      margin-bottom: 24px;
      margin-right: 0;

      &__value {
        font-size: 32px;
      }
    }
  }
  @media only screen and (max-width: 540px) {
    margin-right: 0;
    ._price__value {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`

export const StyledExperienceInfoCheck = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: ${({ theme }) => theme.colors.blue};
  margin-top: 16px;
  svg {
    margin-right: 8px;
    path {
      stroke: ${({ theme }) => theme.colors.blue};
    }
  }
`;

PublicSubmitButton.propTypes = {
  isSubmitting: PropTypes.bool,
  pricePerParticipant: PropTypes.number,
  textBelow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}


export default PublicSubmitButton;
