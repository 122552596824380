import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const WIDTH = 344;

const infiniteSlides = (slides, currentSlideIndex) => {
  const multiplyIndex = Math.floor(currentSlideIndex / slides.length);
  const marginOffsetX = -WIDTH * (currentSlideIndex);
  return {
    skipOffsetX: -WIDTH * (slides.length * multiplyIndex),
    marginOffsetX,
    slides: [...slides]
  };
};

const List = ({ slides, currentSlideIndex }) => {
  const { slides: infinitySlides, skipOffsetX, marginOffsetX } = infiniteSlides(slides, currentSlideIndex);

  return (
    <StyledWrapper slides={slides} className="mb-list" marginLeft={marginOffsetX - skipOffsetX}>
      {infinitySlides}
    </StyledWrapper>
  );
};

const  StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ slides }) => slides.length}, 344px);
  transition: .3s margin-left ease;
  margin-left: ${({ marginLeft }) => marginLeft}px;
`

List.propTypes = {
  slides: PropTypes.array.isRequired,
  currentSlideIndex: PropTypes.number.isRequired
};

export default List;
