// Modules
import React from 'react'
import { useHistory } from 'react-router-dom'

// Assets
import Icon from 'assets/icons/Icon'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useLogout } from 'requests/auth'


const MobileMenuHeading = ({ setMobileMenuActive }) => {
  const t = useGetTranslate()
  const logout = useLogout()
  const history = useHistory()

  const logoutHandler = async () => {
    logout()
    history.push('/')
    setMobileMenuActive(false)
  }

  return (
    <div className="mobile-menu__heading">
      <div className="mobile-menu--title">{t('footer.mobile.my-preferences')}</div>
      <div className="mobile-menu--exit" onClick={logoutHandler}>
        <Icon.SignOut />
      </div>
    </div>
  )
}
export default MobileMenuHeading
