import get from 'lodash/get'
import compact from 'lodash/fp/compact';
import compose from 'lodash/fp/compose';
import join from 'lodash/fp/join';
import find from 'lodash/fp/find';
import prop from 'lodash/fp/prop';
import propOr from 'lodash/fp/propOr';
import pick from 'lodash/fp/pick';

import { countries, states } from './data';

const getNormalizedCountry = compose(
  pick(['label', 'value']),
  code => find(c => code === c.data, countries),
  prop('shortName'),
  find(({ types }) => types.includes('country'))
);

const getNormalizedState = (countryValue, splitted) => {
  const isUsa = countryValue === 'UNITED_STATES';
  const usaState = isUsa
    ? states.find(s => s.label === splitted.stateOrSimilar)
    : states[0];
  const state = splitted.stateOrSimilar || '';
  return { usaState, state };
};

const getNormalizedCity = compose(
  city => ({ value: city, label: city }),
  propOr('', 'city')
);

const getNormalizedZipCode = propOr('', 'postalCode');

const normalize = compose(join(', '), compact);

const getNormalizedAddress = (splitted, original) => {
  const _address = propOr('', 'address', splitted);
  const _streetNumber = propOr('', 'streetNumber', splitted);

  return {
    value: original.placeId,
    label: normalize([_address, _streetNumber]),
  };
};


export const getAdapter = ({ splitted, original }) => {
  const country = getNormalizedCountry(original.addressComponents);

  const { state, usaState } = getNormalizedState(country.value, splitted);

  const city = getNormalizedCity(splitted);

  const zipCode = getNormalizedZipCode(splitted);

  const address = getNormalizedAddress(splitted, original);

  return { address, country, state, usaState, city, zipCode };
};

export const getAddressForSubmit = value => {
  const country = get(value, 'country.value')
  const isUSA = country === 'UNITED_STATES';
  const state = isUSA ? get(value, 'usaState.value') : get(value, 'state');

  return {
    address: { value: value.address.label, googlePlaceId: value.address.value },
    city: { value: value.city.value },
    country,
    state,
    zipCode: value.zipCode
  }
}
