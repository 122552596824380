import React from 'react'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { FormProvider, useForm, useWatch } from 'react-hook-form'
import compose from 'lodash/fp/compose'
import get from 'lodash/get'
import { types } from 'shared'

import { Row, Input } from '_legacy/common'
import TextArea from '_legacy/common/TextArea'
import PriceInput from '_legacy/common/Input/PriceInput'
import PhoneWithValidation from '_legacy/common/Input/PhoneWithValidation'
import Button from '_legacy/components/Button'
import Select from '_legacy/components/Form/Selects/SelectComponent'
import RequiredLabel from '_legacy/components/RegisterForms/shared/components/RequiredLabel/index.js'
import MissedData from '_legacy/components/RegisterForms/shared/components/MissedData'

import { useRequestCommercialEngagements } from 'requests/commercial-engagements'
import { emailPattern } from 'utils/services/validators/emailValidator'
import {
  geHiringEntitiesOptions,
  getAvailableEngagementTypes,
  getPrimaryEngagementFormOfCompensation,
} from '_legacy/containers/CommercialEngagements/utils'

const defaultValues = {
  fullName: '',
  hiringEntity: null,
  companyName: '',
  title: '',
  email: '',
  phone: '',
  minimumBudget: null,
  maximumBudget: null,
  primaryEngagementType: null,
  primaryFormOfCompensation: null,
  message: '',
}

const { COMPANY, CHARITY } = types.talent.commercialEngagements.HiringEntity

// TODO: refactor defalult value pick (lodash get or falsy values check)
const submitValidator = data => ({
  fullName: get(data, 'fullName', ''),
  hiringEntity: get(data, 'hiringEntity.value') || undefined,
  companyName: get(data, 'companyName') || undefined,
  title: get(data, 'title') || undefined,
  phone: get(data, 'phone.length') > 2 ? get(data, 'phone') : undefined,
  email: get(data, 'email') || undefined,
  minimumBudget: parseInt(get(data, 'minimumBudget')),
  maximumBudget: parseInt(get(data, 'maximumBudget')),
  primaryEngagementType: get(data, 'primaryEngagementType.value') || undefined,
  primaryFormOfCompensation: get(data, 'primaryFormOfCompensation.value') || undefined,
  message: get(data, 'message', ''),
})


const CommercialEngagementsForm = React.forwardRef((props, ref) => {
  const t = useGetTranslate()

  const [loading, setIsLoading] = React.useState(false)

  const requestCommercialEngagements = useRequestCommercialEngagements(setIsLoading)
  const onSubmit = compose(requestCommercialEngagements, submitValidator)

  const methods = useForm({ defaultValues, mode: 'onChange' })
  const { handleSubmit, watch, clearErrors, setError, control } = methods

  const hiringEntityWatch = useWatch({ control, name: 'hiringEntity', defaultValue: null })
  const hiringEntityValue = get(hiringEntityWatch, 'value', null)
  const notSelectPersonHiring = hiringEntityValue === COMPANY || hiringEntityValue === CHARITY

  const onMinMaxValidator = () => {
    const { minimumBudget, maximumBudget } = watch(['minimumBudget', 'maximumBudget'])

    if (parseInt(minimumBudget, 10) < parseInt(maximumBudget, 10)) {
      return clearErrors(['minimumBudget', 'maximumBudget']);
    } else {
      [
        {
          type: 'max',
          name: 'minimumBudget',
          message: t('commercial-engagements.form.min-budget.errors.max'),
        },
        {
          type: 'min',
          name: 'maximumBudget',
          message: t('commercial-engagements.form.max-budget.errors.min'),
        },
      ].forEach(({ name, type, message }) =>
        setError(name, { type, message }),
      );
    }
  };

  return (
    <div className="commercial-engagements-form" ref={ref}>
      <div className="commercial-engagement darken">
        <h1 className="font-header-h1 commercial-engagement-header">
          {t('commercial-engagements.header.title')}
        </h1>
        <h6 className="commercial-engagement-subheader">
          {t('commercial-engagements.header.returns')}
        </h6>

        <div className="partnership-wrap">
          <div className="header">
            <div className="header-line-block"/>
            <div className="font-header-h2 title">
              {t('commercial-engagements.header.start-partnership')}
            </div>
            <div className="header-line-block"/>
          </div>

          <div className="content">
            <p className="font-body-2">{t('commercial-engagements.content.full-access')}</p>
            <p className="font-body-2">{t('commercial-engagements.content.direct-access')}</p>
          </div>
        </div>

        <p className="font-body-1 fill-form">
          {t('commercial-engagements.content.fill-form')}
        </p>
      </div>

      <FormProvider {...methods}>
        <form
          className='commercial-engagement darken'
          onSubmit={handleSubmit(onSubmit)}
        >
          <RequiredLabel />
          <Row
            alignItems='center'
            nameBlock={t('commercial-engagements.form.full-name.label')}
            isRequired
            component={
              <Input
                name='fullName'
                placeholder={t('commercial-engagements.form.full-name.placeholder')}
                requiredMessage={t('commercial-engagements.form.full-name.errors.required')}
                isRequired
              />
            }
          />
          <Row
            alignItems='center'
            nameBlock={t('commercial-engagements.form.hiring-entity.label')}
            component={
              <Select
                name='hiringEntity'
                options={geHiringEntitiesOptions(t)}
                placeholder={t('commercial-engagements.form.hiring-entity.placeholder')}
              />
            }
          />
          {
            notSelectPersonHiring && <Row
              alignItems='center'
              nameBlock={t('commercial-engagements.form.company-name.label')}
              component={
                <Input
                  name='companyName'
                  placeholder={t('commercial-engagements.form.company-name.placeholder')}
                  requiredMessage={t('commercial-engagements.form.full-name.errors.required')}
                />
              }
            />
          }
          {
            notSelectPersonHiring && <Row
              alignItems='center'
              nameBlock={t('commercial-engagements.form.title.label')}
              component={
                <Input
                  name='title'
                  placeholder={t('commercial-engagements.form.title.placeholder')}
                />
              }
            />}
          <Row
            alignItems='center'
            nameBlock={t('commercial-engagements.form.email.label')}
            isRequired
            component={
              <Input
                name='email'
                type='email'
                placeholder={t('commercial-engagements.form.email.placeholder')}
                validation={{
                  pattern: {
                    value: emailPattern,
                    message: t('commercial-engagements.form.email.errors.email'),
                  },
                }}
                requiredMessage='commercial-engagements.form.email.errors.required'
                isRequired
              />
            }
          />
          <Row
            alignItems='center'
            nameBlock={t('contact.form.phone.label')}
            component={
              <PhoneWithValidation name='phone' />
            }
          />
          <Row
            alignItems='center'
            className="commercial-engagements-form--budget"
            nameBlock={t('commercial-engagements.form.min-budget.label')}
            component={
              <PriceInput
                name='minimumBudget'
                placeholder={t('commercial-engagements.form.min-budget.placeholder')}
                validation={{
                  valueAsNumber: true,
                }}
                onCustomChange={onMinMaxValidator}
              />
            }
          />
          <Row
            alignItems='center'
            className="commercial-engagements-form--budget"
            nameBlock={t('commercial-engagements.form.max-budget.label')}
            component={
              <PriceInput
                name='maximumBudget'
                placeholder={t('commercial-engagements.form.max-budget.placeholder')}
                validation={{
                  valueAsNumber: true,
                }}
                onCustomChange={onMinMaxValidator}
              />
            }
          />
          <Row
            alignItems='center'
            nameBlock={t('commercial-engagements.form.primary-engagement-type.label')}
            component={
              <Select
                name='primaryEngagementType'
                options={getAvailableEngagementTypes(t)}
                placeholder={t('commercial-engagements.form.primary-engagement-type.placeholder')}
              />
            }
          />
          <Row
            alignItems='center'
            nameBlock={t('commercial-engagements.form.primary-form-of-compensation.label')}
            component={
              <Select
                name='primaryFormOfCompensation'
                options={getPrimaryEngagementFormOfCompensation(t)}
                placeholder={t('commercial-engagements.form.primary-form-of-compensation.label')}
              />
            }
          />
          <Row
            nameBlock={t('commercial-engagements.form.message.label')}
            isRequired
            component={
              <TextArea
                className='textarea-darken'
                name='message'
                placeholder='commercial-engagements.form.message.placeholder'
                maxLength={500}
                isRequired
                requiredMessage='commercial-engagements.form.message.errors.required'
              />
            }
          />
          <Row
            component={
              <Button
                loader={loading}
                text={t('commercial-engagements.form.button.label')}
                typeButton='submit'
                paddingVertical='13'
                paddingHorizontal='30'
              />
            }
          />
          <MissedData />
        </form>
      </FormProvider>
    </div>
  )
})

export default CommercialEngagementsForm
