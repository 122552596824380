import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import get from 'lodash/get';

import BlockWithBorder from '../../common/Template/BlockWithBorder';
import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg';

import LegalTerms from './components/LegalTerms';
import ArrowScrollDown from './components/ArrowScrollDown';
import Info from './components/Info';
import Body2 from '../../common/Typography/Body2';
import Slider from './components/Slider';
import { useCommercialEngagements } from 'store/hooks/globalState/useCommercialEngagements';
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';

const getDownloadLink = talentId =>
  `${process.env.REACT_APP_API_URL}/talents/${talentId}/commercial-engagements/media-kit`;

const Main = ({ onClick }) => {
  const t = useGetTranslate();
  const commercialEngagements = useCommercialEngagements();
  const displayName = get(commercialEngagements, 'displayName');
  const talentId = get(commercialEngagements, 'id')

  const titleComponent = (
    <BorderTitle download href={getDownloadLink(talentId)}>
      <DownloadIcon />
      <Body2 bold>
        <Trans
          tKey="commercial-engagements.main.download"
          phs={[{ ph: '{{displayName}}', value: displayName }]}
        />
      </Body2>
    </BorderTitle>
  );

  return (
    <MainWrapper>
      <Header>{t('commercial-engagements.main.header')}</Header>

      <BlockWithBorder titleComponent={titleComponent}>
        <MainContainer>
          <SliderContainer>
            <Slider />
          </SliderContainer>
          <InfoContainer />
          <LegalsContainer />
        </MainContainer>
      </BlockWithBorder>

      <ArrowScrollDown clickHandler={onClick} />
    </MainWrapper>
  );
};

const Header = styled.h1`
  font-family: 'Playfair Display', sans-serif;
  font-weight: 900;
  font-size: 60px;
  line-height: 1.25;
  text-align: center;
  font-feature-settings: 'case' on, 'liga' off;
  color: ${({ theme }) => theme.colors.blue};

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }

  @media screen and (max-width: 414px) {
    font-size: 28px;
  }
`;

const MainWrapper = styled.div`
  min-height: 100vh;
  position: relative;

  padding-top: 140px;
  width: 100%;
  max-width: 1394px;
  margin: 0 auto;

  ${Header} {
    margin-bottom: 14px;
  }

  .content {
    padding-top: 20px;
    @media screen and (max-width: 991px) {
      padding: 0;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 80px;
    }
  }

  @media screen and (max-width: 1440px) {
    height: auto;
    min-height: auto;

    .block-width-border {
      &:before,
      &:after,
      .line {
        display: none;
      }
    }

    .title {
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 767px) {
    padding-top: 80px;
  }
`;

const MainContainer = styled.div`
  display: grid;
  align-items: start;
  grid-template:
    'slider info'
    'slider legal-terms';
  grid-template-columns: 2fr 2fr;

  @media screen and (max-width: 1280px) {
    grid-column-gap: 50px;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 12px;
    grid-gap: 30px 16px;
    grid-template:
      'slider info'
      'legal-terms legal-terms';
    grid-template-columns: 2fr 2fr;
  }

  @media screen and (max-width: 767px) {
    overflow: hidden;
    margin-top: 0;
    grid-gap: 0;
    grid-template:
      'slider'
      'info'
      'legal-terms';
    grid-template-columns: 1fr;
  }
`

const LegalsContainer = styled(LegalTerms)`
  grid-area: legal-terms;
`;

const InfoContainer = styled(Info)`
  grid-area: info;
`;

const SliderContainer = styled.div`
  grid-area: slider;
  justify-self: center;
  @media screen and (max-width: 1025px) {
    transform: translateX(-30px);
  }
  
  @media only screen and (max-width: 991px) {
    transform: translateX(0) scale(1);
  }
  
  @media only screen and (max-width: 767px) {
    margin-bottom: 48px;
  }
  
  @media only screen and (max-height: 991px), screen and (max-width: 991px){
    transform: translateX(0) scale(1);
  }
  @media only screen and (max-height: 991px), screen and (max-width: 767px){
    transform: scale(1);
  }
`;

const BorderTitle = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  background: none;
  border: none;
  cursor: pointer;

  ${Body2} {
    margin-left: 10px;
    padding-top: 6px;
  }

  @media screen and (max-width: 1024px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 991px) {
    margin: auto;
    text-align: center;

    ${Body2} {
      margin-left: 0;
      padding-top: 0;
    }

    svg {
      display: none;
    }
  }
`;

Main.propTypes = {
  onClick: PropTypes.func,
}

export default Main;
