import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

const CustomPicture = React.memo(({ className = '', style, file, imgRef, onLoad }) => {
  const classes = cx('custom-image', className)

  const srcSet = React.useMemo(() => {
    if (!file || !file.sources) return null
    return file.sources
      .sort((a, b) => a.width - b.width)
      .map(({ fullUrl, width }) => `${fullUrl} ${width}w`)
      .join(', ')
  }, [file])

  const sizes = React.useMemo(() => {
    if (!file || !file.sources) return null
    return file.sources
      .sort((a, b) => a.width - b.width)
      .map(({ width }) => `(max-width: ${width}px) ${width}px`)
      .join(', ')
  }, [file])

  if (!file) return null

  return (
    <img
      ref={imgRef}
      className={classes}
      style={style}
      sizes={sizes}
      srcSet={srcSet}
      src={file.fullUrl}
      alt={file.originalName}
      onLoad={onLoad}
    />
  )
})

CustomPicture.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  file: PropTypes.object,
  imgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  onLoad: PropTypes.func,
}

export default CustomPicture
