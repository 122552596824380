import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import ModalPortal from 'portals/ModalPortal';

const TermsModal = ({ children, title, isModalOpen, closeModal }) => {
  const t = useGetTranslate();
  return (
    <ModalPortal isOpen={isModalOpen} onClose={closeModal} showCloseButton>
      <div className="legal-terms-modal">
        <h1 className="modal-header">{t(title)}</h1>
        <div>
          {children}
        </div>
      </div>
    </ModalPortal>
  );
};

TermsModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.element,
};

export default TermsModal;
