import React from 'react'
import PropTypes from 'prop-types'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import Icon from 'assets/icons/Icon'
import {
  getFirstCityOrCountry,
  getFirstLocationAddress,
  getFirstLocationFormattedAddress,
  getFirstLocationName,
  getLocationLabel,
} from 'utils/location'


const PublicLocationSingle = ({ locations, isOnline, hideAddressFromPubView }) => {
  const t = useGetTranslate()
  const label = hideAddressFromPubView ? getFirstCityOrCountry(locations) : getFirstLocationName(locations)

  return (
    <div className="public-location__single">
      <h4>{label || t('experience.card.location')}</h4>
      {!hideAddressFromPubView && (
        <p>{!isOnline ? getFirstLocationFormattedAddress(locations) : getFirstLocationAddress(locations)}</p>
      )}
    </div>
  )
}


const PublicLocationMultiple = ({ locations }) => {
  const t = useGetTranslate()
  const toggleDropdown = event => event.target.parentElement.classList.toggle('active')

  return (
    <div className="public-location__multiple">
      <h4>{t('experience.card.location-options')}</h4>

      {locations?.map(location => {
        return (
          <div key={location.id} className="pv-card-location-flexible">
            {location.name ? (
              <div className="public-location__multiple__title" onClick={toggleDropdown}>
                <p>{location.name}</p>
                <Icon.ArrowDownSelectMenu />
              </div>
            ) : null}
            <p className={`${location.name ? '' : 'visible'}`}>
              {getLocationLabel(location)}
            </p>
          </div>
        )
      })}
    </div>
  )
}


const PublicLocation = ({
  locations,
  isOnline,
  hideAddressFromPubView
}) => (
  <div className="public-location">
    {
      locations?.length === 1 ? (
        <PublicLocationSingle locations={locations} isOnline={isOnline} hideAddressFromPubView={hideAddressFromPubView} />
      ) : (
        <PublicLocationMultiple locations={locations} hideAddressFromPubView={hideAddressFromPubView} />
      )
    }
  </div>
)

PublicLocation.propTypes = {
  locations: PropTypes.array,
  isOnline: PropTypes.bool,
}

export default PublicLocation
