import React from 'react';
import { useParams } from 'react-router-dom';
import pick from 'lodash/pick';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import mapValues from 'lodash/mapValues';

import {
  getTalentPublicView as getTalentPublicViewApi,
  preview as previewApi,
} from 'api/appApi/talent/publicView';
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'
import { useSetTalentPublicView, useTalentPublicView, } from 'store/hooks/globalState/useTalentPublic';
import { NOT_FOUND } from '_legacy/constants/routes';

const pickPath = (object, path, props) => pick(get(object, path), props);


const sortEventPrimaryMediaKeys = ['videoChat', 'videoMessage'];

const sortMedia = media => sortBy(media, ({ isPrimary }) => !isPrimary);

const prepareSortData = (talent) => {
  const k = pick(talent, sortEventPrimaryMediaKeys);
  const sortedKeys = mapValues(k, (value) => {
    const media = get(value, 'media', []);
    return { ...value, media: sortMedia(media) };
  });
  return { ...talent, ...sortedKeys };
};

export function useFetchTalentPublicView() {
  const handleError = useHandleResponseError()
  const setTalentPublicView = useSetTalentPublicView((prev, { currentUserPersonalData, talent }) => ({
    currentUserPersonalData,
    talent: {
      ...talent,
      reviews: {
        items: talent.reviews.data,
        pageInfo: talent.reviews.pageInfo,
      },
    },
  }));
  const { talentNickname } = useParams();

  return React.useCallback(() => {
    getTalentPublicViewApi({ talentNickname })
      .then(({ talent, currentUserPersonalData }) => {
        setTalentPublicView({ currentUserPersonalData, talent: prepareSortData(talent) });
      })
      .catch(err => {
        handleError(err)
      });
  }, [setTalentPublicView, talentNickname, handleError]);
}

export function useFetchPreviewTalentPublicView() {
  const setTalentPublicView = useSetTalentPublicView((prev, { talent }) => ({
    ...prev,
    talent: {
      ...talent,
      reviews: {
        items: talent.reviews.data,
        pageInfo: talent.reviews.pageInfo,
      },
    },
  }));
  const { link } = useParams();
  return React.useCallback(() => {
    previewApi.getPreviewTalentPublicView({ previewTalentLink: link })
      .then(({ previewTalent }) => {
        setTalentPublicView({ talent: previewTalent });
      })
      .catch(err => {
        if (err?.name === 'PREVIEW_TALENT_NOT_FOUND') {
          history.push(`/${NOT_FOUND}`);
        }
      });
  }, [setTalentPublicView, link]);
}

export function useFetchNextPreviewsTalentPublicReviews() {
  const { link } = useParams();
  const talentPublicView = useTalentPublicView();
  const { page, limit } = pickPath(
    talentPublicView,
    ['reviews', 'pageInfo'],
    ['page', 'limit']
  );
  const setTalentPublicView = useSetTalentPublicView((prev, next) => ({
    ...prev,
    talent: {
      ...prev.talent,
      reviews: {
        items: {
          ...prev.talent.reviews.items,
          ...next.reviews,
        },
        pageInfo: next.pageInfo,
      },
    },
  }));


  return React.useCallback(() => {
    previewApi.getPreviewTalentReviews({ previewTalentLink: link, page, limit })
      .then(({ reviews, pageInfo }) => {
        setTalentPublicView({ reviews, pageInfo });
      })
      .catch(err => console.warn(err));
  }, [setTalentPublicView, link]);
}
