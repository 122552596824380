import { TalentOffersType } from 'shared'
import { isNil, pipe } from 'lodash/fp'

import { isChosenTimeOfferType } from 'utils/offer'
import { getTalentOrUserId } from 'utils/user'
import { buildPhs, phsConfig } from '../../config'

export const getModifiers = ({ occurrence, user, receiverTalent, receiverUser }) => {
  const { type, startTime } = occurrence
  const receiver = receiverTalent || receiverUser

  const isAgent = getTalentOrUserId(user) !== receiver.id

  const isVideoChat = type === TalentOffersType.VIDEO_CHAT
  const isVideoMessage = type === TalentOffersType.VIDEO_MESSAGE
  const isLiveVirtualEvent = type === TalentOffersType.VIRTUAL_EVENT
  const isVirtualLesson = type === TalentOffersType.VIRTUAL_LESSON
  const isVideoVoiceOverLesson = type === TalentOffersType.VIDEO_VOICE_OVER_LESSON
  const isReadyMadeVideo = type === TalentOffersType.READY_MADE_LESSON

  const withDate = isChosenTimeOfferType(type) && Boolean(startTime)
  const dateNotSelected = isChosenTimeOfferType(type) && isNil(startTime)

  return {
    isAgent,

    isVideoChat,
    isVideoMessage,
    isVirtualLesson,
    isReadyMadeVideo,
    isLiveVirtualEvent,
    isVideoVoiceOverLesson,

    withDate,
    dateNotSelected,
  }
}

export const matchKeys = ({
  isAgent,
  isLiveVirtualEvent,
  isVirtualLesson,
  isVideoChat,
  isVideoMessage,
  isVideoVoiceOverLesson,
  isReadyMadeVideo,
  withDate,
  dateNotSelected,
}) => {
  return [
    'notification.new-order',

    isVideoChat && '--video-chat',
    isVideoMessage && '--video-message',
    isReadyMadeVideo && '--ready-made-video',
    isVirtualLesson && '--virtual-lesson',
    isLiveVirtualEvent && '--live-virtual-event',
    isVideoVoiceOverLesson && '--video-voice-over-lesson',

    withDate && '--with-date',
    dateNotSelected && '--date-not-selected',

    isAgent && '--agent',
  ]
    .filter(Boolean)
    .join('')
}

export const buildTransKey = pipe(getModifiers, matchKeys)

export const getNewOrderPhs = buildPhs([
  phsConfig.talentFullName,
  phsConfig.talentLink,
  phsConfig.aficionadoLink,
  phsConfig.aficionadoFullName,
  phsConfig.offeringName,
  phsConfig.offeringLink,
  phsConfig.date,
  phsConfig.time
])
