import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Input } from './Input';
import Icon from 'assets/icons/Icon';

const StyledPasswordWrapper = styled.div`
  position: relative;
  
  input {
    padding-right: 64px;
  }
`;

const EyeWrapper = styled.button.attrs(p => ({
  type: p.type || 'button'
}))`
  background-color: transparent;
  border: none;
  cursor: pointer;
  
  position: absolute;
  top: 20px;
  right: 8px;
  transition: all .3s linear;
  
   &:before {
      content: '';
      position: absolute;
      background-color: #9495A7; // todo: refactor with theme
      left: 19px;
      top: -2px;
      z-index: 100;
      height: 100%;
      pointer-events: none;
      transform: rotate(45deg);
      
      width: ${({ isOpen }) => isOpen ? '2px' : '0'}
    }
`;

export const PasswordInput = React.memo(function PasswordInput (props) {
  const [isFieldTypePassword, setIsFieldTypePassword] = useState(true);

  return (
    <StyledPasswordWrapper>
      <Input
        type={isFieldTypePassword ? 'password' : 'text'}
        {...props}
      />
      <EyeWrapper
        isOpen={!isFieldTypePassword}
        onClick={() => setIsFieldTypePassword(p => !p)}
      >
        <Icon.EyeOpen/>
      </EyeWrapper>
    </StyledPasswordWrapper>
  );
});

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  validation: PropTypes.object,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
};

EyeWrapper.propTypes = {
  isOpen: PropTypes.bool,
};
