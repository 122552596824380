// TODO: Move inside Lessons/LessonsPublic components
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import map from 'lodash/map';
import get from 'lodash/get';
import pick from 'lodash/pick';

import {
  getLessonsList,
  getTalentLessonsList as getTalentLessonsListApi,
  updateLesson as updateLessonApi,
} from 'api/appApi/offers/lessons'
import { useLessons, useSetLessons } from 'store/hooks/globalState/useLessons'
import { useAddFilesToLibrary } from 'store/hooks/globalState/useFileLibrary'
import { getCharitiesForSubmit } from 'utils/services/loaders/charities'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { notify } from '../utils/notify'
import { useTalentId, useUser } from 'store/hooks/globalState/useUser'
import { NOT_FOUND } from '_legacy/constants/routes'
import { PROMOTIONAL_MEDIA_ASK_MODAL } from '_legacy/components/Modal/modals-types'
import { useStateModal } from 'store/hooks/globalState/useModal'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'
import { getTalentId } from '../utils/user'
import { submitEntourageAdapter } from 'utils/forms/adapters/entourage'

const getLesson = res => pick(res, ['liveInPersonLesson', 'liveVirtualLesson', 'videoVoiceOverLesson'])

// helpers
function getMediaFiles(res) {
  const lessons = getLesson(res)
  return map(lessons, lesson => get(lesson, 'media.file')).filter(Boolean)
}

function videoVoiceOverAdapter(lesson) {
  delete lesson.mediaTemp
  return {
    active: get(lesson, 'active') === 'true',
    charities: getCharitiesForSubmit(get(lesson, 'charities')),
    media: get(lesson, 'media'),
    price: +get(lesson, 'price'),
    specialInstructions: get(lesson, 'specialInstructions'),
  }
}

function liveInPersonLessonAdapter(lesson) {
  return {
    active: get(lesson, 'active') === 'true',
    price: +get(lesson, 'price'),
    charities: getCharitiesForSubmit(get(lesson, 'charities')),
    media: get(lesson, 'media'),
    lengthOfTime: Number(get(lesson, 'lengthOfTime')) * 60,
    specialInstructions: get(lesson, 'specialInstructions'),
    location: get(lesson, ['location', 'value']),
    daysTimesType: get(lesson, 'daysTimesType.value'),
    entourageType: submitEntourageAdapter(lesson),
  }
}

function liveVirtualLessonAdapter(lesson) {
  return {
    active: get(lesson, 'active') === 'true',
    price: +get(lesson, 'price'),
    charities: getCharitiesForSubmit(get(lesson, 'charities')),
    media: get(lesson, 'media'),
    lengthOfTime: Number(get(lesson, 'lengthOfTime')) * 60,
    specialInstructions: get(lesson, 'specialInstructions'),
    daysTimesType: get(lesson, 'daysTimesType.value'),
  }
}

export function useFetchLessons() {
  const params = useParams();
  const setLessons = useSetLessons((prev, next) => next);
  const addFilesToFilesLibrary = useAddFilesToLibrary();
  const talentId = get(params, 'id', useTalentId());
  const history = useHistory();

  return React.useCallback(() => {
    getTalentLessonsListApi({ talentId })
      .then(({ lessons, talent }) => {
        const media = getMediaFiles(lessons);
        addFilesToFilesLibrary(media);
        setLessons({ lessons, talent });
      })
      .catch(error => {
        if (error.name === 'VALIDATION_FAILED') {
          history.push(`/${NOT_FOUND}`);
        }
      });
  }, [setLessons, addFilesToFilesLibrary]);
}

export function useFetchLessonsPublic() {
  const { talentPlaqueNickname } = useParams()
  const setLessons = useSetLessons((prev, next) => next)
  const addFilesToFilesLibrary = useAddFilesToLibrary()
  const handleResponseError = useHandleResponseError()
  const currentTalentId = useUser(getTalentId)

  return React.useCallback(() => {
    getLessonsList({ talentPlaqueNickname })
      .then(({ talent, ...lessons }) => {
        // todo: remove after separate lesson public page and form
        if (talent.id === currentTalentId) {
          const media = getMediaFiles(lessons)
          addFilesToFilesLibrary(media)
        }

        setLessons({ lessons, talent })
      })
      .catch(error => handleResponseError(error))
  }, [setLessons, addFilesToFilesLibrary, currentTalentId])
}

export function useUpdateVideoVoiceOverLesson() {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const openAskModal = useStateModal(PROMOTIONAL_MEDIA_ASK_MODAL);
  const lesson = useLessons(state => get(state, 'lessons.videoVoiceOverLesson'));
  const updateLesson = useSetLessons((prev, videoVoiceOverLesson) => ({
    ...prev,
    lessons: {
      ...prev.lessons,
      videoVoiceOverLesson,
    },
  }))
  return React.useCallback(
    (data, setLoading) => {
      setLoading(true)

      updateLessonApi({ talentId, route: '/video-voice-over', data: videoVoiceOverAdapter(data) })
        .then(({ videoVoiceOverLesson, isPromotionalMediaNeeded }) => {
          notify(t(`lessons.message.${lesson ? 'update' : 'create'}`), 'success')
          updateLesson(videoVoiceOverLesson)
          if (isPromotionalMediaNeeded) openAskModal({ withoutRedirect: true })
        })
        .catch(error => console.warn(error))
        .finally(() => setLoading(false))
    },
    [lesson, talentId]
  )
}

export function useUpdateLiveVirtualLesson() {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const openAskModal = useStateModal(PROMOTIONAL_MEDIA_ASK_MODAL);
  const lesson = useLessons(state => get(state, 'lessons.liveVirtualLesson'));
  const updateLesson = useSetLessons((prev, liveVirtualLesson) => ({
    ...prev,
    lessons: {
      ...prev.lessons,
      liveVirtualLesson,
    },
  }))

  return React.useCallback(
    (data, setLoading) => {
      setLoading(true)

      updateLessonApi({ talentId, route: '/live-virtual', data: liveVirtualLessonAdapter(data) })
        .then(({ liveVirtualLesson, isPromotionalMediaNeeded }) => {
          notify(t(`lessons.message.${lesson ? 'update' : 'create'}`), 'success')
          updateLesson(liveVirtualLesson)
          if (isPromotionalMediaNeeded) openAskModal({ withoutRedirect: true })
        })
        .catch(error => console.warn(error))
        .finally(() => setLoading(false))
    },
    [lesson, talentId]
  )
}

export function useUpdateLiveInPersonLesson() {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const openAskModal = useStateModal(PROMOTIONAL_MEDIA_ASK_MODAL);
  const lesson = useLessons(state => get(state, 'lessons.liveInPersonLesson'));
  const updateLesson = useSetLessons((prev, liveInPersonLesson) => ({
    ...prev,
    lessons: {
      ...prev.lessons,
      liveInPersonLesson,
    },
  }))

  return React.useCallback(
    (data, setLoading) => {
      setLoading(true)
      updateLessonApi({ talentId, route: '/live-in-person', data: liveInPersonLessonAdapter(data) })
        .then(({ liveInPersonLesson, isPromotionalMediaNeeded }) => {
          notify(t(`lessons.message.${lesson ? 'update' : 'create'}`), 'success')
          updateLesson(liveInPersonLesson)
          if (isPromotionalMediaNeeded) openAskModal({ withoutRedirect: true })
        })
        .catch(error => console.warn(error))
        .finally(() => setLoading(false))
    },
    [lesson, updateLesson, talentId]
  )
}
