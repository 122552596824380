import { cond, T, F, propEq, pipe, allPass } from 'lodash/fp'
import { TaxpayerStatus } from 'shared'

export const editFormValidationAdapter = formState => ({
  taxpayerStatus: formState.user.taxpayerStatus,
  country: formState.user.address.country,
})

export const isLicensesIsRequired = cond([
  [propEq('taxpayerStatus', TaxpayerStatus.US), T],
  [propEq('country.value', 'UNITED_STATES'), T],
  [T, F],
])

export const isLicensesIsRequiredForEdit = pipe(
  editFormValidationAdapter,
  isLicensesIsRequired
)

export const shouldShowTaxMessage = allPass([
  propEq('taxpayerStatus', TaxpayerStatus.NOT_US),
  propEq('country.value', 'UNITED_STATES')
])

export const shouldShowTaxMessageForEdit = pipe(
  editFormValidationAdapter,
  shouldShowTaxMessage
)
