import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import { FileType } from 'shared';

import Price from '_legacy/components/Price'
import CustomPicture from '_legacy/common/CustomPicture';
import Tooltip from '_legacy/components/Tooltip/index.js';
import CustomVideo from '_legacy/common/custom-video/CustomVideo';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { typesOfferings } from './utils/typesOfferings';
import { getMonthDate } from 'utils/date'

import './utils/offeringCard.scss';

const OfferingCard = ({ data: { offerOccurrence, totalPrice, talentOffer }, discountPrice }) => {
  const t = useGetTranslate()

  const { offerType, primaryMedia, name, startTime, friends, talent } = offerOccurrence

  const formattedStartTime = startTime || get(talentOffer, 'options.time.startTime')

  const Icon = get(typesOfferings, [offerType, 'icon'])
  const nameOffering = get(typesOfferings, [offerType, 'name'])

  const actualPrice = isNumber(discountPrice) ? discountPrice : totalPrice

  return (
    <div className='offering-card'>
      <div className='offering-card__image'>
        {primaryMedia?.file.type === FileType.VIDEO
          ? <CustomVideo file={primaryMedia.file} />
          : <CustomPicture file={primaryMedia.file} />}
      </div>

      {offerType && <div className='offering-card__top'>
        <Icon className='offering-card__offering' />
      </div>}
      <div className='offering-card__content'>
        <div className='offering-card__fullName'>
          <p className='offering-card__fullName_name'>{talent?.firstName}</p>
          <p className='offering-card__fullName_lastname'>{talent?.lastName}</p>
        </div>
        {name ?
          <Tooltip tooltipElement={name}>
            <div className='offering-card__offers'>{name}</div>
          </Tooltip> :
          <div className='offering-card__name-offering'>
            {t(nameOffering)}
          </div>
        }
        {formattedStartTime && <div className='offering-card__date'>
          {getMonthDate(formattedStartTime)}
        </div>}

        <div className='offering-card__price'>
          {isNumber(discountPrice) && (
            <Price
              withCurrency
              price={totalPrice}
              className='offering-card__oldprice'
            />
          )}
          <Price
            withCurrency
            price={actualPrice}
            className='offering-card__newprice'
          />
        </div>
        {friends && <div className='offering-card__friends'>
          {friends}&nbsp;{t('payment-page.offer.friends')}
        </div>}
      </div>
    </div>
  );
};

OfferingCard.propTypes = {
  data: PropTypes.object,
  discountPrice: PropTypes.number,
};

export default OfferingCard;
