export const SELECT_JOIN_TO_TALENT_MODAL = 'SelectJoinToTalentModal';
export const JOIN_TO_TALENT_MODAL = 'JoinToTalentModal';
export const CHANGE_ROUTE_MODAL = 'ChangeRouteModal';
export const PROMO_CODES_DISCOUNT_HIT = 'PROMO_CODES_DISCOUNT_HIT';
export const PROMO_CODES_APPLICABLE_OFFERINGS_HIT = 'PROMO_CODES_APPLICABLE_OFFERINGS_HIT';
export const PROMO_CODES_EXPIRATION_DATE_HIT = 'PROMO_CODES_EXPIRATION_DATE_HIT';
export const PROMO_CODES_MAXIMUM_OF_USES_HIT = 'PROMO_CODES_MAXIMUM_OF_USES_HIT';
export const PROMO_CODES_SELECTS_OFFERINGS = 'PROMO_CODES_SELECTS_OFFERINGS';
export const PROMOTIONAL_MEDIA_ASK_MODAL = 'PROMOTIONAL_MEDIA_ASK_MODAL';
export const PROMOTIONAL_MEDIA_LIBRARY_MODAL = 'PROMOTIONAL_MEDIA_LIBRARY_MODAL';
export const TERM_SERVICES = 'TERM_SERVICES'
export const TERM_PRIVACY_POLICE = 'TERM_PRIVACY_POLICE'
export const PURCHASE_CONFIRM = 'PURCHASE_CONFIRM'
export const RESTORE_PASSWORD = 'RESTORE_PASSWORD'
export const PAYMENT_MODAL = 'PAYMENT_MODAL'
export const UPLOAD_MEDIA = 'UPLOAD_MEDIA'
export const SELECT_PROMOTIONAL_MEDIA_MODAL = 'SELECT_PROMOTIONAL_MEDIA_MODAL'
export const REMOVE_MEDIA_MODAL = 'REMOVE_MEDIA_MODAL'
