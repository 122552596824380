import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { appApi } from 'api';
import Button from '_legacy/components/Button';
import { useFetch } from 'store/hooks/useFetch';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { sharedErrors } from '_legacy/common/Input/phoneErrorMessages';

const phoneRequest = appApi.verify.phone.request;


const PhoneConfirmButton = ({ fields }) => {
  const t = useGetTranslate();
  const { getValues, setValue, setError, watch, errors, formState, clearErrors } = useFormContext();
  const phoneErrors = get(errors, fields.phone);
  const isPhoneDirty = get(formState.dirtyFields, fields.phone, false);
  const phone = watch(fields.phone, '');
  const oldPhone = watch(fields.oldPhone, '');
  const isPhoneSand = watch(fields.isPhoneSand, false);
  const isCodeConfirm = watch(fields.isCodeConfirm, false);

  const [{ data, error, isLoading }, sendPhone] = useFetch(phoneRequest);

  const disabled = isLoading || phoneErrors || phone.length < 3 || isPhoneSand || !isPhoneDirty;

  const onVerifyClick = () => {
    if (getValues(fields.oldPhone) === getValues(fields.phone)) {
      setError(fields.phone, { message: 'Phones are same' });
      return;
    }
    sendPhone({ phone: getValues(fields.phone) });
  };

  // handle response
  React.useEffect(() => {
    if (data) {
      setValue(fields.isPhoneSand, true, { shouldValidate: true });
    }
  }, [data]);

  // handle error
  React.useEffect(() => {
    if (error) {
      setError(fields.phone, {
        type: 'server',
        message: t(sharedErrors[error.name]),
      });
    }
  }, [error]);


  if (!oldPhone) {
    return (
      <Button
        text={t('shared.phone.button.verify')}
        disabled={disabled}
        handleOnClick={onVerifyClick}
        paddingVertical="18"
      />
    );
  }

  const onChangeClick = () => {
    setValue(fields.isPhoneSand, false);
    setValue(fields.isCodeConfirm, false);
  };

  const onCancelClick = () => {
    setValue(fields.isPhoneSand, true);
    setValue(fields.isCodeConfirm, true);
    setValue(fields.phone, oldPhone, { shouldDirty: false });

    clearErrors([fields.isPhoneSand, fields.isCodeConfirm, fields.phone]);
  };

  const rightBtnClick = isCodeConfirm ? onChangeClick : onCancelClick;
  const rightBtnText = isCodeConfirm
    ? t('shared.phone.button.change')
    : t('shared.phone.button.cancel');

  const leftBtnDisabled = oldPhone === phone || phoneErrors || (isPhoneSand && !isCodeConfirm);
  const leftBtnText = isPhoneSand
    ? t('shared.phone.button.verified')
    : t('shared.phone.button.get-code');

  return (
    <Wrapper>
      <Button
        text={leftBtnText}
        disabled={leftBtnDisabled}
        handleOnClick={onVerifyClick}
        paddingVertical="18"
      />
      <Button
        primary
        text={rightBtnText}
        handleOnClick={rightBtnClick}
        paddingVertical="18"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`;

PhoneConfirmButton.propTypes = {
  fields: PropTypes.object,
};

export default PhoneConfirmButton;
