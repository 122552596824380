import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Row } from '_legacy/common';
import TextArea from '_legacy/common/TextArea';
import TipButton from '_legacy/components/Form/LabelTips/TipButton';
import OwnWordsInfoModal from '_legacy/components/Modal/OwnWordsInfoModal';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const OwnWords = ({ name }) => {
  const t = useGetTranslate();

  return (
    <StyledWrapper
      nameBlock={t('register.form.talent-own-words.label')}
      isRequired
      tip={<TipButton modalComponent={OwnWordsInfoModal} />}
      component={
        <TextArea
          name={name}
          placeholder='register.form.talent-own-words.placeholder'
          maxLength={100}
          className='own-words'
          isRequired
          requiredMessage='register.form.talent-own-words.error'
        />
      }
    />
  );
};

const StyledWrapper = styled(Row)`
  .own-words .textarea {
    height: 93px;
  }
`;

OwnWords.propTypes = {
  name: PropTypes.string.isRequired,
};

export default OwnWords;
