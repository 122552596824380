import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useCommercialEngagements } from 'store/hooks/globalState/useCommercialEngagements';

import H1 from '../../common/Typography/H1';
import Body1 from '../../common/Typography/Body1';
import ArrowScrollDown from './components/ArrowScrollDown';

const Relationship = React.forwardRef(({ onClick }, ref) => {
  const t = useGetTranslate()
  const commercialEngagements = useCommercialEngagements();
  const firstName = get(commercialEngagements, 'firstName');

  return (
    <RelationshipWrapper ref={ref}>
      <BackgroundText>{t('commercial-engagements.main.background')}</BackgroundText>

      <Container>
        <H1>
          <Trans
            tKey='commercial-engagements.relationship.header'
            phs={[{ ph: '{{firstName}}', value: firstName }]}
          />
        </H1>
        <RequestWrapper>
          <Border>
            <Body1>
              <Trans
                tKey='commercial-engagements.relationship.text'
                phs={[{ ph: '{{firstName}}', value: firstName }]}
              />
            </Body1>
          </Border>
          <BorderBottom>
            <BorderBottomLine />
            <RequestButton onClick={onClick}>
              {t('commercial-engagements.relationship.button')}
            </RequestButton>
            <BorderBottomLine />
          </BorderBottom>
        </RequestWrapper>
      </Container>

      <ArrowScrollDown clickHandler={onClick} />
    </RelationshipWrapper>
  );
});

const RelationshipWrapper = styled.div`
  height: 100vh;
  position: relative;
  
  @media screen and (max-width: 1440px) {
    padding-bottom: 120px;
    padding-top: 80px;
    height: auto;
  }
  @media screen and (max-width: 1024px) {
    padding-top: 0;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 80px;
  }
`;

const RequestWrapper = styled.div``

const BackgroundText = styled.h3`
  position: absolute;
  text-align: center;
  top: 5%;
  right: 0;
  left: 0;

  font-family: 'Playfair Display', sans-serif;
  font-weight: 900;
  font-size: 224px;
  line-height: 180px;
  font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
  color: #272835;
  opacity: 0.04;
  
  @media screen and (max-width: 1440px) {
    //font-size: 180px;
    display: none;
  }
  
  //@media screen and (max-width: 1280px) {
  //  font-size: 160px;
  //}
  //
  //@media screen and (max-width: 991px) {
  //  display: none;
  //}
`;

const Container = styled.div`
  height: 100%;
  width: 100%;

  max-width: 840px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 991px) {
    max-width: 672px;
  }
  
  ${H1} {
    margin-bottom: 120px;
    text-align: center;
    @media screen and (max-width: 1024px) {
      margin-bottom: 42px;
    }
    @media screen and (max-width: 991px) {
      padding-top: 0;
      font-size: 32px;
      margin-bottom: 32px;
    }
    
    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
  }

  ${Body1} {
    text-align: center;
    margin-bottom: 50px;
    @media screen and (max-width: 1024px) {
      margin-bottom: 10px;
    }
    @media screen and (max-width: 991px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
  
`;

const Border = styled.div`
  position: relative;
  padding: 64px 80px 60px;
  border: 1px solid #272835;
  border-bottom: none;

  max-width: 840px;
  margin: 0 auto;
  
  @media screen and (max-width: 991px) {
    padding: 40px;
  }

  @media screen and (max-width: 991px) {
    padding: 32px;
  }
`;

const RequestButton = styled.button`
  width: 100%;
  max-width: 500px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;
  color: #272835;

  cursor: pointer;
  background: transparent;
  border: none;

  @media screen and (max-width: 991px) {
    font-size: 16px;
    line-height: 26px;
  }
  
  @media screen and (max-width: 768px) {
    max-width: 350px;
  }
  
  @media screen and (max-width: 767px) {
    max-width: 300px;
  }
  @media screen and (max-width: 575px) {
    max-width: 250px;
  }
`;

const BorderBottom = styled.div`
  position: relative;
  top: -20px;

  display: flex;
  align-items: center;
  height: 40px;
`;

const BorderBottomLine = styled.div`
  flex: 1;
  height: 1px;
  background-color: #272835;
`;

export default Relationship;
