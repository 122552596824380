import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import { useFormContext } from 'react-hook-form'
import cx from 'classnames'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// config
import {
  repeatEveryTypeWithTranslate,
  repeatOn,
} from '_legacy/containers/Experience/translates'

// components
import Select from '_legacy/components/Form/Selects/SelectComponent'
import Radio from '_legacy/components/Form/Radio/Radio'
import Button from '_legacy/components/Button'
import { Input } from '_legacy/common/Input'
import DatePicker from '_legacy/common/DatePicker/DatePicker'

import ModalPortal from 'portals/ModalPortal'
import DaysList from 'components/forms/DaysList'
import Label from 'components/forms/Label'

// default form value
const defaultValues = {
  through: '1',
  ends: 'NEVER',
  type: repeatEveryTypeWithTranslate[0],
}

const CustomRecurrence = ({ isOpen, closeModal }) => {
  const t = useGetTranslate()
  const { register, setValue, watch } = useFormContext()

  const dateOfExperience = watch('dateOfExperience', Date.now())
  const recurringModal = watch('recurringModal', defaultValues)
  const ends = get(recurringModal, 'ends')
  const type = get(recurringModal, 'type')

  const isShowWeekSelect = type?.value === 'WEEK'
  const isShowMonthSelect = type?.value === 'MONTH'

  const onSubmit = closeModal

  const onCancel = () => {
    closeModal()
    forEach(defaultValues, (value, key) => setValue(`recurringModal.${key}`, value))
  }

  return (
    <ModalPortal isOpen={isOpen} onClose={closeModal} showCloseButton>
      <div className="recurrence-modal cr-modal default">
        <h1 className="cr-modal__header">{t('experience.modal.header')}</h1>

        <div className="cr-modal__item type-group">
          <Label>{t('experience.modal.repeat-every')}</Label>
          <div className="type-group__wrapper">
            <Input type="number" placeholder="1" name="recurringModal.through" min={1} defaultValue={1} />
            <Select
              name="recurringModal.type"
              options={repeatEveryTypeWithTranslate(t)}
              defaultValue={repeatEveryTypeWithTranslate(t)[0]}
            />
          </div>
        </div>

        {isShowWeekSelect && (
          <div className="cr-modal__item">
            <Label>{t('experience.modal.repeat-on')}</Label>
            <DaysList name="recurringModal.on" />
          </div>
        )}

        {isShowMonthSelect && (
          <div className="cr-modal__item repeat-group">
            <Label>{t('experience.modal.repeat-on')}</Label>
            <Select
              options={repeatOn(dateOfExperience)}
              defaultValue={repeatOn(dateOfExperience)[0]}
              placeholder="Repeat on"
              name="recurringModal.repeat-on"
            />
          </div>
        )}

        <div className="cr-modal__item ends-group">
          <Label>{t('experience.modal.repeat-ends.label')}</Label>

          <div className="ends-group__wrapper">
            <div className="ends-group__item">
              <Radio
                className={'radio-wrapper'}
                name="recurringModal.ends"
                value="NEVER"
                defaultChecked
                label={t('experience.modal.repeat-ends.never')}
              />
            </div>

            <div className="ends-group__item">
              <Radio
                className={'radio-wrapper'}
                register={register}
                name="recurringModal.ends"
                value="ON"
                label={t('experience.modal.repeat-ends.on')}
              />
              <DatePicker
                defaultValue={dateOfExperience}
                name="recurringModal.ends-on"
                placeholder={t('register.form.date-of-birth.placeholder')}
                disabled={ends !== 'ON'}
                minDate={dateOfExperience}
              />
            </div>

            <div className="ends-group__item">
              <Radio
                className={'radio-wrapper'}
                name={'recurringModal.ends'}
                value={'AFTER'}
                label={t('experience.modal.repeat-ends.after')}
              />
              {/* after-input */}
              <div className="ends-group__after-occurrence-input">
                <input
                  ref={register()}
                  disabled={ends !== 'AFTER'}
                  type="number"
                  placeholder="1"
                  defaultValue={1}
                  name="recurringModal.after-on"
                  min={1}
                  className={'input input-default'}
                />

                <label
                  className={cx('ends-group__after-occurrence-label', {
                    disabled: ends !== 'AFTER',
                  })}
                >
                  {t('experience.modal.occurrence')}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="button-block">
          <Button
            typeButton="button"
            text={t('experience.modal.cancel')}
            handleOnClick={onCancel}
            primary
            paddingVertical="16"
            paddingHorizontal="34"
            style={{ marginRight: 15 }}
          />
          <Button
            typeButton="button"
            text={t('experience.modal.done')}
            handleOnClick={onSubmit}
            paddingVertical="16"
            paddingHorizontal="45"
          />
        </div>
      </div>
    </ModalPortal>
  )
}

CustomRecurrence.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default CustomRecurrence
