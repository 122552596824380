import React, { useState } from 'react'
import PropTypes from 'prop-types'

// components
import CustomVideo from '_legacy/common/custom-video/CustomVideo'

import ModalPortal from 'portals/ModalPortal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const VideoModal = ({ isModalOpen, closeModal, file, showPrimaryButton = false, onPrimaryButtonClick }) => {
  const t = useGetTranslate()
  const [isHorizontal, setIsHorizontal] = useState(false)

  const onLoad = e => {
    setIsHorizontal(e.target.videoHeight <= e.target.videoWidth)
  }

  return (
    <ModalPortal isOpen={isModalOpen} onClose={closeModal} showCloseButton isMobileFullScreen>
      <div className="video-modal">
        <CustomVideo
          file={file}
          controls
          className={`video-modal--${isHorizontal ? 'horizontal' : 'vertical'}`}
          onLoadedMetadata={e => onLoad(e)}
        />
        {showPrimaryButton && (
          <button className="image-modal__primary-button" onClick={onPrimaryButtonClick}>
            {t('shared.words.make-primary')}
          </button>
        )}
      </div>
    </ModalPortal>
  )
}
VideoModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  file: PropTypes.object,
  showPrimaryButton: PropTypes.bool,
  onPrimaryButtonClick: PropTypes.func,
}
export default VideoModal
