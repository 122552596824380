import { TaxpayerStatus, types } from 'shared'

import { initialStateBiography } from '../../Talent/components/Biographies/initialStateBiography';
import { initialStateLanguages } from '../../Talent/components/Languages/initialStateLanguages';
import { initialStateCharities } from '../../Talent/components/Charities/initialStateCharities';

const { FIRST_NAME_AND_LAST_NAME } = types.talent.DisplayNameType


export const defaultValues = {
  // user fields
  user: {
    type: null,
    profileImage: null,
    firstName: '',
    lastName: '',
    nickname: '',
    email: '',
    backupEmail: '',
    address: {
      address: null,
      city: null,
      state: null,
      zipCode: '',
      country: '',
    },
    dateOfBirth: '',
    socialSecurityOrTIN: null,
    licenses: [],
    phone: '',
    isPhoneSand: true,
    isCodeConfirm: true,
    oldPhone: '',
    socialMedia: [
      {
        link: '',
        social: { value: 'FACEBOOK', label: 'Facebook' },
      },
    ],
    profileMedia: [],
    profileMediaTemp: [],
    profileMediaPrimaryFile: null,
    taxpayerStatus: TaxpayerStatus.US,
  },
  talent: {
    firstName: '',
    lastName: '',
    displayNameType: FIRST_NAME_AND_LAST_NAME,
    fullBodyImage: null,
    profileImage: null,
    halfBodyImage: null,
    location: '',
    languages: initialStateLanguages,
    nationality: '',
    charities: initialStateCharities,
    ownWords: '',
    biographies: initialStateBiography,
    licenses: [],
    primaryCategory: [],
    categories: [[]],
    promoCode: null,
  }
};
