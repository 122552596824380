import { PromotionalMediaType } from 'shared'
import c from 'lodash/compact'
import filter from 'lodash/filter'
import pick from 'lodash/pick'
import map from 'lodash/map'
import get from 'lodash/get'

const lessons = 'lessons'
const videoChat = 'videoChat'
const videoMessage = 'videoMessage'
const liveExperiences = 'liveExperiences'
const liveVirtualEvents = 'liveVirtualEvents'
const readyMadeEvents = 'readyMadeEvents'

const keys = {
  [lessons]: lessons,
  [videoChat]: videoChat,
  [videoMessage]: videoMessage,
  [liveExperiences]: liveExperiences,
  [liveVirtualEvents]: liveVirtualEvents,
  [readyMadeEvents]: readyMadeEvents,
}

const config = {
  [keys.lessons]: PromotionalMediaType.LESSONS_PROMO,
  [keys.videoChat]: PromotionalMediaType.VIDEO_CHAT_PROMO,
  [keys.videoMessage]: PromotionalMediaType.VIDEO_MESSAGE_PROMO,
  [keys.liveExperiences]: PromotionalMediaType.IN_PERSON_EXPERIENCE_PROMO,
  [keys.liveVirtualEvents]: PromotionalMediaType.LIVE_VIRTUAL_EVENT_PROMO,
  [keys.readyMadeEvents]: PromotionalMediaType.READY_MADE_LESSON_PROMO,
}

const getLessons = (res) => {
  const lessons = filter(pick(res, ['liveInPersonLesson', 'liveVirtualLesson', 'videoVoiceOverLesson']), 'id')
  return map(lessons, lesson => ({ id: get(lesson, 'id'), media: get(lesson, 'media') }))
}

const getCovers = (covers) => map(covers, cover => ({ id: cover.media.file.id, media: cover.media }))

export function getSlides({
  liveExperiences,
  videoMessage,
  videoMessagesCovers,
  videoChat,
  videoChatsCovers,
  lessons,
  lessonsCovers,
  readyMadeEvents,
  readyMadeEventsCovers,
  liveVirtualEvents,
  liveVirtualEventsCovers,
  promotionalMedia = [],
}) {
  const getPromotionalMedia = (type) => promotionalMedia.find(media => media.type === config[type])

  const liveExperiencesPromo = getPromotionalMedia(keys.liveExperiences) ? { photo: getPromotionalMedia(keys.liveExperiences)?.media, isPromo: true  } : null
  const videoChatPromo = getPromotionalMedia(keys.videoChat)?.media ? { ...getPromotionalMedia(keys.videoChat)?.media, isPromo: true } : null
  const liveVirtualEventsPromo = getPromotionalMedia(keys.liveVirtualEvents)? { ...getPromotionalMedia(keys.liveVirtualEvents), isPromo: true } : null
  const videoMessagePromo = getPromotionalMedia(keys.videoMessage)?.media ? { ...getPromotionalMedia(keys.videoMessage)?.media, isPromo: true } : null
  const lessonsPromo = getPromotionalMedia(keys.lessons) ? { media: getPromotionalMedia(keys.lessons)?.media, isPromo: true } : null
  const readyMadeEventsPromo = getPromotionalMedia(keys.readyMadeEvents) ? { ...getPromotionalMedia(keys.readyMadeEvents), isPromo: true } : null

  const LiveExperiencesCover = c([liveExperiencesPromo, ...liveExperiences || []])
  const LiveVideoChatsCover = c([videoChatPromo, ...videoChat?.media || (videoChatsCovers || []).map(v => v.media) ||[]])
  const LiveVirtualEventsCover = c([liveVirtualEventsPromo, ...(liveVirtualEvents || []), ...getCovers(liveVirtualEventsCovers) || []])
  const VideoMessagesCover = c([videoMessagePromo, ...videoMessage?.media || (videoMessagesCovers || []).map(v => v.media) || []])
  const LessonsCover = c([lessonsPromo, ...getLessons(lessons), ...getCovers(lessonsCovers) || []])
  const ReadyMadeEventsCover = c([readyMadeEventsPromo, ...(readyMadeEvents || []), ...getCovers(readyMadeEventsCovers) || []])

  return {
    LiveExperiencesCover,
    LiveVideoChatsCover,
    LiveVirtualEventsCover,
    VideoMessagesCover,
    LessonsCover,
    ReadyMadeEventsCover,
  }
}
