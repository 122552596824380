import styled from 'styled-components';

const Body1 = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: ${({ bold }) => bold ? '700' : '500'};
  font-size: 21px;
  line-height: 32px;
  
  color: #272835; // todo: fix with theme
  ${({ ...props }) => props}
`;

export default Body1;
