import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';

import Slide from '_legacy/components/TalentPublicView/Slider/Slide'
import Icon from 'assets/icons/Icon';

const Empty = ({ firstName }) => {
  const t = useGetTranslate();
  return (
    <Slide.MobileSlide.Empty
      isMirroredInside={true}
      size="big"
      icon={<Icon.VideoMessages />}
      content={
        <Trans
          tKey='talent-public-view.sliders.video-messages.empty.content.label'
          phs={[{ ph: '{{firstName}}', value: firstName }]}
        />
      }
      hint={
        <span>
          {t('talent-public-view.sliders.lessons.empty.hint.label.text')}
          {' '}
          <Link
            className="tbv-slider-mobile-slide-empty-hint-link"
            onClick={() => alert('This actions isn\'t implemented')}
          >
            {t('talent-public-view.sliders.lessons.empty.hint.label.let')}
            {' '}
            {firstName}
            {' '}
            {t('talent-public-view.sliders.lessons.empty.hint.label.know')}
            .
          </Link>
        </span>
      }
    />
  );
};

Empty.propTypes = {
  firstName: PropTypes.string.isRequired,
};

export default Empty;
