import React from 'react'
import {
  prop,
  compose,
  uniqueId,
  cond,
  where,
  __,
  identity,
  T,
  merge,
  propEq,
  omit,
  isEmpty,
} from 'lodash/fp'
import {
  FileType,
  TalentOffersType,
  PromotionalMediaType,
  errorNames,
} from 'shared'

import { getMedia as getMediaApi, bulkRemoveFile as removeMediaApi } from 'api/appApi/fileLibrary'
import { setToPromotionalMedia as setToPromotionalMediaApi } from 'api/appApi/talent/promotionalMedia'

import { upload as uploadApi } from 'api/appApi/utils'
import { fields } from '_legacy/configs/table/myMedia'
import { notify } from 'utils/notify'

import { getFileLibraryIds } from 'utils/user'
import { getNormalizeFileType } from 'utils/isFile'
import normalizeParams from 'utils/normalizeParams'

import {
  useSetMyMediaFilters,
  useSetMyMediaTable,
  useMyMediaFilters,
} from 'store/hooks/globalState/useMyMedia'
import { useUser } from 'store/hooks/globalState/useUser'
import { useCurrentPage } from 'store/hooks/globalState/useUtils'
import { sanitizeFilterFields } from '_legacy/components/Table/utils'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

const allPromoFiles = [
  PromotionalMediaType.IN_PERSON_EXPERIENCE_PROMO,
  PromotionalMediaType.LESSONS_PROMO,
  PromotionalMediaType.READY_MADE_LESSON_PROMO,
  PromotionalMediaType.VIDEO_CHAT_PROMO,
  PromotionalMediaType.VIDEO_MESSAGE_PROMO,
  PromotionalMediaType.LIVE_VIRTUAL_EVENT_PROMO,
]

const allMediaTypes = [FileType.PHOTO, FileType.VIDEO]

const allLessons = [
  TalentOffersType.VIRTUAL_LESSON,
  TalentOffersType.READY_MADE_LESSON,
  TalentOffersType.LIVE_IN_PERSON_LESSON,
]

const parseUsedIn = cond([
  [
    propEq('usedInOffersTypes', 'LESSONS'),
    merge(__, { usedInOffersTypes: allLessons }),
  ],
  [
    prop('usedInOffersTypes'),
    p => ({ ...p, usedInOffersTypes: [p.usedInOffersTypes] }),
  ],
  [T, omit('usedInOffersTypes')],
])

const parseMediaType = cond([
  [where({ mediaTypeIn: isEmpty }), merge(__, { mediaTypeIn: allMediaTypes })],
  [T, identity],
])

const parsePromo = cond([
  [
    propEq('usedInPromotionalMediaTypes', 'ALL'),
    merge(__, { usedInPromotionalMediaTypes: allPromoFiles }),
  ],
  [T, identity],
])

const normalize = compose(
  normalizeParams,
  parseUsedIn,
  parseMediaType,
  parsePromo
)

export function useFetchMyMedia() {
  const setMyMediaTable = useSetMyMediaTable((prev, next) => next)
  const setMyMediaFilters = useSetMyMediaFilters((prev, next) => ({
    ...prev,
    ...next,
  }))
  const handleResponseError = useHandleResponseError()

  return React.useCallback(
    (options, user) => {
      const query = normalize(options)
      getMediaApi({
        query: { ...query, fileLibrariesIds: getFileLibraryIds(user) },
      })
        .then(({ files, pageInfo }) => {
          setMyMediaTable(files)
          setMyMediaFilters(pageInfo)
        })
        .catch(err => {
          handleResponseError(err)
          console.warn(err)
        })
    },
    [setMyMediaTable, setMyMediaFilters]
  )
}

export function useRemoveMediaFromTable() {
  const t = useGetTranslate()
  const user = useUser()
  const page = useCurrentPage()
  const filtersOptions = useMyMediaFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )
  const fetchMedia = useFetchMyMedia()

  return React.useCallback(
    filesIds => {
      removeMediaApi({ fileLibrariesIds: getFileLibraryIds(user), filesIds })
        .then(() => {
          fetchMedia({ ...filtersOptions, page }, user)
          notify(t('remove-item-modal.notify.success'))
        })
        .catch(console.warn)
    },
    [t, filtersOptions, page, fetchMedia, user]
  )
}

// todo: implement later
export function useShareOnMyProfile() {
  return React.useCallback(() => {}, [])
}

const config = {
  [PromotionalMediaType.LESSONS_PROMO]: 'Lessons',
  [PromotionalMediaType.VIDEO_CHAT_PROMO]: 'Video Chat',
  [PromotionalMediaType.VIDEO_MESSAGE_PROMO]: 'Video Message',
  [PromotionalMediaType.IN_PERSON_EXPERIENCE_PROMO]: 'Experiences',
  [PromotionalMediaType.LIVE_VIRTUAL_EVENT_PROMO]: 'Live Virtual Event',
  [PromotionalMediaType.READY_MADE_LESSON_PROMO]: 'Ready Made Lessons',
}

export function useSetPromotionalMedia() {
  const t = useGetTranslate()
  const page = useCurrentPage()
  const user = useUser()
  const filtersOptions = useMyMediaFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )
  const fetchMedia = useFetchMyMedia()

  return React.useCallback(
    ({ fileToken, promotionalMediaTypes }) => {
      setToPromotionalMediaApi({ fileToken, promotionalMediaTypes: promotionalMediaTypes })
        .then(() => {
          fetchMedia({ ...filtersOptions, page }, user)
          notify(t('set-to-promotional.notify.success'))
        })
        .catch(error => {
          if (
            error.name ===
            errorNames.promotionalMedia
              .SET_PROMOTIONAL_MEDIA_FOR_NOT_EXIST_OFFER_TYPE
          ) {
            const foreignPromo = error.context.wrongTypes
              .map(type => config[type])
              .join(', ')
            notify(t('set-to-promotional.notify.error') + foreignPromo, 'error')
          }
        })
    },
    [t, filtersOptions, page, fetchMedia, user]
  )
}

const createTempFile = file => ({
  uploadedAt: new Date(),
  type: getNormalizeFileType(file.type),
  originalName: file.name,
  offers: [],
  promotionalMedia: [],
  id: uniqueId('file'),
  fullUrl: URL.createObjectURL(file),
  mediaLoading: true,
})

export function useUploadMedia() {
  const page = useCurrentPage()
  const user = useUser()
  const filtersOptions = useMyMediaFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )
  const fetchMedia = useFetchMyMedia()
  const addFileToMyMediaTable = useSetMyMediaTable((prev, next) => [
    next,
    ...prev,
  ])

  return React.useCallback(
    file => {
      const tempFile = createTempFile(file)
      addFileToMyMediaTable(tempFile)
      uploadApi(file, {}, true)
        .then(() => fetchMedia({ ...filtersOptions, page }, user))
        .catch(console.warn)
    },
    [addFileToMyMediaTable, fetchMedia, page, filtersOptions, user]
  )
}
