import React from 'react';
import filter from 'lodash/filter';
import pick from 'lodash/pick';
import map from 'lodash/map';
import get from 'lodash/get';

import Slide from '_legacy/components/TalentPublicView/Slider/Slide';
import LiveExperiences from '_legacy/components/TalentPublicView/Slider/prototypes/LiveExperiences';
import LiveVideoChats from '_legacy/components/TalentPublicView/Slider/prototypes/LiveVideoChats';
import VideoMessages from '_legacy/components/TalentPublicView/Slider/prototypes/VideoMessages';
import LiveVirtualEvents from '_legacy/components/TalentPublicView/Slider/prototypes/LiveVirtualEvents';
import ReadyMadeEvents from '_legacy/components/TalentPublicView/Slider/prototypes/ReadyMadeEvents';
import Lessons from '_legacy/components/TalentPublicView/Slider/prototypes/Lessons';

import { getMedia, isVideo } from '_legacy/components/TalentPublicView/Slider/prototypes/LiveVirtualEvents/Desktop/LiveVirtualEvents';
import * as r from '_legacy/constants/routes';

import { getDateLabel } from '_legacy/components/TalentPublicView/Slider/prototypes/LiveExperiences/Desktop/LiveExperiences'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getFullMonthDate } from 'utils/date'

export const transformLiveExperiencesSlides = ({ liveExperiencesSlides, firstName, plaqueNickname }) => {
  const t = useGetTranslate()
  const _getDate = getDateLabel(t)

  if (liveExperiencesSlides.length === 0) {
    return [<LiveExperiences.Mobile.Empty key={'empty-live-experiences'} firstName={firstName} isMobile />];
  }

  return liveExperiencesSlides.map(slide => {
    const { id, photo, video, name, sequentialId } = slide

    const path = `/${r.EXPERIENCES}/${plaqueNickname}/${sequentialId}`

    return (
      <Slide.MobileSlide
        key={id}
        file={video || photo}
        path={path}
        isPromo={slide.isPromo}
        overlay={
          <LiveExperiences.Mobile.Overlay
            date={_getDate(slide)}
            name={name}
            isVideo={video}
          />
        }
      />
    );
  }
  );
};

export const transformVideoMessagesSlides = ({ videoMessagesSlides, videoMessagesOfferId, firstName, plaqueNickname }) => {

  if (videoMessagesSlides.length === 0) {
    return [<VideoMessages.Mobile.Empty key={'empty-video-messages'} firstName={firstName} isMobile />];
  }

  return videoMessagesSlides.map(slide =>
    <Slide.MobileSlide
      key={slide.id}
      file={slide}
      isPromo={slide.isPromo}
      path={`/${r.VIDEO_MESSAGE}/${plaqueNickname}/${videoMessagesOfferId}`}
    />)
};

export const transformLiveVideoChatsSlides = ({ videoChatsSlides, videoChatsOfferId, firstName, plaqueNickname }) => {
  if (videoChatsSlides.length === 0) {
    return [<LiveVideoChats.Mobile.Empty key={'empty-live-video-chats'} firstName={firstName} isMobile />];
  }

  return videoChatsSlides.map((slide) =>
    <Slide.MobileSlide
      key={slide.id}
      file={slide}
      isPromo={slide.isPromo}
      path={`/${r.VIDEO_CHAT}/${plaqueNickname}/${videoChatsOfferId}`}
    />);
};

export const transformLessonsSlides = ({ lessonsSlides, firstName, plaqueNickname }) => {
  if (lessonsSlides.length === 0) {
    return [<Lessons.Mobile.Empty key={'empty-lessons'} firstName={firstName} isMobile />];
  }

  return lessonsSlides.map(slide =>
    <Slide.MobileSlide
      isPromo={slide.isPromo}
      id={slide.id} key={slide.media.id} file={slide.media}
      path={`/${r.LESSONS}/${plaqueNickname}`}
    />);
};

export const transformReadyMadeEventsSlides = ({ readyMadeEvents, firstName, plaqueNickname }) => {
  if (readyMadeEvents.length === 0) {
    return [<ReadyMadeEvents.Mobile.Empty key={'empty-lessons'} firstName={firstName} isMobile />];
  }

  return readyMadeEvents.map(event => {
    const { id, productName, sequentialId } = event

    return (
      <Slide.MobileSlide
        key={id}
        isPromo={event.isPromo}
        file={getMedia(event)}
        path={`/${r.READY_MADE_EVENT_LESSON}/${plaqueNickname}/${sequentialId}`}
        overlay={<ReadyMadeEvents.Mobile.Overlay name={productName} isVideo={isVideo(event)}/>}
      />
    );
  })
}

export const transformLiveVirtualEventsSlides = ({ liveVirtualEvents, firstName, plaqueNickname }) => {
  const t = useGetTranslate()
  if (liveVirtualEvents.length === 0) {
    return [
      <LiveVirtualEvents.Mobile.Empty
        key={'empty-live-virtual-events'}
        firstName={firstName}
        isMobile
        hideControl
        customTitle={t('shared.words.coming-soon')}
      />
    ];
  }

  return liveVirtualEvents.map(event => {
    const { id, date, name, sequentialId } = event

    return (
      <Slide.MobileSlide
        key={id}
        isPromo={event.isPromo}
        file={getMedia(event)}
        path={`/${r.LIVE_VIRTUAL_EVENTS}/${plaqueNickname}/${sequentialId}`}
        overlay={<LiveVirtualEvents.Mobile.Overlay
          key={id}
          date={getFullMonthDate(date)}
          name={name}
          isVideo={isVideo(event)}
        />}
      />
    );
  })
};

export function getLessons(res) {
  const lessons = filter(pick(res, ['liveInPersonLesson', 'liveVirtualLesson', 'videoVoiceOverLesson']), 'id')
  return map(lessons, lesson => ({ media: get(lesson, 'media'), }))
}
