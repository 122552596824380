import get from 'lodash/get'
import { TaxpayerStatus } from 'shared'

import {
  getAddressAdapter,
  getLicensesAdapter,
  getDateAdapter,
  getCharitiesWithoutPercentAdapter,
  getBiographiesAdapter,
  getCategoryAdapter,
  getNationalitiesAdapter,
  getLanguagesAdapter,
  getLocationAdapter,
  getCategoryArrayAdapter,
  getFullBodyImage,
  getHalfBodyImage,
  getProfileImage,
  getFullBodyImages,
  getSocialMediaAdapter,
  getTimeZoneAdapter,
} from 'utils/forms/adapters'
import { getMultiSelectLibraryAdapter } from 'utils/services/validators/library'

function getUser(user) {
  if (!user) return undefined;
  return {
    type: get(user, 'type'),
    profileImage: get(user, 'profileImage.file.fileToken'),
    firstName: get(user, 'firstName', ''),
    lastName: get(user, 'lastName', ''),
    nickname: get(user, 'nickname', ''),
    email: get(user, 'email', ''),
    backupEmail: get(user, 'backupEmail', ''),
    address: getAddressAdapter(user?.address),
    timezone: getTimeZoneAdapter(user?.timeZoneId),
    dateOfBirth: getDateAdapter(user?.dateOfBirth),
    socialSecurityOrTIN: get(user, 'socialSecurityOrTIN', ''),
    licenses: getLicensesAdapter(user?.licenses),
    isPhoneSand: true,
    isCodeConfirm: true,
    oldPhone: get(user, 'phone'),
    phone: get(user, 'phone'),
    taxpayerStatus: get(user, 'taxpayerStatus') ?? TaxpayerStatus.US,
    socialMedia: getSocialMediaAdapter(user?.socialMedia),
    ...getMultiSelectLibraryAdapter('profileMedia', user),
  };
}

function getTalent(talent, t, allCategories) {
  if (!talent) return undefined;

  return {
    firstName: get(talent, 'firstName'),
    lastName: get(talent, 'lastName'),
    nickname: get(talent, 'nickname'),
    ownWords: get(talent, 'ownWords'),
    charities: getCharitiesWithoutPercentAdapter(talent?.charities),
    licenses: getLicensesAdapter(talent?.licenses),
    location: getLocationAdapter(talent?.location),
    languages: getLanguagesAdapter(talent?.languages, t),
    nationality: getNationalitiesAdapter(talent?.nationality),
    primaryCategory: getCategoryAdapter(allCategories, talent?.primaryCategory),
    categories: getCategoryArrayAdapter(allCategories, talent?.categories),
    biographies: getBiographiesAdapter(talent?.biographies),
    fullBodyImage: getFullBodyImage(talent?.fullBodyImage),
    halfBodyImage: getHalfBodyImage(talent?.halfBodyImage),
    profileImage: getProfileImage(talent?.profileImage),
    fullBodyImages: getFullBodyImages(talent?.fullBodyImages, talent?.fullBodyImage),
    promoCode: get(talent, 'promoCode'),
  };
}

export function getAdapter(res, t, allCategories) {
  const user = get(res, 'user')
  const talent = get(res, 'talent')
  return {
    user: getUser(user),
    talent: getTalent(talent, t, allCategories),
  };
}
