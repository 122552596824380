import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MediaViewer from '../MediaViewer';
import { StyledMobileContent, StyledMobileSlide } from './MobileEmpty';

const MobileSlide = ({ file, overlay }) => {
  const isCurrent = true;
  const isNext = false;

  const [globalIsPlay, setGlobalIsPlay] = React.useState(false)

  return (
    <StyledMobileSlide>
      <StyledMobileContent margin={0} border>
        <StyledContentBorder>
          <MediaViewer
            file={file}
            isCurrent={isCurrent}
            isNext={isNext}
            isMobile={true}
            setGlobalIsPlay={setGlobalIsPlay}
          />
        </StyledContentBorder>
        {!globalIsPlay ? overlay : null}
      </StyledMobileContent>
    </StyledMobileSlide>
  );
};

const StyledContentBorder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

MobileSlide.propTypes = {
  slide: PropTypes.object.isRequired,
  overlay: PropTypes.object,
  file: PropTypes.object,
};

export default MobileSlide;
