import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import Input from '_legacy/components/RegisterForms/shared/components/Input';
import TipButton from '_legacy/components/Form/LabelTips/TipButton';
import NicknameInfoModal from '_legacy/components/Modal/NicknameInfoModal';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { checkNickname as checkNicknameApi } from 'api/appApi/utils';
import {
  checkNicknameMaxLength,
  checkNicknameMinLength,
  isNicknameContainsForbiddenWords,
} from 'utils/services/validators/nicknameValidator'

const errors = {
  NICKNAME_IS_TOO_SHORT: 'shared.input.nickname.errors.nickname-is-too-short',
  NICKNAME_IS_TOO_LONG: 'shared.input.nickname.errors.nickname-is-too-long',
  NICKNAME_IS_NOT_UNIQUE: 'shared.input.nickname.errors.nickname-is-not-unique',
  VALIDATION_FAILED: 'shared.input.nickname.errors.nickname-is-not-valid',
  NICKNAME_IS_FORBIDDEN: 'shared.input.nickname.errors.nickname-is-forbidden',
};

const Nickname = ({ name = 'nickname' ,...props }) => {
  const t = useGetTranslate();
  const { clearErrors, setError, control, formState } = useFormContext();
  const nickname = useWatch({ name, control, defaultValue: '' });
  const isDirty = get(formState.dirtyFields, name, false);
  const [localUniqError, setLocalUniqError] = React.useState();

  const checkNickname = async nickname => {
    if (checkNicknameMinLength(nickname)) {
      setError(name, { message: errors.NICKNAME_IS_TOO_SHORT });
      return;
    }

    if (checkNicknameMaxLength(nickname)) {
      setError(name, { message: errors.NICKNAME_IS_TOO_LONG });
      return;
    }

    if (isNicknameContainsForbiddenWords(nickname)) {
      setError(name, { message: errors.NICKNAME_IS_FORBIDDEN });
      return
    }

    try {
      await checkNicknameApi({ nickname });
      clearErrors(name);
      setLocalUniqError(null);
    } catch (error) {
      const message = get(errors, error.name);
      setError(name, { message });
      setLocalUniqError(message);
    }
  };

  const debounceCheck = React.useCallback(debounce(checkNickname, 1000), []);

  React.useEffect(() => {
    if (isDirty) {
      debounceCheck(nickname);
    }
  }, [nickname, isDirty]);

  return (
    <Input
      isRequired
      name={name}
      validation={{ validate: () => localUniqError }}
      label={t('register-user.form.nick-name.label')}
      tip={<TipButton modalComponent={NicknameInfoModal} />}
      placeholder={t('register-user.form.nick-name.placeholder')}
      {...props}
    />
  );
};

Nickname.propTypes = {
  name: PropTypes.string
};

export default Nickname;
