import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import MobileMenuListItem from './MobileMenuListItem'
import { useOnClickOutside } from 'store/hooks/useOnClickOutside'
import { useNavigationMenu } from '_legacy/configs/navigation-menu/useNavigationMenu'

const MobileMenuList = ({ setMobileMenuActive }) => {
  const accountItems = useNavigationMenu()
  const [selectedItem, setSelectedItem] = useState(null)

  const selectItem = item => {
    setSelectedItem(prev => (prev === item ? false : item))

    const isLink = accountItems.find(i => i.slug === item)?.isLink
    if (isLink) {
      setMobileMenuActive(false)
    }
  }

  const node = useRef()
  useOnClickOutside(node, () => setSelectedItem(false))


  return (
    <nav className="mobile-menu__list" ref={node}>
      {accountItems
        .map(({ slug, icon, name, linkProps, isLink, linkTo, onLinkClick, hasUnreadChatMessages: ucm, IconComponent }) => (
          <MobileMenuListItem
            key={slug}
            slug={slug}
            icon={icon}
            name={name}
            linkProps={linkProps}
            isLink={isLink}
            linkTo={linkTo}
            onLinkClick={onLinkClick}
            setMobileMenuActive={setMobileMenuActive}
            selected={selectedItem && selectedItem === slug}
            onItemSelect={selectItem}
            hasUnreadChatMessages={ucm}
            IconComponent={IconComponent}
          />
        ))}
    </nav>
  )
}

MobileMenuList.propTypes = {
  setMobileMenuActive: PropTypes.func,
}

export default MobileMenuList
