import styled from 'styled-components';

const H1 = styled.h1`
  font-family: 'Playfair Display', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 1.25;
  font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

  color: #272835;
  ${({ ...props }) => props}
`;

export default  H1;
