import React from 'react'
import get from 'lodash/get'

import CommercialEngagementsForm from './CommercialEngagementsForm'
import Relationship from './Relationship'
import Main from './Main';
import PageLoader from '_legacy/common/Template/PageLoader'
import { useFetchCommercialEngagements } from 'requests/commercial-engagements'
import {
  useCommercialEngagements,
  useSetCommercialEngagements,
} from 'store/hooks/globalState/useCommercialEngagements'
import { scrollToRef } from 'utils/scrollToRef'

const CommercialEngagements = () => {
  const commercialEngagements = useCommercialEngagements()
  const fetchCommercialEngagements = useFetchCommercialEngagements()
  const clearCommercialEngagements = useSetCommercialEngagements(() => null)
  const commercialVideoMessage = get(commercialEngagements, 'commercialVideoMessage')
  const $el = document.querySelector('#header')

  const relationshipRef = React.useRef(null)
  const formRef = React.useRef()

  React.useEffect(() => {
    fetchCommercialEngagements()
    return () => {
      clearCommercialEngagements()
    }
  }, [])

  React.useEffect(() => {
    if ($el) {
      $el.classList.remove(commercialVideoMessage ? 'dark-theme' : 'light-theme')
      $el.classList.add(commercialVideoMessage ? 'light-theme' : 'dark-theme')
    }
  }, [commercialEngagements, $el])

  if (!commercialEngagements) return <PageLoader />

  const scrollToRelationship = () => scrollToRef(relationshipRef)
  const scrollToForm = () => scrollToRef(formRef)

  return (
    <>
      {commercialVideoMessage && (
        <div className='content-wrapper'>
          <Main onClick={scrollToRelationship} />
          <Relationship ref={relationshipRef} onClick={scrollToForm} />
        </div>
      )}
      <CommercialEngagementsForm ref={formRef} />
    </>
  );
};

export default CommercialEngagements
