import React from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';

import PhoneInput from '_legacy/common/Input/PhoneInput';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { checkPhone as checkPhoneApi } from 'api/appApi/utils';
import { sharedErrors } from './phoneErrorMessages';

const PhoneWithAsyncValidation = ({ isEditable, disabled, fields }) => {
  const name = get(fields, 'phone');
  const t = useGetTranslate();
  const { clearErrors, setError, control, formState, getValues } = useFormContext();
  const phone = useWatch({ name, control, defaultValue: '' });
  const isPhoneSand = useWatch({ name: get(fields, 'isPhoneSand'), control, defaultValue: false });

  const isDirty = get(formState.dirtyFields, name, false);
  const [localUniqError, setLocalUniqError] = React.useState();

  const checkPhone = async phone => {
    try {
      await checkPhoneApi({ phone });
      clearErrors(name);
      setLocalUniqError(null);
    } catch (e) {
      const message = t(sharedErrors[e.name]);
      setError(name, { message });
      setLocalUniqError(message);

      if (getValues(name) === getValues(fields.oldPhone)) {
        clearErrors(name);
        setLocalUniqError(() => null);
      }
    }
  };

  const debounceCheck = React.useCallback(debounce(checkPhone, 800), []);

  React.useEffect(() => {
    if (!isPhoneSand && (isEditable || isDirty) && phone) {
      debounceCheck(phone);
    }
  }, [phone, isDirty, isPhoneSand]);

  return (
    <PhoneInput
      name={name}
      disabled={disabled}
      validation={{ validate: () => localUniqError }}
    />
  );
};

PhoneWithAsyncValidation.propTypes = {
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isEditable: PropTypes.bool,
  fields: PropTypes.object,
};

export default PhoneWithAsyncValidation;
