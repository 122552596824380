import React from 'react';
import styled from 'styled-components';
import { types } from 'shared'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { Row } from '_legacy/common/Template';
import SingleSelectLibrary from '_legacy/components/Library/SingleSelectLibrary'
import MediaBackdrop from '_legacy/components/Library/configs/Media/MediaBackdrop';
import MediaPicture from '_legacy/components/Library/configs/Media/MediaPicture';
import DropzoneBackdrop from './DropzoneBackdrop';

const { PHOTO } = types.file.Types

const Image = () => {
  const t = useGetTranslate();

  return (
    <StyledImage
      nameBlock={t('create-experience.form.image.label')}
      component={
        <SingleSelectLibrary
          name='photo'
          rules={{ required: 'shared.words.required' }}
          libraryModalProps={{ enableTypes: PHOTO }}
          dropZoneProps={{ accept: PHOTO, hintComponent: DropzoneBackdrop }}
          components={{ customBackdrop: MediaBackdrop, customPicture: MediaPicture }}
        />
      }
    />
  );
};

const StyledImage = styled(Row)`
  .single-select__library-button {
    padding: 0;
    width: 100%;
    height: 230px;
  }
  
  .icon-camera {
    margin-top: 0;
    padding-top: 12px;
  }
`;

export default Image;
