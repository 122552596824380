import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get'
import { types } from 'shared';

import VideoViewer from './VideoViewer';
import CustomPicture from '_legacy/common/CustomPicture'

const { VIDEO, PHOTO } = types.file.Types

const MediaViewer = ({ file, ...props }) => {
  if(!get(file, 'file')) return null;

  const fileType = get(file, 'file.type')
  if (fileType === VIDEO) {
    return (
      <VideoViewer
        file={file}
        {...props}
      />
    );
  }

  if (fileType === PHOTO) {
    return (
      <CustomPicture
        className='tbv-slider-slide-content-media'
        file={file}
      />
    )
  }

  return null;
};

MediaViewer.propTypes = {
  file: PropTypes.object
};

export default MediaViewer;
