// TODO: Refactor wrappers and forms according to DRY after project ends
import React, { useEffect } from 'react'
import { object, arrayOf } from 'prop-types'
import { isEmpty } from 'lodash'
import { useForm, FormProvider } from 'react-hook-form'

import { FileType } from 'shared'

import { OFFERINGS } from '_legacy/constants/routes.js'
import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler'

import { useSetPathToNavigate } from 'store/hooks/globalState/useSwitchNavigation.js'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates.js'

import { initialCharities } from 'utils/services/loaders/charities'

import { voiceOverLessonFormAdapter } from './utils.js'

import { useUpdateVideoVoiceOverLesson } from 'requests/lessons.js'

import Button from '_legacy/components/Button/'
import Radio from '_legacy/components/Form/Radio/Radio.js'
import TextAreaCommon from '_legacy/components/Form/Inputs/TextAreaCommon.js'
import CharitiesSelect from '_legacy/components/Form/Selects/basic/CharitiesSelect.js'
import SingleSelectLibrary from '_legacy/components/Library/SingleSelectLibrary/'
import MediaPicture from '_legacy/components/Library/configs/Media/MediaPicture.js'
import { ErrorMessage } from '_legacy/common'

import OrderedRulesList from 'components/OrderedRulesList/'

import LessonBackdrop from '../LessonBackdrop/'
import PriceWithLabel from '../PriceWithLabel/'

const { PHOTO, VIDEO } = FileType


const VoiceOverLessonForm = ({
  defaultData,
  rules,
}) => {
  useSetPathToNavigate({ talentRoute: `/${OFFERINGS}`, agentRoute: '/' })

  const t = useGetTranslate()

  const [loading, setLoading] = React.useState()

  const updateVideoVoiceOverLesson = useUpdateVideoVoiceOverLesson()

  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      charities: initialCharities,
    },
  })

  useEffect(() => {
    if (!isEmpty(defaultData) && !methods.getValues('price')) {
      const data = voiceOverLessonFormAdapter(defaultData)
      methods.reset(data)
    }
  }, [defaultData])

  return (
    <FormProvider {...methods}>
      <ChangeRouteHandler
        isDraft={false}
        onSubmit={methods.handleSubmit(value => updateVideoVoiceOverLesson(value, setLoading))}
        shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
      />

      <form
        className="lesson-edit-form lesson-edit-form--voice-over-lesson darken"
        onSubmit={methods.handleSubmit(value => updateVideoVoiceOverLesson(value, setLoading))}
      >
        <OrderedRulesList rules={rules} />

        <div className="lessons-field include-service">
          <h3 className="label">{t('lessons.label.services-include')}</h3>

          <div className="include-service__inner">
            <Radio
              value={true}
              name="active"
              register={methods.register}
              label={t('shared.words.label.yes')}
              defaultChecked
              whiteTheme
            />

            <Radio
              value={false}
              name="active"
              register={methods.register}
              label={t('shared.words.label.no')}
              whiteTheme
            />
          </div>
        </div>

        <PriceWithLabel
          name='price'
          label={t('lessons.label.price')}
          placeholder='lessons.placeholder.price'
          requiredMessage='lessons.required.price'
          isRequired
          whiteTheme
        />

        <div className="lessons-field">
          <h3 className="label">{t('lessons.label.which-charities')}</h3>
          <CharitiesSelect
            name='charities'
            error={methods.errors.charities}
            blackTheme
            className='lesson-charities'
          />
        </div>

        <div className="lessons-field">
          <h3 className="label">{t('lessons.label.media.name')}</h3>
          <div className="lesson-media-wrapper">
            <SingleSelectLibrary
              name='media'
              components={{ customBackdrop: LessonBackdrop, customPicture: MediaPicture }}
              dropZoneProps={{ accept: [PHOTO, VIDEO] }}
              rules={{ required: 'lessons.label.media.required' }}
            />
          </div>
        </div>

        <TextAreaCommon
          register={methods.register({
            maxLength: {
              value: 500,
              message: t('lessons.errors.special-instructions.maxLength'),
            },
          })}
          name="specialInstructions"
          placeholder={t('lessons.placeholder.special-instructions')}
          label={t('lessons.label.special-instructions')}
          watch={methods.watch}
          max={500}
          error={methods.errors.specialInstructions}
          containerClassName="lessons-textarea lessons-field"
        />

        <Button
          disabled={loading}
          text={t('lessons.button.publish')}
          typeButton="submit"
          paddingVertical="13"
          classBtn="lessons__button"
        />
        {!isEmpty(methods.formState.errors) &&
          <ErrorMessage
            message={t(
              'register-aficionado.form.errors.required-data-is-missing'
            )}
          />
        }
      </form>
    </FormProvider>
  )
}

VoiceOverLessonForm.defaultProps = {
  defaultData: {},
}

VoiceOverLessonForm.propTypes = {
  defaultData: object,
  rules: arrayOf(object),
}

export default VoiceOverLessonForm
