import React from 'react'
import { useForm } from 'react-hook-form'
import compose from 'lodash/fp/compose'
import isEmpty from 'lodash/isEmpty';

import Form from '_legacy/components/VideoChatForm'
import { submitVideoChatAdapter } from '_legacy/components/VideoChatForm/utils'
import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler';
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'

import { useCreateVideoChat } from 'requests/video-chat';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useSetPathToNavigate } from 'store/hooks/globalState/useSwitchNavigation';
import { OFFERINGS } from '_legacy/constants/routes';

const CreateVideoChat = React.memo(() => {
  useSetPathToNavigate({ talentRoute: `/${OFFERINGS}`, agentRoute: '/' })

  const t = useGetTranslate()
  const createVideoChat = useCreateVideoChat()

  const methods = useForm({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      'whichCharities': [{
        'charity': { label: null, value: null },
        'percent': ''
      }]
    }
  })

  const onSubmit = compose(
    createVideoChat,
    submitVideoChatAdapter
  )

  return (
    <div className="video-chat">
      <h1 className="header mb-62">{t('video-chat.header.title.create')}</h1>
      <Form
        methods={methods}
        onSubmit={onSubmit}
        onPreview={() => alert('This action isn\'t implemented')}
        onDraft={() => alert('This action isn\'t implemented')}
      />
      <ChangeRouteHandler
        isDraft={false}
        onSubmit={methods.handleSubmit(onSubmit)}
        shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
      />
    </div>
  )
})

export default withLibrary(CreateVideoChat)
