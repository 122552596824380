import React from 'react';
import styled from 'styled-components';

import LegalTermsList from '_legacy/containers/CommercialEngagements/CommercialEngagementsRequest/CommercialEngagementsRequestForm/component/LegalTermsList';
import Body2 from '_legacy/common/Typography/Body2';

const LegalTerms = ({ className }) => {
  return (
    <LegalTermsContainer className={className}>
      <Body2 bold>Terms</Body2>
      <LegalTermsList />
    </LegalTermsContainer>
  );
};

const LegalTermsContainer = styled.div`
  background: rgba(186, 187, 205, 0.7);
  padding: 8px 16px;
  
  .list {
    margin-right: 0;
    margin-left: 0;

    &__item {
      color: #272835;
  
      &:not(:last-of-type) {
        margin-bottom: 6px;
      }
    }
  }

`;

export default LegalTerms;
