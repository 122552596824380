import React from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from './AsyncSelectComponent';
import { loadLocationsOptions } from 'utils/services/loaders/locations';

const LocationsSelect = (props) => {
  const locationsLoader = async (...props) => {
    if (props[0].trim().length === 0) return { options: [], hasMore: false };
    const response = await loadLocationsOptions(...props);
    return response;
  };

  return (
    <AsyncSelect
      isShowArrow={false}
      openMenuOnClick={false}
      loadOptions={locationsLoader}
      {...props}
    />
  );
};

LocationsSelect.propTypes = {
  placeholder: PropTypes.string,
  clearErrors: PropTypes.func,
  blackTheme: PropTypes.bool,
  name: PropTypes.string,
  rules: PropTypes.object,
  error: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  watch: PropTypes.func,
  setValue: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  isCreatable: PropTypes.bool,
};

export default LocationsSelect;
