import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import sumBy from 'lodash/sumBy';
import isNil from 'lodash/isNil';


import ArrayListItem from '_legacy/common/Template/ArrayListItem';
import PercentInput from '_legacy/components/Form/Inputs/MaskInputs/PercentInput';
import AsyncSelect from './AsyncSelectComponent';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { loadCharitiesOptions } from 'utils/services/loaders/charities';

const CharitiesSelect = ({ blackTheme, name = 'charities', showSeparator = true, className = '' }) => {
  const t = useGetTranslate();
  const { control, watch, trigger, setValue, clearErrors } = useFormContext();
  const { fields, append, remove } = useFieldArray({ name, control });
  const charities = watch(name, []);

  const isShowAddButton = fields.length < 4;

  // trigger validation on change
  const triggerFields = () => {
    fields.forEach((_, index) => {
      trigger([`${name}[${index}].percent`, `${name}[${index}].charity`]);
    },
    );
  };

  const appendHandler = () => {
    append({
      charity: { label: null, value: null },
      percent: null,
    });
  };

  const _loadCharitiesOptions = async (...props) => {
    const charities = watch(name, []);
    const response = await loadCharitiesOptions(...props);

    return {
      ...response,
      options: response.options.filter(
        o => !charities.find(c => c.charity?.value === o.value),
      ),
    };
  };

  // validators
  const checkCharityForRequired = (index, charities) => {
    const percent = charities?.[index]?.percent || charities?.[index]?.percent?.ref?.value;
    const charity = charities?.[index]?.charity?.value;
    return !!percent <= !!charity;
  };

  const checkPercentForRequired = (index, charities) => {
    const percent = charities?.[index]?.percent || charities?.[index]?.percent?.ref?.value;
    const charity = charities?.[index]?.charity?.value;
    return !!percent >= !!charity;
  };

  const duplicate = (_charity, index) => {
    if (isNil(_charity?.value)) return true;

    return !watch(name, [])
      .filter((_, idx) => idx !== index)
      .find(({ charity }) => charity?.value === _charity.value);
  };

  const lessThenHundred = () => {
    const charities = watch(name, []);
    const sumOfPercents = sumBy(charities, c => +c.percent || 0);
    return sumOfPercents <= 100;
  };

  useEffect(() => {
    triggerFields();
  }, []);

  useEffect(() => {
    if (fields.length === 0) {
      appendHandler();
    }
  }, [fields]);

  const removeHandler = (index) => {
    if (index === 0 && fields.length === 1) {
      setValue(name, [{ charity: null, percent: null }]);
      clearErrors(name);
    } else {
      remove(index);
    }
  };

  const showRemoveButton = idx => {
    if (idx !== 0) return true;
    return !!charities?.[0].charity?.value || !!charities?.[0].percent;
  };

  return (
    <ul className={`charities__list ${className}`}>
      {fields.map((c, index) => (
        <ArrayListItem key={c.id} className='charities__inner' onRemove={() => removeHandler(index)} showRemoveButton={showRemoveButton(index)} showSeparator={showSeparator}>
          <div className={'charities__charity'}>
            <AsyncSelect
              loadOptionsOnMenuOpen={false}
              loadOptions={_loadCharitiesOptions}
              placeholder={t('shared.charities.charity.placeholder')}
              isClearable={false}
              defaultValue={c.charity}
              name={`${name}[${index}].charity`}
              blackTheme={blackTheme}
              rules={{
                validate: {
                  required: () => checkCharityForRequired(index, watch(name)) || 'shared.words.required',
                  duplicate: c => duplicate(c, index) || 'shared.charities.charity.errors.duplicate'
                }
              }}
              onChange={triggerFields}
            />
          </div>
          <div className={'charities__percent'}>
            <PercentInput
              placeholder={t('shared.charities.percent.placeholder')}
              containerClassName='lessons-input lessons-field'
              name={`${name}[${index}].percent`}
              onChange={triggerFields}
              defaultValue={c.percent}
              rules={{
                validate: {
                  maximumIsHundred: () => lessThenHundred() || 'shared.charities.percent.errors.maximum-allocate',
                  checkRequired: () => checkPercentForRequired(index, watch(name)) || 'shared.words.required',
                },
              }}
            />
          </div>
        </ArrayListItem>
      ))}

      {isShowAddButton && (
        <button
          type='button'
          onClick={appendHandler}
          className="multiple-location__button"
        >
          + {t('shared.words.add-more')}
        </button>
      )}
    </ul>
  );
};

CharitiesSelect.propTypes = {
  clearErrors: PropTypes.func,
  blackTheme: PropTypes.bool,
  name: PropTypes.string,
  error: PropTypes.array,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  trigger: PropTypes.func,
  showSeparator: PropTypes.bool,
  className: PropTypes.string,
};

export default CharitiesSelect;
