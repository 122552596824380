import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { getCharitiesLabel } from 'utils/services/loaders/charities';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useCommercialEngagements } from 'store/hooks/globalState/useCommercialEngagements';

const Charities = () => {
  const t = useGetTranslate();
  const commercialEngagements = useCommercialEngagements();
  const whichCharities = get(
    commercialEngagements,
    'commercialVideoMessage.whichCharities'
  );

  return (
    <CharitiesContainer>
      <CharitiesHeader>{t('commercial-engagements.main.charities.header')}</CharitiesHeader>

      <CharitiesList>
        {whichCharities.map(charity => (
          <CharitiesListItem key={charity.label}>
            &nbsp;{getCharitiesLabel(charity.charity)} ({charity.percent}%);
          </CharitiesListItem>
        ))}
      </CharitiesList>

      <CharitiesTip>{t('commercial-engagements.main.charities.tip')}</CharitiesTip>
    </CharitiesContainer>
  );
};

const CharitiesContainer = styled.div`
  margin-bottom: 12px;

  @media screen and (max-width: 991px) {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
  }
`;

const CharitiesHeader = styled.h4`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  display: inline;

  color: ${({ theme }) => theme.colors.blue};

  @media screen and (max-width: 767px) {
    text-align: center;
    display: block;
  }
`;

const CharitiesList = styled.ul`
  display: inline;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const CharitiesListItem = styled.li`
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  display: inline;

  color: ${({ theme }) => theme.colors.blue};

  @media screen and (max-width: 767px) {
    text-align: center;
    display: block;
  }
`;

const CharitiesTip = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;

  color: ${({ theme }) => theme.colors.lightGrey};

  @media screen and (max-width: 767px) {
    text-align: center;
  }
`;

export default Charities;
