/* eslint-disable no-console */
import React from 'react'
import { useHistory } from 'react-router-dom'
import { errorNames } from 'shared'

import { routesList } from 'router/routesMap'
import { notify } from 'utils/notify'

import { bookExperienceByOccurrenceId, bookExperienceTimeSlot } from 'api/appApi/offers/publicViews/experience'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getEntourageError } from 'utils/services/errors/entourage-errors'
import { useUser } from 'store/hooks/globalState/useUser'
import { getUserEntourageType } from 'utils/user'

const offersErrors = errorNames.offers

const bookingErrorMessages = {
  [offersErrors.BOOK_ALREADY_HAS_ORDER_ERROR]: 'experience.booking.errors.book-already-has-order',
  [offersErrors.BOOK_OUT_OF_TIME_BOUND_ERROR]: 'experience.booking.errors.book-out-time-bound',
  [offersErrors.BOOK_PARTICIPANTS_LIMIT_EXCEEDED_ERROR]: 'experience.booking.errors.book-participants-limit-exceeded',
  [offersErrors.BOOK_WRONG_TYPE_ERROR]: 'experience.booking.errors.book-wrong-type',
  [offersErrors.BOOK_OCCURRENCES_LIMIT_EXCEEDED_ERROR]: 'experience.booking.errors.book-occurrences-limit-exceeded',
  [offersErrors.BOOK_OCCURRENCES_OVERLAP_ERROR]: 'experience.booking.book-occurrences-overlap',
  [offersErrors.BOOK_OFFER_IS_NOT_PUBLIC]: 'experience.booking.errors.book-offer-is-not-public',
  [offersErrors.BOOK_OWN_OFFER_NOT_ALLOWED_ERROR]: 'experience.booking.errors.book-own-offer-not-allowed',
}

export function useBookExperience(setIsSubmitting) {
  const t = useGetTranslate()
  const history = useHistory()
  const currentUserEntourage = useUser(getUserEntourageType)

  return React.useCallback(({ experienceId, startTime, endTime, location, includedFriends, invitedFriends }) => {
    bookExperienceTimeSlot({
      experienceId,
      startTime,
      endTime,
      location,
      includedFriends,
      invitedFriends
    })
      .then(({ order }) => {
        history.push(routesList.experiencePurchase(order.id))
      })
      .catch(({ name, context }) => {
        if (name === offersErrors.BOOK_WRONG_ENTOURAGE_TYPE) {
          notify(t(getEntourageError({ expectedEntourageType: context.offerEntourageType, currentUserEntourage })))
        } else {
          notify(t(bookingErrorMessages[name]), 'error')
        }
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }, [t, currentUserEntourage])
}

export function useBookOccurrence(setIsSubmitting) {
  const history = useHistory()
  const t = useGetTranslate()
  const currentUserEntourage = useUser(getUserEntourageType)

  return React.useCallback(({ offerExperienceOccurrenceId, location, includedFriends, invitedFriends }) => {
    setIsSubmitting(true)

    bookExperienceByOccurrenceId({
      offerExperienceOccurrenceId,
      includedFriends,
      invitedFriends,
      location,
    })
      .then(({ order }) => {
        history.push(routesList.experiencePurchase(order.id))
      })
      .catch(({ name, context }) => {
        if (name === offersErrors.BOOK_WRONG_ENTOURAGE_TYPE) {
          notify(t(getEntourageError({ expectedEntourageType: context.offerEntourageType, currentUserEntourage })), 'error')
        } else {
          notify(t(bookingErrorMessages[name]), 'error')
        }
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }, [t, currentUserEntourage])
}
