import { NotificationStatus } from 'shared'
import { eq, cond, propEq, compose, T, curry } from 'lodash/fp'

import { getPlaqueNickname, getSaveFullName } from 'utils/user'
import { getBookingPageRoute, getOrderPageRoute, getPublicPageRoute, routesList } from 'router/routesMap'
import { getAmPmTime, getMonthDayYearDate } from 'utils/date'
import { getFirstLocationLabel } from 'utils/location'

export const statusTrans = {
  [NotificationStatus.ACCEPTED]: 'notifications.status.accepted',
  [NotificationStatus.REJECTED]: 'notifications.status.rejected',
  [NotificationStatus.CANCELLED]: 'notifications.status.cancelled',
}

export const isPending = eq(NotificationStatus.PENDING)

const getSenderLink = cond([
  [propEq('type', 'TALENT_USER'), compose(routesList.talentPublicPage, getPlaqueNickname)],
  [T, compose(routesList.userPublicPage, getPlaqueNickname)],
])

export const phsConfig = {
  senderFullName: {
    ph: '{{senderFullName}}',
    value: p => getSaveFullName(p.sender),
  },
  senderLink: {
    ph: '{{senderLink}}',
    value: getSenderLink,
  },
  talentFullName: {
    ph: '{{talentFullName}}',
    value: p => getSaveFullName(p.receiverTalent),
  },
  talentLink: {
    ph: '{{talentLink}}',
    value: p => routesList.talentPublicPage(getPlaqueNickname(p.receiverTalent)),
  },
  aficionadoFullName: {
    ph: '{{aficionadoFullName}}',
    value: p => getSaveFullName(p.sender),
  },
  aficionadoLink: {
    ph: '{{aficionadoLink}}',
    value: p => routesList.userPublicPage(getPlaqueNickname(p.sender)),
  },
  offeringName: {
    ph: '{{offeringName}}',
    value: p => p.occurrence?.title,
  },
  offeringLink: {
    ph: '{{offeringLink}}',
    value: p => getPublicPageRoute(p.occurrence.type, getPlaqueNickname(p.receiverTalent), p.occurrence?.sequentialId),
  },
  date: {
    ph: '{{date}}',
    value: p => getMonthDayYearDate(p.occurrence?.startTime),
  },
  time: {
    ph: '{{time}}',
    value: p => getAmPmTime(p.occurrence?.startTime),
  },
  orderName: {
    ph: '{{orderName}}',
    value: p => p.occurrence?.title,
  },
  orderLink: {
    ph: '{{orderLink}}',
    value: p => getOrderPageRoute(p.occurrence.offerType, p.occurrence.id),
  },
  location: {
    ph: '{{location}}',
    value: p => getFirstLocationLabel(p.occurrence?.locations)
  },
  days: {
    ph: '{{days}}',
    value: p => p.options.daysRemains
  },
  dueDate: {
    ph: '{{dueDate}}',
    value: p => getMonthDayYearDate(p.options?.dueDate),
  },
  senderTalentFullName: {
    ph: '{{talentFullName}}',
    value: p => getSaveFullName(p.sender),
  },
  senderTalentLink: {
    ph: '{{talentLink}}',
    value: p => routesList.talentPublicPage(getPlaqueNickname(p.sender)),
  },
  receiverTalentFullName: {
    ph: '{{receiverTalentFullName}}',
    value: p => getSaveFullName(p.receiverTalent)
  },
  receiverTalentLink: {
    ph: '{{receiverTalentLink}}',
    value: p => routesList.talentPublicPage(getPlaqueNickname(p.receiverTalent))
  },
  orderNumber: {
    ph: '{{orderNumber}}',
    value: p => p.order.number
  },
  orderUserFullName: {
    ph: '{{orderUserFullName}}',
    value: p => getSaveFullName(p.order.user)
  },
  orderUserLink: {
    ph: '{{orderUserLink}}',
    value: p => getSenderLink(p.order.user)
  },
  bookingLink: {
    ph: '{{orderLink}}',
    value: p => getBookingPageRoute(p.occurrence.type, p.order.id)
  }
}

export const buildPhs = curry((phs, props) => {
  return phs.map(({ ph, value }) => ({ ph, value: value(props) }))
})

