/* eslint-disable no-unused-vars*/
//TODO check hideArrow prop
import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import cond from 'lodash/cond';
import constant from 'lodash/constant';
import matches from 'lodash/matches';
import stubTrue from 'lodash/stubTrue';
import get from 'lodash/get';

import { ErrorMessage } from '../ErrorMessage';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

export const Input = React.memo(function Input({
  isRequired,
  name,
  type = 'text',
  placeholder = '',
  validation = {},
  className,
  shouldShowError = true,
  hideArrow = true,
  isDisabled,
  requiredMessage,
  ...props
}) {
  const { register, errors } = useFormContext();
  const t = useGetTranslate();
  const requiredText = 'shared.words.required';
  const error = get(errors, name);

  const errorMessageRequired = requiredMessage ? requiredMessage : requiredText;

  const handleWriteOnlyNumber = e => {
    if (type === 'number') {
      const regex = /[0-9]/;
      return !regex.test(String.fromCharCode(e.which)) && e.preventDefault()
    }
  }

  return (
    <StyledWrapper isDisabled={isDisabled} className={`input-container ${className}`}>
      <StyledInput
        className={`input ${className}`}
        name={name}
        type={type}
        placeholder={placeholder}
        ref={register({
          required: isRequired && errorMessageRequired,
          ...validation,
        })}
        error={Boolean(error)}
        autocomplete="off"
        isDisabled={isDisabled}
        onKeyPress={handleWriteOnlyNumber}
        {...(isDisabled ? { tabIndex: -1 } : {})}
        {...props}
      />
      {shouldShowError && error && (
        <ErrorMessage className="error" message={error.message} />
      )}
    </StyledWrapper>
  );
});

const getBorderColor = cond([
  [matches({ error: true }), constant('red')],
  [stubTrue, constant('#BABBCD')], // todo: refactor with theme
]);

export const StyledInput = styled.input`
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 4px;
  border: 2px solid ${getBorderColor};
  padding: 17px;

  font-size: 18px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif; // todo: refactor with theme
  line-height: normal;

  &::placeholder {
    color: #9495a7;
  }

  ${({ isDisabled }) =>
  isDisabled &&
    css`
      background-color: #e9eaf0;
      color: #9495a7;
      cursor: not-allowed;
      pointer-events: none;
      &:hover {
        border-color: #babbcd;
      }
      &:focus {
        pointer-events: none;
      }
    `}

  &:focus, &:hover {
    border-color: #000;
  }

  ${({ isDisabled }) =>
      isDisabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      background-color: #e9eaf0;

      &:hover {
        border-color: #babbcd;
      }
    `}

  ${({ hideArrow }) =>
      hideArrow &&
    css`
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type='number'] {
        -moz-appearance: textfield;
      }
    `}
`;

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;

  ${({ isDisabled }) =>
  isDisabled &&
    css`
      cursor: not-allowed;
    `}

  .error {
    position: absolute;
    top: 110%;
    left: 0;
  }
`;

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  validation: PropTypes.object,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  shouldShowError: PropTypes.bool,
  requiredMessage: PropTypes.string,
  hideArrow: PropTypes.bool,
};

StyledInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  hideArrow: PropTypes.bool,
};
