import React from 'react'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Controller, useFormContext } from 'react-hook-form'
import get from 'lodash/get'
import compose from 'lodash/fp/compose'

import { ErrorMessage } from '../ErrorMessage'


const Common = ({
  name,
  placeholder,
  isRequired,
  className,
  rules,
  shouldShowErrorMessage = true,
  defaultValue,
  ...props
}) => {
  const { control, errors } = useFormContext()
  const error = get(errors, name)
  const classes = cx('datepicker-wrapper', className, { error })
  const [open, setOpen] = React.useState(false)

  const closeModal = () => setOpen(false)
  const toggleModal = () => setOpen(p => !p)

  return (
    <div className={classes}>
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={name}
        rules={{ required: isRequired && 'shared.words.required', ...rules }}
        render={({ onChange, value }) => (
          <DatePicker
            autoComplete="off"
            selected={value}
            onChange={compose(closeModal, onChange)}
            placeholderText={placeholder}
            customInput={<CustomInput open={open} />}
            open={open}
            onClickOutside={closeModal}
            onInputClick={toggleModal}
            onKeyDown={(e) => e.preventDefault()}
            {...props}
          />
        )}
      />
      {shouldShowErrorMessage && error && (
        <ErrorMessage message={error?.message} />
      )}
    </div>
  )
}

Common.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  shouldShowErrorMessage: PropTypes.bool,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  minTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  maxTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
};

export default Common

function CustomInput(props) {
  const classes = cx('form-group', {
    'form-group--rotate': props.open
  })

  return (
    <div className={classes}>
      <input type="text" className='form-control' {...props} />
    </div>
  )
}
