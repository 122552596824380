import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form'

import { Types } from 'shared/dist/src/types/file';

// components
import TipButton from '_legacy/components/Form/LabelTips/TipButton';
import UploaderFiles from '_legacy/common/UploaderFiles';
import ProfileManager from '../User/components/ProfileManager';
import Nickname from '../shared/components/Nickname';
import PhoneConfirm from '../shared/components/PhoneConfirm';
import DateOfBirth from '../shared/components/DateOfBirth';
import Email from '../User/components/Email';
import BackupEmail from '../shared/components/BackupEmail';
import MultiLocationSelect from '../shared/components/MultiLocationSelect';
import HeadshotImage from '../shared/components/HeadshotImage';
import SocialMediaLinks from './components/SocialMediaLinks';
import TimeZoneSelect from '_legacy/components/RegisterForms/shared/components/TimeZoneSelect';
import TaxLegalDocumentationInfoModal from '_legacy/components/Modal/TaxLegalDocumentationInfoModal';

// utils
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import Input from '../shared/components/Input';
import UploadProfilePics from './components/UploadProfilePics';
import Taxes from '../shared/components/Taxes'
import { isLicensesIsRequiredForEdit, shouldShowTaxMessageForEdit } from '../User/utils/validators'


const AgentForm = React.memo(function AgentForm({ viewerTypes }) {
  const t = useGetTranslate();
  const methods = useFormContext()

  const { isAgentOrManagerWithoutTalent, isAficionado } = viewerTypes;

  const formState = methods.watch()
  React.useEffect(() => {
    if (methods.formState.isSubmitted) {
      methods.trigger('user.licenses')
    }
  }, [formState, methods])

  return (
    <>
      {!isAficionado && (
        <ProfileManager
          disabled
          name='user.type'
          customValidation={{ required: false }}
        />
      )}
      <HeadshotImage name='user.profileImage'/>
      {(isAgentOrManagerWithoutTalent || isAficionado) && <UploadProfilePics />}
      <Input
        isDisabled
        isRequired
        name='user.firstName'
        placeholder={t('register-user.form.first-name.placeholder')}
        label={t('register-user.form.first-name.label')}
      />
      <Input
        isDisabled
        isRequired
        name='user.lastName'
        placeholder={t('register-user.form.last-name.placeholder')}
        label={t('register-user.form.last-name.label')}
      />
      <Nickname name='user.nickname' isDisabled/>
      <Email name='user.email' shouldValidate={false} disabled/>
      <BackupEmail name='user.backupEmail'/>
      <MultiLocationSelect baseName='user.address'/>
      <TimeZoneSelect name='user.timezone' />
      <DateOfBirth name='user.dateOfBirth'/>
      <PhoneConfirm baseName='user' isEditable/>
      {(isAgentOrManagerWithoutTalent || isAficionado) && (
        <SocialMediaLinks name='user.socialMedia'/>
      )}
      <Input
        isRequired
        name='user.socialSecurityOrTIN'
        placeholder={t('register-user.form.social-security-or-tin.placeholder')}
        label={t('register-user.form.social-security-or-tin.label')}
      />
      <Taxes name='user.taxpayerStatus' showTaxMessage={shouldShowTaxMessageForEdit(formState)} />
      <UploaderFiles
        name='user.licenses'
        isRequired={isLicensesIsRequiredForEdit(formState)}
        label={t('register-user.form.w9.label')}
        titleHint={t('register-user.form.w9.placeholder.desc')}
        typesFiles={[Types.FILE, Types.PHOTO, Types.VIDEO]}
        tip={<TipButton modalComponent={TaxLegalDocumentationInfoModal} />}
      />
    </>
  );
});

AgentForm.propTypes = {
  viewerTypes: PropTypes.object
};

export default AgentForm;

