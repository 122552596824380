import React from 'react';
import PropTypes from 'prop-types';

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';

import Slide from '_legacy/components/TalentPublicView/Slider/Slide'
import Icon from 'assets/icons/Icon';

const Empty = ({ firstName, isMobile = false }) => {
  const BasicEmpty = isMobile ? Slide.MobileSlide.Empty : Slide.DesktopSlide.Empty;
  const t = useGetTranslate()
  return (
    <BasicEmpty
      isMirroredInside={true}
      size="big"
      icon={<Icon.VideoMessages />}
      content={
        <Trans
          tKey='talent-public-view.sliders.video-messages.empty.content.label'
          phs={[{ ph: '{{firstName}}', value: firstName }]}
        />
      }
      hint={
        <span>
          {t('talent-public-view.sliders.lessons.empty.hint.label.text')}
        </span>
      }
    />
  );
};

Empty.propTypes = {
  firstName: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

export default Empty;
