// Modules
import React from 'react'
import prop from 'lodash/fp/prop'
import cx from 'classnames'

// Assets
import Icon from 'assets/icons/Icon'

// Routing
import { routesList } from 'router/routesMap'

// Store (hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useNotificationsMenuFilters } from 'store/hooks/globalState/useNotificationsState'
import { useHasUnreadChatMessages } from 'store/hooks/globalState/useChats'

// Utils
import { history, hashHistory } from 'utils/history'

// Components
import NotificationCounter from '_legacy/components/Header/NotificationMenu/NotificationCounter'

const isOnHashPath = hash => history.location.hash.includes(hash)
const isOnBrowserPath = path => history.location.pathname.includes(path) && history.location.hash === ''

export const closeOverlay = () => { window.location.hash = '' }


export const useGetNavigationPanelItems = (mobileMenuActive, setMobileMenuActive) => {
  const isLoggedIn = useUser(Boolean)

  // Counters
  const unreadNotificationsCount = useNotificationsMenuFilters(prop('count'))
  const unreadMessagesCount = useHasUnreadChatMessages()
  const UnreadNotificationsComponent = () => <NotificationCounter unreadNotificationsCount={unreadNotificationsCount} />
  const UnreadMessagesComponent = () => <span className={cx('unread-messages', { 'active': unreadMessagesCount })} />

  // Click handlers
  const onClickSignIn = () => hashHistory.push(routesList.hashLogin())
  const onClickSearch = () => hashHistory.push(routesList.hashSearchBy())
  const onClickMessages = () => history.push(routesList.secureChat())
  const onClickNotifications = () => history.push(routesList.notifications())
  const onClickMenu = () => {
    closeOverlay()
    setMobileMenuActive(!mobileMenuActive)
  }

  // Configs
  const loggedOutItems = [
    {
      label: 'footer.mobile.search',
      Icon: Icon.Search,
      onClick: onClickSearch,
      isActive: isOnHashPath(routesList.hashSearchBy()),
    },
    {
      label: 'footer.mobile.notifications',
      Icon: Icon.Notifications,
      onClick: onClickSignIn,
      isActive: isOnBrowserPath(routesList.notifications()),
    },
    {
      label: 'footer.mobile.messages',
      Icon: Icon.Chats,
      onClick: onClickSignIn,
      isActive: isOnBrowserPath(routesList.secureChat()),
    },
    {
      label: 'footer.mobile.sign_in',
      Icon: Icon.SignIn,
      onClick: onClickSignIn,
      isActive: isOnHashPath(routesList.hashLogin()),
    },
  ]

  const loggedInItems = [
    {
      label: 'footer.mobile.search',
      Icon: Icon.Search,
      onClick: onClickSearch,
      isActive: isOnHashPath(routesList.hashLogin()) && !mobileMenuActive,
    },
    {
      label: 'footer.mobile.notifications',
      Icon: Icon.Notifications,
      onClick: onClickNotifications,
      isActive: isOnBrowserPath(routesList.notifications()) && !mobileMenuActive,
      Counter: UnreadNotificationsComponent,
    },
    {
      label: 'footer.mobile.messages',
      Icon: Icon.Chats,
      onClick: onClickMessages,
      isActive: isOnBrowserPath(routesList.secureChat()) && !mobileMenuActive,
      Counter: UnreadMessagesComponent,
    },
    {
      label: 'footer.mobile.menu',
      Icon: Icon.BurgerMenu,
      onClick: onClickMenu,
      isActive: mobileMenuActive,
    },
  ]

  return isLoggedIn
    ? loggedInItems
    : loggedOutItems
}
