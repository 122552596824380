import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { array } from 'prop-types';
import T from 'lodash/fp/T'
import lt from 'lodash/fp/lt'
import __ from 'lodash/fp/__'
import cond from 'lodash/cond'
import constant from 'lodash/constant'

import Icon from 'assets/icons/Icon';
import BiographyItem from './BiographyItem/';

import { useWindowDimensions } from 'store/hooks';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

SwiperCore.use([Navigation, Pagination]);

const Biography = ({ biographies = [] }) => {
  const t = useGetTranslate();
  const prevRef = React.useRef(null);
  const nextRef = React.useRef(null);
  const { width } = useWindowDimensions();

  const getItems = React.useMemo(() => cond([
    [lt(__, 625), constant(1)],
    [lt(__, 991), constant(2)],
    [lt(__, 1400), constant(3)],
    [lt(__, 1500), constant(4)],
    [T, constant(4)],
  ])(width), [width])

  const disableSlider = getItems < biographies.length

  if (biographies.length === 0)
    return <div style={{ height: width < 1200 ? 'auto' : 50 }} />;

  return (
    <div className="biography-slider">
      <div className="biography-slider__header">
        <h3 className="title">{t('talent-public-view.biography')}</h3>
      </div>

      <div className="biography-slider__wrapper" data-disabled={String(!disableSlider)}>

        <div className="biography-slider__navigation">
          <div className="biography-slider__navigation_btn biography-slider__navigation_btn--prev" ref={prevRef} >
            <Icon.ArrowSliderPrev stroke='#626473' />
          </div>
          <div className="biography-slider__navigation_btn biography-slider__navigation_btn--next" ref={nextRef} >
            <Icon.ArrowSliderNext stroke='#626473' />
          </div>
        </div>

        <Swiper
          slidesPerView={getItems}
          spaceBetween={16}
          pagination={{ clickable: true }}
          allowTouchMove={disableSlider}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
          }}
        >
          {biographies
            .sort((a, b) => a.index - b.index)
            .map(({ id, header, subheader, description }) => (
              <SwiperSlide key={id} className="biography-slider__item">
                <BiographyItem
                  key={id}
                  header={header}
                  subheader={subheader}
                  description={description}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

Biography.propTypes = {
  biographies: array.isRequired,
};

export default Biography;
