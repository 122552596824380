import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import castArray from 'lodash/castArray';
import head from 'lodash/head';
import compact from 'lodash/compact'

import SelectedListItem from './SelectedListItem';
import SelectedListWrapper from '_legacy/components/Library/components/SelectedList/SelectedListWrapper';

const SelectedList = React.memo(({ name, primaryName }) => {
  const { watch, register, setValue } = useFormContext();
  const selectedFileIds = compact(castArray(watch(name, [])));
  const primaryFileId = watch(primaryName);

  React.useEffect(() => {
    register(primaryName);
  }, [register, primaryName]);

  React.useEffect(() => {
    // set first primary type
    const idsArray = castArray(selectedFileIds)
    if (idsArray.length && !primaryFileId) {
      setValue(primaryName, head(idsArray), { shouldDirty: true });
    }
  }, [primaryFileId, selectedFileIds]);

  if (selectedFileIds.length === 0) return null

  return (
    <SelectedListWrapper>
      {selectedFileIds.map((fileId, index) => (
        <SelectedListItem
          key={index}
          id={fileId}
          name={name}
          primaryName={primaryName}
        />
      ))}
    </SelectedListWrapper>
  );
});

SelectedList.propTypes = {
  name: PropTypes.string,
  primaryName: PropTypes.string,
};

export default SelectedList;
