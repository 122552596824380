import { api } from 'shared'

import * as r from '_legacy/constants/routes';
import * as offerings from '_legacy/constants/offerings'
const {
  IN_PERSON_EXPERIENCE,
  READY_MADE_LESSON,
  VIRTUAL_EVENT
} = api.talents.talent.offers.query.TypeFilter

const offeringsConfig = [
  {
    key: offerings.EXPERIENCES,
    icon: 'InPersonExperiences',
    title: 'offerings.new.inPersonExperiences.title',
    description: 'offerings.new.inPersonExperiences.description',
    createLink: `/${r.OFFERINGS}/${r.EXPERIENCES}/${r.NEW}`,
    editLink: `/${r.LISTING}/${r.LIST}`,
    isMulti: true,
    field: IN_PERSON_EXPERIENCE,
    available: true
  },
  {
    key: offerings.VIDEO_MESSAGE,
    icon: 'VideoMessages',
    title: 'offerings.new.videoMessages.title',
    description: 'offerings.new.videoMessages.description',
    createLink: `/${r.OFFERINGS}/${r.VIDEO_MESSAGE}/${r.NEW}`,
    editLink: `/${r.OFFERINGS}/${r.VIDEO_MESSAGE}/${r.EDIT}`,
    available: true
  },
  {
    key: offerings.VIDEO_CHAT,
    icon: 'VideoChats',
    title: 'offerings.new.liveVideoChats.title',
    description: 'offerings.new.liveVideoChats.description',
    createLink: `/${r.OFFERINGS}/${r.VIDEO_CHAT}/${r.NEW}`,
    editLink: `/${r.OFFERINGS}/${r.VIDEO_CHAT}/${r.EDIT}`,
    available: true
  },
  {
    key: offerings.LESSONS,
    icon: 'Lessons',
    title: 'offerings.new.lessons.title',
    description: 'offerings.new.lessons.description',
    createLink: `/${r.OFFERINGS}/${r.LESSONS}`,
    editLink: `/${r.OFFERINGS}/${r.LESSONS}`,
    available: true
  },
  {
    key: offerings.READY_MADE_EVENT,
    icon: 'PreMadeVideoLessons',
    title: 'offerings.new.premadeVideoLessons.title',
    description: 'offerings.new.premadeVideoLessons.description',
    createLink: `/${r.OFFERINGS}/${r.READY_MADE_EVENT_LESSON}/${r.NEW}`,
    editLink: `/${r.LISTING}/${r.LIST}`,
    isMulti: true,
    field: READY_MADE_LESSON,
    available: true
  },
  {
    key: offerings.LAST_MASTERCLASS,
    icon: 'LiveMasterclasses',
    title: 'offerings.new.liveMasterclasses.title',
    description: 'offerings.new.liveMasterclasses.description',
    createLink: `/${r.OFFERINGS}/${r.MASTER_CLASS}/${r.NEW}`,
    editLink: `/${r.LISTING}/${r.LIST}`,
    isMulti: true,
    field: VIRTUAL_EVENT,
    available: false
  },
  {
    key: offerings.LAST_LIVE_VIRTUAL_EVENT,
    icon: 'LiveMasterclasses',
    title: 'offerings.new.virtualEvents.title',
    description: 'offerings.new.virtualEvents.description',
    createLink: `/${r.OFFERINGS}/${r.LIVE_VIRTUAL_EVENTS}/${r.NEW}`,
    editLink: `/${r.LISTING}/${r.LIST}`,
    isMulti: true,
    field: VIRTUAL_EVENT,
    available: false
  },
  {
    key: offerings.COMMERCIAL_VIDEO_MESSAGE,
    icon: 'ForCommercialUse',
    title: 'offerings.new.videoMessagesForCommercialUse.title',
    description: 'offerings.new.videoMessagesForCommercialUse.description',
    createLink: `/${r.OFFERINGS}/${r.COMMERCIAL_VIDEO_MESSAGE}/${r.NEW}`,
    editLink: `/${r.OFFERINGS}/${r.COMMERCIAL_VIDEO_MESSAGE}/${r.EDIT}`,
    available: false
  },
]

export default offeringsConfig;
