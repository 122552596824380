// Modules
import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { ChatMessageType } from 'shared'

// Utils
import { linkify } from 'utils/linkify'
import { getAmPmTime } from 'utils/date'
import { getTalentId, getUserId } from 'utils/user'
import { useUser } from 'store/hooks/globalState/useUser'

// Legacy components
import Avatar from '_legacy/components/Avatar'

// Components
import ActiveChatSystemMessage from './ActiveChatSystemMessage'


const ActiveChatMessage = ({ message, chatInfo, messageZonedDate }) => {
  const talentId = useUser(getTalentId)
  const userId = useUser(getUserId)

  const currentUserId = talentId || userId
  const currentUserMessage = currentUserId === message.author

  const messageAuthorDetails = chatInfo?.participants?.find(p => p.id === message.author)

  const additionalStylesClass = cx({
    'from-system': message.attributes?.messageType === ChatMessageType.SYSTEM,
    'from-me': currentUserMessage,
    'from-others': !currentUserMessage,
  })

  const systemMessage = message.attributes?.messageType === ChatMessageType.SYSTEM

  return (
    <div className={`chat-message__wrapper ${additionalStylesClass}`}>
      {systemMessage ? (
        <ActiveChatSystemMessage
          chatInfo={chatInfo}
          currentUserId={currentUserId}
          currentUserMessage={currentUserMessage}
          message={message}
          messageAuthorDetails={messageAuthorDetails}
        />
      ) : (
        <>
          {!currentUserMessage && (
            <Avatar file={messageAuthorDetails?.profileImage?.file} className="chat-message__sender-icon" />
          )}

          <div className={`chat-message__body ${additionalStylesClass}`}>
            <div
              className={`chat-message__message ${additionalStylesClass}`}
              dangerouslySetInnerHTML={{ __html: linkify(message.body) }}
            />
            <div className="chat-message__message-time">{getAmPmTime(messageZonedDate)}</div>
          </div>
        </>
      )}
    </div>
  )
}

ActiveChatMessage.propTypes = {
  message: PropTypes.object.isRequired,
  chatInfo: PropTypes.object.isRequired,
}

export default ActiveChatMessage
