import React from 'react';

import {
  getVideoMessage as getVideoMessageApi,
  createVideoMessage as createVideoMessageApi,
  updateVideoMessage as updateVideoMessageApi,
} from 'api/appApi/offers/videoMessage';

import { notify } from 'utils/notify';
import { getFilesFromMedia } from 'utils/services/validators/library';

import { useTalentId } from 'store/hooks/globalState/useUser';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useAddFilesToLibrary } from 'store/hooks/globalState/useFileLibrary';
import { useSetVideoMessage } from 'store/hooks/globalState/videoMessage';
import { useNavigate } from 'store/hooks/globalState/useSwitchNavigation';
import { useStateModal } from 'store/hooks/globalState/useModal';

import { OFFERINGS } from '_legacy/constants/routes';
import { PROMOTIONAL_MEDIA_ASK_MODAL } from '_legacy/components/Modal/modals-types';

import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

export function useFetchVideoMessage() {
  const addFilesToFilesLibrary = useAddFilesToLibrary();
  const talentId = useTalentId();

  const setVideoMessageData = useSetVideoMessage((prev, data) => ({ ...prev, data }));
  const setVideoMessageError = useSetVideoMessage((prev, error) => ({ ...prev, error }));
  const setVideoMessageLoading = useSetVideoMessage((prev, loading) => ({ ...prev, loading }));
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    setVideoMessageLoading(true);
    setVideoMessageData(null);

    getVideoMessageApi({ talentId })
      .then(({ videoMessage }) => {
        const media = getFilesFromMedia(videoMessage);
        addFilesToFilesLibrary(media);
        setVideoMessageData(videoMessage);
      })
      .catch(err => {
        handleResponseError(err)
        setVideoMessageError(err);
        setVideoMessageData({});
      })
      .finally(() => {
        setVideoMessageLoading(false);
      });
  }, [addFilesToFilesLibrary, setVideoMessageLoading, setVideoMessageError, setVideoMessageLoading, talentId]);
}

export function useCreateVideoMessage() {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const navigate = useNavigate();
  const openAskModal = useStateModal(PROMOTIONAL_MEDIA_ASK_MODAL);

  const setVideoMessageError = useSetVideoMessage((prev, error) => ({ ...prev, error }));
  const setVideoMessageLoading = useSetVideoMessage((prev, loading) => ({ ...prev, loading }));

  return React.useCallback(data => {
    setVideoMessageLoading(true);

    // todo: remove conditional when backend fixed it
    createVideoMessageApi({ talentId, data })
      .then(({ isPromotionalMediaNeeded }) => {
        if (isPromotionalMediaNeeded) {
          notify(t('video-message.form.message.create.successfully'));
          openAskModal();
        } else {
          navigate(`/${OFFERINGS}`, t('video-message.form.message.create.successfully'));
        }
      })
      .catch(error => {
        notify(error.message, 'error');
        setVideoMessageError(error);
      })
      .finally(() => {
        setVideoMessageLoading(false);
      });
  }, [talentId, t, setVideoMessageLoading, setVideoMessageError]);
}

export function useUpdateVideoMessage() {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const navigate = useNavigate();

  const setVideoMessageLoading = useSetVideoMessage((prev, loading) => ({ ...prev, loading }));
  const setVideoMessageError = useSetVideoMessage((prev, error) => ({ ...prev, error }));

  return React.useCallback(data => {
    setVideoMessageLoading(true);

    updateVideoMessageApi({ talentId, data })
      .then(() => {
        navigate(`/${OFFERINGS}`, t('video-message.form.message.update.successfully'));
      })
      .catch(error => {
        notify(error.message, 'error');
        setVideoMessageError(error);
      })
      .finally(() => {
        setVideoMessageLoading(false);
      });
  }, [talentId, t, setVideoMessageLoading, setVideoMessageError, navigate]);
}
