import { includes, __ } from 'lodash/fp'
import * as r from '_legacy/constants/routes'

export const forbiddenNicknameWords = Object.values(r)

export const isNicknameContainsForbiddenWords = includes(__, forbiddenNicknameWords)

export const checkNicknameMinLength = (nickname = '') => nickname.length < 3

export const checkNicknameMaxLength = (nickname = '') => nickname.length > 20
