import React from 'react'
import styled from 'styled-components'
import { types } from 'shared'

const { PHOTO } = types.file.Types

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'

import LibraryWithCrop from '_legacy/components/Library/LibraryWithCrop'
import FullBodyBackdrop from '_legacy/components/Library/configs/FullBody/FullBodyBackdrop'
import PictureWithText from '_legacy/components/Library/configs/common/PictureWithText'

import ExampleCustomImg from 'assets/images/example-custom-box.png'
import PropTypes from 'prop-types'

const FullBodyImage = ({ name = 'fullBodyImage', component }) => {
  const t = useGetTranslate()

  const dropZoneProps = {
    accept: PHOTO,
  }
  const cropModalProps = {
    cropShape: 'rect',
    aspect: 3 / 4,
    header: 'Crop Full-Body Image',
  }
  const components = {
    customPicture: PictureWithText,
    customBackdrop: FullBodyBackdrop,
  }

  return (
    <React.Fragment>
      <div className="upload-container">
        <div className="upload-item">
          <div className="upload-item__full-body-wrapper">
            <LibraryWithCrop
              name={name}
              rules={{ required: 'shared.words.required' }}
              components={components}
              dropZoneProps={dropZoneProps}
              cropModalProps={cropModalProps}
            />
          </div>
          <div className="upload-label upload-label--light">
            {t('register.upload.hint.desc.full-body')}
          </div>
        </div>
        <div className="upload-item upload-item--full-body">
          <div className="upload-item__full-body-wrapper">
            <img className="upload-item__image" src={ExampleCustomImg} alt="" />
            <div className='upload-label upload-label--light upload-item__image-hint'>
              Min. <strong>1,400 px</strong> / Ideal <strong>2,400 px</strong>
            </div>
          </div>
          <div className="upload-label upload-label--light">
            Min. <strong>900 px</strong> / Ideal <strong>1,600 px</strong>
          </div>
        </div>
      </div>
      {component}
      <FullBodyDescription>
        <Trans tKey={'register.upload.fullBodyDesc'} />
      </FullBodyDescription>
    </React.Fragment>
  )
}


const FullBodyDescription = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: rgb(98, 100, 115);
  line-height: 1.625;
  margin-top: 16px;
  margin-bottom: 0;
`

FullBodyImage.propTypes = {
  name: PropTypes.string,
  component: PropTypes.node,
}

export default FullBodyImage
