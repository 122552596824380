import { isEmpty, complement, always, cond, head, compose, join, props, compact, T, prop, anyPass } from 'lodash/fp'
import { isChosenLocationOffer, isNALocationOffer, isOnlineOffer } from './offer'


/**
 * Function to get location address
 * @func getLocationAddress
 * @param {Object} location - The default location.
 * @param {Object} location.address - The address.
 * @return {string} The location address
 *
 * @example
 *     getLocationAddress(location)
 */
export const getLocationAddress = prop('address')

export const getFirstLocationAddress = compose(getLocationAddress, head)

export const getLocationTimeZone = prop('timezone.id')

export const getFirstLocationTimeZone = compose(
  getLocationTimeZone,
  head
)

/**
 * Function to get location formatted address
 * @func getLocationAddress
 * @param {Object} location - The default location.
 * @param {Object} location.formattedAddress - The formatted address.
 * @return {string} The location address
 *
 * @example
 *     getLocationAddress(location)
 */
export const getLocationFormattedAddress = prop('formattedAddress')

export const getFirstLocationFormattedAddress = compose(getLocationFormattedAddress, head)

export const getCityOrCountry = compose(
  head,
  compact,
  props(['city', 'country'])
)

export const getFirstCityOrCountry = compose(
  getCityOrCountry,
  head,
)

/**
 * Function to get location label with place
 * @func getLocationFormattedAddressWithName
 * @param {Object} location - The default location.
 * @param {Object} location.formattedAddress - The full formatted address.
 * @param {Object} location.name - The default location name.
 * @return {string} The location label
 *
 * @example
 *     getLocationLabelWithName(location)
 */
export const getLocationFormattedAddressWithName = compose(
  join(', '),
  compact,
  props(['name', 'formattedAddress']),
)

/**
 * Function to get location label
 * @func getLocationLabel
 * @param {Object} location - The default location.
 * @param {Object} location.address - The default location.
 * @param {Object} location.city - The default location.
 * @param {Object} location.stateOrSimilar - The default location.
 * @param {Object} location.country - The default location.
 * @return {string} The location label
 *
 * @example
 *     getLocationLabel(location)
 */
export const getLocationLabel = compose(
  join(', '),
  compact,
  props(['address', 'city', 'stateOrSimilar', 'country']),
)

export const getFirstLocationLabel = compose(getLocationLabel, head)


/**
 * Function to get location name
 * @func getLocationName
 * @param {Array} location - The default location.
 * @param {Object} location.name
 * @return {string} The location label
 *
 * @example
 *     getLocationName(location)
 */
export const getLocation = prop('name')

export const getFirstLocationName = compose(getLocation, head)


/**
 * Function to get location place
 * @func getFullName
 * @param {Array} location - The default location.
 * @param {string} user.place - The location place.
 * @param {string} user.stateOrSimilar - The location place or similar
 * @return {string} The location
 *
 * @example
 *     getLocationPlace(location)
 *     getLocationPlace(locations[0])
 */
export const getLocationPlace = compose(
  head,
  compact,
  props(['name', 'place', 'stateOrSimilar']),
)

export const getFirstLocationPlace = compose(getLocationPlace, head)


/**
 * Function to check if location has props to get place label
 * @func hasPropsForLocationPlace
 * @param {Object} location.name
 * @param {Object} location.place
 * @param {Object} location.stateOrSimilar
 * @return {Boolean}
 *
 * @example
 *     hasPropsForLocationPlace(location)
 */
export const hasPropsForLocationPlace = compose(
  complement(isEmpty),
  compact,
  props(['name', 'place', 'stateOrSimilar']),
)


/**
 * Function to get location title if exist or formatted address
 * @func getLocationTitle
 * @param {Object} location
 * @return {String} location title
 *
 * @example
 *     getLocationTitle(location)
 */
export const getLocationTitle = cond([
  [hasPropsForLocationPlace, getLocationPlace],
  [T, getLocationFormattedAddress],
])

export const getFirstLocationTitle = compose(getLocationTitle, head)

/**
 * Function to get location subtitle or empty string if title not exist
 * @func getLocationSubtitle
 * @param {Object} location
 * @return {String} location title
 *
 * @exampleects/frontend/src/utils/offer.js

 *     getLocationSubtitle(location)
 */
export const getLocationSubtitle = cond([
  [hasPropsForLocationPlace, getLocationFormattedAddress],
  [T, always('')],
])

export const getFirstLocationSubtitle = compose(getLocationSubtitle, head)


/**
 * Function to get location name base on offer type
 * @func getLocationSubtitle
 * @param {Object} offer
 * @param {Object} offer.type
 * @param {Object} offer.locations
 * @return {String} location title
 *
 * @example
 *     t(getLocationName(location))
 */
export const getLocationName = cond([
  [isOnlineOffer, always('table.filters.locations.online')],
  [isNALocationOffer, always('shared.words.na')],
  [isChosenLocationOffer, compose(getFirstLocationTitle, prop('locations'))],
])


/**
 * Function to get location details (if need) base on offer type
 * @func getLocationDetails
 * @param {Object} offer
 * @param {Object} offer.type
 * @param {Object} offer.locations
 * @return {String} location details
 *
 * @example
 *     t(getLocationDetails(location))
 */
export const getLocationDetails = cond([
  [anyPass([isOnlineOffer, isNALocationOffer]), always('')],
  [isChosenLocationOffer, compose(getFirstLocationSubtitle, prop('locations'))],
])
