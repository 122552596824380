import React from 'react'

import RowInput from '_legacy/common/Input/RowInput'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { validateEventNameMinLength } from 'utils/services/validators/eventNameValidator'

const Name = () => {
  const t = useGetTranslate()
  return (
    <RowInput
      isRequired
      name="name"
      label={t('create-experience.form.name.label')}
      placeholder={t('create-experience.form.name.placeholder')}
      validation={{
        validate: {
          minLength: eventName => validateEventNameMinLength(eventName),
          maxLength: eventName => (eventName || '').length < 50 || 'create-experience.form.name.errors.max-length',
        },
        required: 'create-experience.form.name.errors.required',
      }}
    />
  )
}

export default Name
