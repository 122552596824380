import React from 'react'
import { types } from 'shared'
import { FormProvider, useForm } from 'react-hook-form'
import { isEmpty } from 'lodash/fp'

import PublicInfoForm from '_legacy/components/PublicView/PublicInfoForm'
import PublicFormItem from '_legacy/components/PublicView/PublicFlexibleFormItemContext'
import PublicFixedFormItem from '_legacy/components/PublicView/PublicFixedFormItem'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getLocationTimeZone } from 'utils/location'

import { getTimezoneAbbr, parseDate } from 'utils/services/timezone'
import { getShortMonthDate, getTimeRange } from 'utils/date'
import { useBookOccurrence } from 'requests/public-views/experience/experience-booking'
import { buildLocations, buildSlots, fillSelectedLocation } from '../utils'

const { FLEXIBLE } = types.experiences.TypesOfLocation


const FixedTypesOfDate = React.memo(({ experience }) => {
  const { locations, maxNumberOfParticipants, typesOfLocation, pricePerParticipant } = experience
  const isFlexibleTypeOfLocation = React.useMemo(() => typesOfLocation === FLEXIBLE, [])

  const t = useGetTranslate()
  const methods = useForm({ shouldUnregister: false })
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const bookOccurrence = useBookOccurrence(setIsSubmitting)

  // ****** form state ******
  const watchedLocation = methods.watch('location', locations?.[0]?.id)
  const watchedDate = methods.watch('date')

  const { locationTimeZoneAbbr, occurrenceOptions, locationOptions, participantTitle, selectedRange } = React.useMemo(() => {
    const slots = buildSlots(experience)

    // ****** occurrence options ******
    const occurrenceOptions = slots.map(slot => ({ value: slot.id, label: getShortMonthDate(slot.startTime) }))
    const selectedOccurrence = slots.find(slot => slot.id === watchedDate)

    // ****** location options ******
    const filledLocations = fillSelectedLocation(selectedOccurrence?.locations, experience.locations)
    const locationOptions = buildLocations(filledLocations)
    const selectedLocation = locations.find(location => location.id === watchedLocation)
    const locationTimeZone = getLocationTimeZone(selectedLocation)
    const locationTimeZoneAbbr = getTimezoneAbbr(locationTimeZone)

    // ****** time label ******
    const selectedStartTime = parseDate(selectedOccurrence?.startTime, locationTimeZone)
    const selectedEndTime = parseDate(selectedOccurrence?.endTime, locationTimeZone)
    const selectedRange = getTimeRange([selectedStartTime, selectedEndTime])

    // ****** participant label ******
    const participantTitle = `${selectedOccurrence?.participantsCount} of ${maxNumberOfParticipants}`

    return {
      locationOptions,
      occurrenceOptions,
      locationTimeZoneAbbr,
      participantTitle,
      selectedRange
    }
  }, [experience, watchedLocation, watchedDate])

  // ****** submit ******
  const onSubmit = React.useCallback(() => {
    bookOccurrence({
      offerExperienceOccurrenceId: watchedDate,
      location: watchedLocation,
      invitedFriends: [],
      includedFriends: []
    })
  }, [watchedLocation, watchedDate])

  return (
    <FormProvider {...methods}>
      <PublicInfoForm
        isSubmitting={isSubmitting}
        onSubmit={methods.handleSubmit(onSubmit)}
        pricePerParticipant={pricePerParticipant}
      >
        <h3>{t('experience.info.heading')}</h3>
        <p className="public-view__timezone-reminder">
          {t('experience.info.timezone-reminder-location')}:<div>{locationTimeZoneAbbr}</div>
        </p>

        {!isEmpty(occurrenceOptions) &&
          <div className="public-view__info-list">
            {/*location select*/}
            {!isEmpty(locationOptions) && isFlexibleTypeOfLocation && (
              <PublicFormItem name="location" title={t('experience.info.location')} items={locationOptions} />
            )}

            {/*date select*/}
            <PublicFormItem name="date" title={t('experience.info.date')} items={occurrenceOptions} />

            {/*time select*/}
            <PublicFixedFormItem title={t('experience.info.time')} fixedItem={selectedRange} />

            {/*participant label*/}
            <PublicFixedFormItem
              title={t('experience.info.current-participant')}
              fixedItem={participantTitle}
            />
          </div>
        }

      </PublicInfoForm>
    </FormProvider>
  )
})

export default FixedTypesOfDate
