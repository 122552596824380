import * as r from '_legacy/constants/routes'
import get from 'lodash/get'
import { TalentOffersType } from 'shared'

export const routesList = Object.freeze({
  // Browser rouset
  homePage: () => '/',
  userPublicPage: (userPlaqueNickname) => `/${r.USER}/${userPlaqueNickname}`,
  talentPublicPage: (talentPlaqueNickname) => `/${talentPlaqueNickname}`,
  searchTalent: () => `/${r.SEARCH}/${r.TALENT}`,
  searchOfferings: () => `/${r.SEARCH}/${r.OFFERINGS}`,
  experiencePublicPage: (talentPlaqueNickname, offerSequentialId) => `/${r.EXPERIENCES}/${talentPlaqueNickname}/${offerSequentialId}`,
  liveVirtualEventPublicPage: (talentPlaqueNickname, offerSequentialId) => `/${r.LIVE_VIRTUAL_EVENTS}/${talentPlaqueNickname}/${offerSequentialId}`,
  lessonsPublicPage: (talentPlaqueNickname) => `/${r.LESSONS}/${talentPlaqueNickname}`,
  videoMessagePublicPage: (talentPlaqueNickname, offerSequentialId) => `/${r.VIDEO_MESSAGE}/${talentPlaqueNickname}/${offerSequentialId}`,
  videoChatPublicPage: (talentPlaqueNickname, offerSequentialId) => `/${r.VIDEO_CHAT}/${talentPlaqueNickname}/${offerSequentialId}`,
  readyMadeEventLessonPublicPage: (talentPlaqueNickname, offerSequentialId) => `/${r.READY_MADE_EVENT_LESSON}/${talentPlaqueNickname}/${offerSequentialId}`,
  notFound: () => `/${r.NOT_FOUND}`,

  videoChatRoom: (occurrenceId) => `/${r.ROOM}/${r.VIDEO_CHAT}/${occurrenceId}`,
  videoChatRoomEnd: (occurrenceId) => `/${r.ROOM}/${r.VIDEO_CHAT}/${occurrenceId}/end`,
  liveVirtualLessonRoom: (occurrenceId) => `/${r.ROOM}/${r.LIVE_VIRTUAL_LESSON}/${occurrenceId}`,
  liveVirtualLessonRoomEnd: (occurrenceId) => `/${r.ROOM}/${r.LIVE_VIRTUAL_LESSON}/${occurrenceId}/end`,

  notifications: () => `/${r.NOTIFICATIONS}`,
  secureChat: () => `/${r.SECURE_CHAT}`,
  secureChatId: (chatId) => `/${r.SECURE_CHAT}/${chatId}`,

  editExperience: (offerId) => `/${r.OFFERINGS}/${r.EXPERIENCES}/${offerId}/${r.EDIT}`,

  bookingList: () => `/${r.BOOKINGS}/${r.LIST}`,

  videoChatBookingDetails: (orderId) => `/${r.BOOKINGS}/${r.VIDEO_CHAT}/${r.DETAILS}/${orderId}`,
  videoMessageBookingDetails: (orderId) => `/${r.BOOKINGS}/${r.VIDEO_MESSAGE}/${r.DETAILS}/${orderId}`,
  inPersonExperienceBookingDetails: (orderId) => `/${r.BOOKINGS}/${r.EXPERIENCES}/${r.DETAILS}/${orderId}`,
  liveVirtualLessonBookingDetails: (orderId) => `/${r.BOOKINGS}/${r.LIVE_VIRTUAL_LESSON}/${r.DETAILS}/${orderId}`,
  readyMadeLessonBookingDetails: (orderId) => `/${r.BOOKINGS}/${r.READY_MADE_EVENT_LESSON}/${r.DETAILS}/${orderId}`,
  liveInPersonLessonBookingDetails: (orderId) => `/${r.BOOKINGS}/${r.LIVE_IN_PERSON_LESSON}/${r.DETAILS}/${orderId}`,
  videoVoiceOverLessonBookingDetails: (orderId) => `/${r.BOOKINGS}/${r.VIDEO_VOICE_OVER_LESSON}/${r.DETAILS}/${orderId}`,
  liveVirtualEventBookingDetails: (orderId) => `/${r.BOOKINGS}/${r.LIVE_VIRTUAL_EVENTS}/${r.DETAILS}/${orderId}`,

  videoMessageRequestDraft: (talentPlaqueNickname, offerSequentialId) => `/${r.OFFERINGS}/${r.VIDEO_MESSAGE}/${talentPlaqueNickname}/${offerSequentialId}/${r.REQUEST}/${r.DRAFT}`,

  videoChatOrderDetails: (occurrenceId) => `/${r.ORDERS}/${r.VIDEO_CHAT}/${r.DETAILS}/${occurrenceId}`,
  videoMessageOrderDetails: (occurrenceId) => `/${r.ORDERS}/${r.VIDEO_MESSAGE}/${r.DETAILS}/${occurrenceId}`,
  inPersonExperienceOrderDetails: (occurrenceId) => `/${r.ORDERS}/${r.EXPERIENCES}/${r.DETAILS}/${occurrenceId}`,
  liveVirtualEventsOrderDetails: (occurrenceId) => `/${r.ORDERS}/${r.LIVE_VIRTUAL_EVENTS}/${r.DETAILS}/${occurrenceId}`,
  liviuVirtualLessonOrderDetails: (occurrenceId) => `/${r.ORDERS}/${r.LIVE_VIRTUAL_LESSON}/${r.DETAILS}/${occurrenceId}`,
  videoVoiceOverLessonOrderDetails: (occurrenceId) => `/${r.ORDERS}/${r.VIDEO_VOICE_OVER_LESSON}/${r.DETAILS}/${occurrenceId}`,
  liveInPersonLessonOrderDetails: (occurrenceId) => `/${r.ORDERS}/${r.LIVE_IN_PERSON_LESSON}/${r.DETAILS}/${occurrenceId}`,
  readyMadeEventLessonOrderDetails: (occurrenceId) => `/${r.ORDERS}/${r.READY_MADE_EVENT_LESSON}/${r.DETAILS}/${occurrenceId}`,

  currencyAndLanguage: () => `/${r.LANGUAGE_AND_CURRENCY}`,

  // Hash routes
  hashLogin: () => `/${r.HASH_LOGIN}`,
  hashSearchBy: () => `/${r.HASH_SEARCH_BY}`,
  hashSeachByTalent: () => `/${r.HASH_SEARCH_BY}/${r.HASH_TALENT}`,
  hashSeachByOffering: () => `/${r.HASH_SEARCH_BY}/${r.HASH_OFFERING}`,
  hashSeachByLocation: () => `/${r.HASH_SEARCH_BY}/${r.HASH_LOCATION}`,

  experiencePurchase: (orderId) => `/${r.OFFERINGS}/${r.EXPERIENCES}/${orderId}/${r.PURCHASE}`,

  entouragePublic: () => `/${r.ENTOURAGE}`,
  entourageUpgrade: () => `/${r.ENTOURAGE}/${r.UPGRADE}`,
  entouragePurchase: () => `/${r.ENTOURAGE}/${r.PURCHASE}`,
  entourageVerify: () => `/${r.ENTOURAGE}/${r.VERIFY}`
})

export const getPublicPageRoute = (offerType, talentPlaqueNickname, offerSequentialId) => {
  const offeringsTypes = {
    [TalentOffersType.VIDEO_CHAT]: routesList.videoChatPublicPage,
    [TalentOffersType.VIDEO_MESSAGE]: routesList.videoMessagePublicPage,
    [TalentOffersType.VIDEO_VOICE_OVER_LESSON]: routesList.lessonsPublicPage,
    [TalentOffersType.VIRTUAL_LESSON]:  routesList.lessonsPublicPage,
    [TalentOffersType.LIVE_IN_PERSON_LESSON]:  routesList.lessonsPublicPage,
    [TalentOffersType.READY_MADE_LESSON]: routesList.readyMadeEventLessonPublicPage,
    [TalentOffersType.VIRTUAL_EVENT]: routesList.liveVirtualEventPublicPage,
    [TalentOffersType.IN_PERSON_EXPERIENCE]: routesList.experiencePublicPage,
  }

  const routeFn = get(offeringsTypes, offerType, routesList.notFound)

  return routeFn(talentPlaqueNickname, offerSequentialId)
}

export const getOrderPageRoute = (offerType, occurrenceId) => {
  const offeringsTypes = {
    [TalentOffersType.VIDEO_CHAT]: routesList.videoChatOrderDetails,
    [TalentOffersType.VIDEO_MESSAGE]: routesList.videoMessageOrderDetails,
    [TalentOffersType.VIDEO_VOICE_OVER_LESSON]: routesList.videoVoiceOverLessonOrderDetails,
    [TalentOffersType.VIRTUAL_LESSON]:  routesList.liviuVirtualLessonOrderDetails,
    [TalentOffersType.LIVE_IN_PERSON_LESSON]:  routesList.liveInPersonLessonOrderDetails,
    [TalentOffersType.READY_MADE_LESSON]: routesList.readyMadeEventLessonOrderDetails,
    [TalentOffersType.VIRTUAL_EVENT]: routesList.liveVirtualEventsOrderDetails,
    [TalentOffersType.IN_PERSON_EXPERIENCE]: routesList.inPersonExperienceOrderDetails,
  }

  const routeFn = get(offeringsTypes, offerType, routesList.notFound)

  return routeFn(occurrenceId)
}

export const getBookingPageRoute = (offerType, orderId) => {
  const offeringsTypes = {
    [TalentOffersType.VIDEO_CHAT]: routesList.videoChatBookingDetails,
    [TalentOffersType.VIDEO_MESSAGE]: routesList.videoMessageBookingDetails,
    [TalentOffersType.VIDEO_VOICE_OVER_LESSON]: routesList.videoVoiceOverLessonBookingDetails,
    [TalentOffersType.VIRTUAL_LESSON]:  routesList.liveVirtualLessonBookingDetails,
    [TalentOffersType.LIVE_IN_PERSON_LESSON]:  routesList.liveInPersonLessonBookingDetails,
    [TalentOffersType.IN_PERSON_EXPERIENCE]: routesList.inPersonExperienceBookingDetails,
    [TalentOffersType.VIRTUAL_EVENT]: routesList.liveVirtualEventBookingDetails,
  }

  const routeFn = get(offeringsTypes, offerType, routesList.notFound)

  return routeFn(orderId)
}
