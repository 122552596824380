import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';

import Machine from '_legacy/components/TalentPublicView/Slider/SlideMachine/Mobile/Machine';
import BasicActions from '_legacy/components/TalentPublicView/Slider/BasicActions';
import OffersNavigation from '_legacy/components/TalentPublicView/Slider/Mobile/OffersNavigation';
import BookNowButton from '_legacy/components/TalentPublicView/Slider/components/BookNowButton';
import {
  transformLessonsSlides,
  transformLiveExperiencesSlides,
  transformLiveVideoChatsSlides,
  transformVideoMessagesSlides,
  transformLiveVirtualEventsSlides,
  transformReadyMadeEventsSlides,
} from '_legacy/components/TalentPublicView/Slider/Mobile/utils';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const Mobile = React.memo(function Mobile({
  liveExperiencesSlides = [],
  videoMessages,
  videoChats,
  lessonsSlides = [],
  liveVirtualEvents = [],
  readyMadeEvents = [],
  firstName,
  plaqueNickname,
}) {
  const t = useGetTranslate();

  const videoMessagesSlides = get(videoMessages, 'media', []);
  const videoChatsSlides= get(videoChats, 'media', []);
  const videoMessagesOfferId = get(videoMessages, 'offerSequentialId', null);
  const videoChatsOfferId= get(videoChats, 'offerSequentialId', null);

  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(() => {
    if (liveExperiencesSlides.length !== 0) return 0;
    if (videoMessagesSlides.length !== 0) return 1;
    if (videoChatsSlides.length !== 0) return 2;
    if (lessonsSlides.length !== 0) return 3;
    if (readyMadeEvents.length !== 0) return 4;
    if (liveVirtualEvents.length !== 0) return 5;
    return 0;
  });

  const slides = [
    ...transformLiveExperiencesSlides({ liveExperiencesSlides, firstName, plaqueNickname }),
    ...transformVideoMessagesSlides({ videoMessagesSlides, videoMessagesOfferId, firstName, plaqueNickname }),
    ...transformLiveVideoChatsSlides({ videoChatsSlides, videoChatsOfferId, firstName, plaqueNickname }),
    ...transformLessonsSlides({ lessonsSlides, firstName, plaqueNickname }),
    ...transformReadyMadeEventsSlides({ readyMadeEvents, firstName, plaqueNickname }),
    ...transformLiveVirtualEventsSlides({ liveVirtualEvents, firstName, plaqueNickname }),
  ];

  const getCurrentActive = () => {
    const tempCurrentSlideIndex = currentSlideIndex < 0 ? currentSlideIndex % slides.length + slides.length : currentSlideIndex;

    let limit = liveExperiencesSlides.length || 1;
    if(tempCurrentSlideIndex % slides.length < limit) return 0;

    limit += videoMessagesSlides.length || 1;
    if(tempCurrentSlideIndex % slides.length < limit) return 1;

    limit += videoChatsSlides.length || 1;
    if(tempCurrentSlideIndex % slides.length < limit) return 2;

    limit += lessonsSlides.length || 1;
    if(tempCurrentSlideIndex % slides.length < limit) return 3;

    limit += readyMadeEvents.length || 1;
    if(tempCurrentSlideIndex % slides.length < limit) return 4;

    limit += liveVirtualEvents.length || 1;
    if(tempCurrentSlideIndex % slides.length < limit) return 5;
  };

  const setCurrentActive = (activeIndex) => {
    let limit = 0;
    if(activeIndex === 0) return setCurrentSlideIndex(0);

    limit += liveExperiencesSlides.length || 1;
    if(activeIndex === 1) return setCurrentSlideIndex(limit);

    limit += videoMessagesSlides.length || 1;
    if(activeIndex === 2) return setCurrentSlideIndex(limit);

    limit += videoChatsSlides.length || 1;
    if(activeIndex === 3) return setCurrentSlideIndex(limit);

    limit += lessonsSlides.length || 1;
    if(activeIndex === 4) return setCurrentSlideIndex(limit);

    limit += readyMadeEvents.length || 1;
    if(activeIndex === 5) return setCurrentSlideIndex(limit);

    limit += liveVirtualEvents.length || 1;
    if(activeIndex === 6) return setCurrentSlideIndex(limit);

    throw new Error(`Unknown active index, expected: 0 or 1 or 2 or 3, instead get ${activeIndex}`);
  };

  const slide = slides[((currentSlideIndex % slides.length) + slides.length) % slides.length];
  const path = slide?.props?.path
  const isPromo = slide?.props?.isPromo
  const hideControl = slide?.props?.hideControl

  return (
    <StyleWrapper className="mobile-wr">
      <StyledHeader className="mb-header">{t('talent-public-view.mobile-slider.title')}</StyledHeader>
      <OffersNavigation currentActive={getCurrentActive()} onActivate={setCurrentActive} />
      <Machine
        slides={slides}
        currentSlideIndex={currentSlideIndex}
        setCurrentSlideIndex={setCurrentSlideIndex}
      />
      <BasicActions
        slidesCount={slides.length}
        onNext={() => setCurrentSlideIndex(current => current + 1)}
        onPrev={() => setCurrentSlideIndex(current => current - 1)}
      >
        {!hideControl && <BookNowButton isLink={path} path={path} disabled={isPromo} />}
      </BasicActions>
    </StyleWrapper>
  );
})

const StyleWrapper = styled.div`
  width: 344px;
`
const StyledHeader = styled.h4`
  margin-top: 0;
  margin-bottom: 26px;
  font-family: 'Playfair Display', sans-serif;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.25;
  text-align: center;
  font-feature-settings: 'case' on, 'liga' off;
  color: ${({ theme }) => theme.colors.blue};
`

Mobile.propTypes = {
  liveExperiencesSlides: PropTypes.array,
  videoMessages: PropTypes.object,
  videoChats: PropTypes.object,
  lessonsSlides: PropTypes.array,
  liveVirtualEvents: PropTypes.array,
  readyMadeEvents: PropTypes.array,
  firstName: PropTypes.string,
  plaqueNickname: PropTypes.string
};

export default Mobile;
