import React from 'react';
import { get } from 'lodash'
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import Phone from 'react-phone-input-2';
import cx from 'classnames';

const PhoneInput = ({
  name,
  defaultValue = '',
  placeholder,
  onChange: _onChange,
  disabled,
  validation,
  isRequired,
}) => {
  const { control, errors } = useFormContext();
  const inputClass = cx('phone-input', { error: get(errors, name) });

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <Phone
          value={value || ''}
          prefix={'+'}
          inputClass={inputClass}
          placeholder={placeholder || ''}
          country={'us'}
          disabled={disabled}
          dropdownClass='phone-input__dropdown'
          onChange={selected => {
            const value = '+' + selected;
            if (_onChange) _onChange(value);
            return onChange(value);
          }}
        />
      )}
      rules={{
        required: isRequired && 'Required',
        ...validation
      }}
    />
  );
};


PhoneInput.propTypes = {
  invalidMessage: PropTypes.string,
  required: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  validation: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
};

export default PhoneInput;
