import React from 'react';
import { FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import MinBookingTimeBeforeExperience from './components/MinBookingTimeBeforeExperience';
import MaxNumberOfParticipants from './components/MaxNumberOfParticipants';
import Location from './components/Location';
import Name from './components/Name';
import PricePerParticipant from './components/PricePerParticipant';
import AficionadoNoShowFee from './components/AficionadoNoShowFee';
import HideAddressFromPubView from './components/HideAddressFromPubView';
import TypesOfDate from './components/TypesOfDate';
// import SearchInviteTalentMember from './components/SearchInviteTalentMember';
import TakingPicture from './components/TakingPicture';
import Signature from './components/Signature';
import OtherInformation from './components/OtherInformation';
import WhatToBring from './components/WhatToBring';
import Age from './components/Age';
import Charities from '../Charities';
import RequiredLabel from '../RegisterForms/shared/components/RequiredLabel';
import Image from './components/Image';
import Video from './components/Video';
import Taboos from './components/Taboos';
import Submit from './components/Submit';
import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler';
import OfferingsLinkToManagerPromoCodes from '_legacy/components/OfferingsLinkToManagerPromoCodes';
import TimeZoneHandler from './components/TimeZoneHandler';
import MissedData from '_legacy/components/RegisterForms/shared/components/MissedData'
import Entourage from './components/Entourage'

const CreateExperienceForm = ({ methods, onSubmit, onDraft, isLoading, isSubmitDisabled, recurringIdToExclude }) => {
  return (
    <FormProvider {...methods}>
      <ChangeRouteHandler
        isDraft={true}
        onDraft={onDraft}
        shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
      />
      <TimeZoneHandler />
      <form className='default create-exp' onSubmit={methods.handleSubmit(onSubmit)}>
        <RequiredLabel />
        <Name />
        <PricePerParticipant />
        <AficionadoNoShowFee />
        <MaxNumberOfParticipants />
        <Location />
        <HideAddressFromPubView />
        <TypesOfDate recurringIdToExclude={recurringIdToExclude} />
        <Image />
        <Video />
        {/* here and above commented due to ui changes (will be uncommented and further developed in phase 2) */}
        {/* <SearchInviteTalentMember /> */}
        <Taboos />
        <TakingPicture />
        <Signature />
        <OtherInformation />
        <WhatToBring />
        <Age />
        <Charities />
        <MinBookingTimeBeforeExperience />
        <OfferingsLinkToManagerPromoCodes />
        <Entourage />
        <Submit
          methods={methods}
          onDraft={onDraft}
          isLoading={isLoading}
          isSubmitDisabled={isSubmitDisabled}
        />
        <MissedData />
      </form>
    </FormProvider>
  );
};

CreateExperienceForm.propTypes = {
  onSubmit: PropTypes.func,
  onDraft: PropTypes.func,
  methods: PropTypes.object,
  isLoading: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
}

export default CreateExperienceForm;
