import React from 'react';
import { string } from 'prop-types';

import { ReactComponent as CommercialEngagementIcon } from 'assets/commercial-engagement-icon.svg';

import Button from '_legacy/components/Button';

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { COMMERCIAL_ENGAGEMENTS } from '_legacy/constants/routes';

const CommercialEngagementBook = ({ nickname, className = '' }) => {
  const t = useGetTranslate();
  return (
    <div className={`commercial-engangement-book ${className}`}>
      <div className='icon'><CommercialEngagementIcon /></div>
      <p className='text'><Trans tKey='talent-public-view.commercial-engagement.label' /></p>
      <Button
        text={t('shared.words.book-now')}
        fontSize={0.875}
        paddingVertical={10}
        paddingHorizontal={21}
        path={`/${nickname}/${COMMERCIAL_ENGAGEMENTS}`}
        isLink
        className='button'
      />
    </div>
  );
};

CommercialEngagementBook.propTypes = {
  nickname: string.isRequired,
  className: string
};

export default CommercialEngagementBook;
