// Modules
import React from 'react'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'

// Assets
import Icon from 'assets/icons/Icon'

// Routing
import * as r from '_legacy/constants/routes'

// Store (Hooks)
import { useModal } from 'store/hooks/useModal'
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useUpdateOccurrenceTime } from './state/requests/experience-occurrence-request'
import { useCheckIsOfferEditable, useFetchExperienceOrderDetails } from './state/requests/experience-order-details'
import { useExperienceOrderDetails, useIsExperienceEditable, useSetExperienceOrderDetails } from './state/store'
import { useRejectOrderRequest } from './state/requests/experience-order-requests'

// Utils
import { notify } from 'utils/notify'
import { getFirstLocationLabel, getFirstLocationPlace } from 'utils/location'
import { getDateTimeProps, getMonthDayOnly } from 'utils/date'
import { getTimeZoneFromOccurrenceLocation } from 'utils/forms/adapters/timezone'
import { prepareDate } from 'utils/services/timezone'
import { getCharitiesTitle, getParticipantTitle, getPriceTitle, getTimeLeftString } from 'components/OfferingDetails/utils'
import { defaultRoute, getDetailsConfig } from './utils/order-details-config'
import { getTalentPlaqueNickname } from 'utils/user'
import { getInPersonExperienceOrderConfig } from './utils'

// Components
import ModalWithControls from 'portals/ModalWithControls'
import BookingCalendar from 'components/Calendars/BookingCalendar'
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingsDetailsHeaderHold from 'components/OfferingDetails/components/OfferingsDetailsHeaderHold'
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'
import OfferingDetailsWaiting from 'components/OfferingDetails/components/OfferingDetailsWaiting'
import OfferingDetailsGenericStatus from 'components/OfferingDetails/components/OfferingDetailsGenericStatus'
import OfferingDetailsEscrowFunds from 'components/OfferingDetails/components/OfferingDetailsEscrowFunds'
import RejectModal from 'components/OfferingDetails/components/RejectModal'
import CancelOccurrenceButton from '../../components/CancelOccurrenceButton'

// Legacy components
import PageLoader from '_legacy/common/Template/PageLoader'
import Button from '_legacy/components/Button'
import CustomMedia from '_legacy/common/CustomMedia'
import ContentTabs from '_legacy/containers/ContentTabs'
import { getCancelModalProps } from '../utils'

const ExperienceOrderDetails = () => {
  const t = useGetTranslate()
  const { orderId } = useParams()
  const history = useHistory()

  const currentUser = useUser()
  const userNickname = getTalentPlaqueNickname(currentUser)

  const rejectOrders = useRejectOrderRequest()
  const fetchIsEditable = useCheckIsOfferEditable()
  const updateOccurrenceTime = useUpdateOccurrenceTime()
  const fetchOccurrence = useFetchExperienceOrderDetails()

  const occurrence = useExperienceOrderDetails()
  const editableProps = useIsExperienceEditable()
  const clearOccurrence = useSetExperienceOrderDetails(() => null)

  const menuItems = getDetailsConfig(orderId)
  const [orderSlot, setOrderSlot] = React.useState(null)
  const [isCalendarOpen, openCalendar, closeCalendar] = useModal(false)
  const [isRejectModalOpen, openRejectModal, closeRejectModal] = useModal(false)
  const [isHandleEditModalOpen, openHandleEditModal, closeHandleEditModal] = useModal(false)

  React.useEffect(() => {
    fetchOccurrence()
  }, [])

  React.useEffect(() => {
    if (occurrence?.offerId) {
      fetchIsEditable(occurrence.offerId)
    }
  }, [occurrence])

  React.useEffect(() => () => {
    clearOccurrence()
  }, [])

  if (!occurrence) return <PageLoader />

  const { id, name, photo, offerId, pricePerParticipant, offerSequentialId, locations, participantsCount, duration, totalCharityFee } = occurrence

  const timeZone = getTimeZoneFromOccurrenceLocation(occurrence)
  const { formattedDate, formattedTimeRange, startTime } = getDateTimeProps({ ...occurrence, timeZone })

  const timeLeft = getTimeLeftString(occurrence)

  const onHandleReject = () => {
    closeHandleEditModal()
    openRejectModal()
  }

  const onRejectAllAndEdit = ({ message, rejectReason, offerIds }) => {
    rejectOrders({ ordersIds: offerIds, message, rejectReason })
    closeRejectModal()
    history.push(`/${r.OFFERINGS}/${r.EXPERIENCES}/${offerId}/${r.EDIT}`)
  }

  const onCalendarConfirm = formData => {
    updateOccurrenceTime({
      offerExperienceOccurrenceId: id,
      startTime: prepareDate(formData?.start, timeZone),
      endTime: prepareDate(formData?.end, timeZone),
    })

    closeCalendar()
  }

  const onEdit = () => {
    if (!editableProps) return

    if (!editableProps.hasPendingBookingRequests && !editableProps.hasBookedParticipants) {
      history.push(`/${r.OFFERINGS}/${r.EXPERIENCES}/${offerId}/${r.EDIT}`)
    } else if (editableProps.hasBookedParticipants) {
      notify(t('You cannot edit an experience with booked participants'), 'error')
    } else {
      openHandleEditModal()
    }
  }

  const c = getInPersonExperienceOrderConfig(occurrence)

  const cancelOrderButton = (
    <CancelOccurrenceButton
      offerId={offerId}
      onSuccess={fetchOccurrence}
      immediatelyCancelOrder={!editableProps?.hasPendingBookingRequests && !editableProps?.hasBookedParticipants}
      cancelBtnText={t('order.answer.buttons.cancel-occurrence')}
      {...getCancelModalProps(editableProps)}
    />
  )

  return (
    <div className="in-person-experience-order od">
      <OfferingDetailsTitle typeHeader={t('shared.offers.experience')} title={name} />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <div className="od__row od__column-m">
            <div className="od__media-aside">
              {photo ? <CustomMedia file={{ ...photo.file, originalName: '' }} /> : <img alt={''} />}
            </div>
            <div className="od__column od--padding-info">
              <OfferingsDetailsHeaderItem
                icon={<Icon.Calendar />}
                item={formattedDate}
                subitem={formattedTimeRange}
              />
              <OfferingsDetailsHeaderItem
                icon={<Icon.Pin />}
                item={getFirstLocationPlace(locations)}
                subitem={getFirstLocationLabel(locations)}
              />
              <OfferingsDetailsHeaderItem
                icon={<Icon.Participants />}
                item={getParticipantTitle({ t, ...occurrence })}
              />
              <OfferingsDetailsHeaderItem
                icon={<Icon.Pricetag />}
                item={getPriceTitle({ t, ...occurrence })}
                subitem={t('order-details.price-per-person')}
              />
              <Link className="od__row od__public-link" to={`/${r.EXPERIENCES}/${userNickname}/${offerSequentialId}`}>
                <div className="od--main-text od--accented od__public-link--text">
                  {t('order-details.info.link-text')}
                </div>
                <div className="od__public-link--arrow" />
              </Link>
            </div>
          </div>
        </div>
        <div className="od--shadow-box od--centered">
          {/*{isWaitingForApprove(occurrence) && timeLeft && requestsCount > 0 && (*/}
          {c.isShowWaitingForApprove && (
            <OfferingDetailsWaiting
              waitingTitle={t('order-details.experience.header')}
              waitingText={timeLeft && t('order-details.actions.times-left')}
              timeLeft={timeLeft}
            >
              <Button
                stretch
                classBtn="button mb-16"
                handleOnClick={onEdit}
                text={t('order-details.actions.edit')}
                typeButton="submit"
                fontSize="0.875"
                paddingVertical="16"
              />
              {cancelOrderButton}
            </OfferingDetailsWaiting>
          )}

          {/* TODO check conditions after BE changes */}
          {c.isShowHold && (
            <OfferingsDetailsHeaderHold
              title={t('order-details.experience.header')}
              typeText={t('order-details.experience.header')}
              onHoldTitle={t('order-details.info.hold')}
              date={getMonthDayOnly(startTime)}
              duration={formattedTimeRange}
              locationMain={getFirstLocationPlace(locations)}
              locationFull={getFirstLocationLabel(locations)}
            >
              <div className="od__column">
                <Button
                  classBtn="button mb-16"
                  handleOnClick={onEdit}
                  text={t('order-details.actions.edit')}
                  typeButton="submit"
                  fontSize="0.875"
                  paddingVertical="16"
                />
                {cancelOrderButton}
              </div>
            </OfferingsDetailsHeaderHold>
          )}

          {c.isShowRejected && (
            <OfferingDetailsGenericStatus
              title={t('order-details.experience.header')}
              type={t('order.header.rejected')}
            />
          )}

          {c.isShowCanceled && (
            <OfferingDetailsGenericStatus
              title={t('order-details.experience.header')}
              type={t('order.header.cancelled')}
            />
          )}

          {/* TODO clarify if we need cancel button if event in progress */}
          {c.isShowInProgress && (
            <OfferingDetailsGenericStatus
              title={t('order-details.experience.header')}
              type={t('order.header.in-progress')}
            />
          )}

          {c.isShowCompleted && (
            <OfferingDetailsEscrowFunds
              title={t('order-details.experience.header')}
              subtitle={t('order.description.funds')}
              price={pricePerParticipant * participantsCount}
            />
          )}

          {c.isShowClosed && (
            <>
              <OfferingDetailsEscrowFunds
                title={t('order-details.experience.header')}
                subtitle={t('order.description.received')}
                price={pricePerParticipant * participantsCount}
              />
              {!!totalCharityFee && participantsCount && (
                <OfferingDetailsEscrowFunds
                  className="od--bordered"
                  subtitle={getCharitiesTitle(t, pricePerParticipant * participantsCount, totalCharityFee)}
                  price={totalCharityFee}
                  description={t('order.description.charities.text')}
                />
              )}
            </>
          )}

          {c.isShowCalendar && (
            <div className="od__column od--with-padding">
              <p className="od--uppercase-title mb-24">{t('order-details.experience.header')}</p>
              <Button
                handleOnClick={openCalendar}
                iconComponent={<Icon.ViewCalendar fill="#fff" />}
                text={t('order-details.virtual-chats.propose-date')}
                typeButton="submit"
                fontSize="0.875"
                paddingVertical="16"
                paddingHorizontal="10"
                className="mb-16"
              />
              {cancelOrderButton}
            </div>
          )}
        </div>
      </div>

      <ContentTabs menuItems={menuItems} defaultRoute={defaultRoute} />

      <RejectModal
        isOpen={isRejectModalOpen}
        onClose={closeRejectModal}
        orderId={orderId}
        rejectHandler={onRejectAllAndEdit}
      />

      <ModalWithControls
        isOpen={isHandleEditModalOpen}
        onClose={closeHandleEditModal}
        header={t('order-details.experience.header')}
        message={t('recurring-list.edit.text')}
        buttonsConfig={[
          { text: t('recurring-list.edit.cancelText'), handleOnClick: closeHandleEditModal, primary: true },
          { text: t('recurring-list.edit.discardText'), handleOnClick: onHandleReject },
        ]}
      />

      <BookingCalendar
        isOpen={isCalendarOpen}
        onClose={closeCalendar}
        onConfirm={onCalendarConfirm}
        timeZone={timeZone}
        eventDuration={duration}
        talentId={currentUser.talent.id}
        eventTitle={name}
        minBookingTimeBeforeExperience={0}
        orderSlot={orderSlot}
        setOrderSlot={setOrderSlot}
      />
    </div>
  )
}

export default ExperienceOrderDetails
