import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withAsyncPaginate, AsyncPaginate } from 'react-select-async-paginate';
import Creatable from 'react-select/creatable';
import cx from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import cond from 'lodash/cond';
import matches from 'lodash/matches';
import get from 'lodash/get';

// components
import { ErrorMessage } from '_legacy/common/ErrorMessage';
import IndicatorSeparator from './IndicatorSeparator';
import Input from './Input';
import NoOptionsMessage from './NoOptionsMessage';
import LoaderMessage from './LoaderMessage';
import DropdownIndicator from './DropdownIndicator';
import styled from 'styled-components';

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const AsyncSelect = ({
  placeholder,
  blackTheme,
  name,
  rules,
  loadOptions,
  defaultValue,
  loadOptionsOnMenuOpen = true,
  options = [],
  onChange: propOnChange,
  isClearable = false,
  isShowArrow = true,
  openMenuOnClick = true,
  isMulti = false,
  customComponents,
  isCreatable = false,
  ...props
}) => {
  const { watch, setValue, clearErrors, control, errors } = useFormContext();
  const value = watch(name);
  const error = get(errors, name);

  const classes = cx('react-select-container', {
    'react-select-container--danger': error?.message,
    'react-select-container--black-theme': blackTheme,
  });

  const selectRef = useRef();

  const [inputValue, setInputValue] = useState(null);

  const onInputChange = (inputValue, { action }) => {
    cond([
      [matches('input-blur'), () => setInputValue(value ? value.label : '')],
      [matches('input-change'), () => setInputValue(inputValue)],
      [matches('clear'), () => setInputValue(null)],
    ])(action);

    if (propOnChange) propOnChange();
  };

  const onChange = (option) => {
    setValue(name, option, { shouldDirty: true });
    setInputValue(option ? option.label : '');
    clearErrors(name);
  };

  const onFocus = () => value && selectRef.current?.select.inputRef.select();

  useEffect(() => {
    if (value?.label) {
      setInputValue(value.label);
      return;
    }
    if (value?.label === null || value?.label === '') {
      setInputValue(null);
      return;
    }
  }, [value?.label]);

  const _props = {
    components: {
      IndicatorSeparator,
      Input,
      NoOptionsMessage,
      DropdownIndicator: isShowArrow ? DropdownIndicator : null,
      ...customComponents
    },
    isClearable: isClearable,
    options: options,
    placeholder: placeholder,
    debounceTimeout: 500,
    loadOptions: loadOptions,
    additional: { page: 1 },
    classNamePrefix: 'react-select',
    loadingMessage: LoaderMessage,
    loadOptionsOnMenuOpen: loadOptionsOnMenuOpen,
    value: value,
    inputValue: inputValue,
    onInputChange: onInputChange,
    onChange: onChange,
    onFocus: onFocus,
    defaultOptions: true,
    controlShouldRenderValue: false,
    cacheOptions: true,
    openMenuOnClick: openMenuOnClick,
    isMulti: isMulti,
    ...props,
  };

  return (
    <StyledWrapper className={classes}>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={rules}
        render={() => isCreatable
          ? <CreatableAsyncPaginate
            ref={selectRef}
            {..._props}
            SelectComponent={Creatable}
            formatCreateLabel={(userInput) => `Select '${userInput}'`}
          />
          : <AsyncPaginate ref={selectRef} {..._props} />
        }
      />
      {error && <ErrorMessage message={error.message} className='error' />}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: relative;
  .error {
    position: absolute;
    top: 100%;
    padding-top: 4px;
  }
`;

AsyncSelect.propTypes = {
  placeholder: PropTypes.string,
  clearErrors: PropTypes.func,
  blackTheme: PropTypes.bool,
  name: PropTypes.string,
  rules: PropTypes.object,
  error: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  watch: PropTypes.func,
  setValue: PropTypes.func,
  loadOptions: PropTypes.func,
  defaultValue: PropTypes.object,
  isShowArrow: PropTypes.bool,
  openMenuOnClick: PropTypes.bool,
  isMulti: PropTypes.bool,
  customComponents: PropTypes.func,
  loadOptionsOnMenuOpen: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
  isCreatable: PropTypes.bool,
};

export default AsyncSelect;
