import React from 'react';
import PropTypes from 'prop-types';
import List from './List';
import styled from 'styled-components';

const Machine = React.memo(function Machine({ slides, currentSlideIndex }) {
  return (
    <StyledWrapper className="mb-machine">
      <List slides={slides} currentSlideIndex={currentSlideIndex} />
    </StyledWrapper>
  )
});

const StyledWrapper = styled.div`
  padding-top: 26px;
  padding-bottom: 20px;
  overflow: hidden;
`

Machine.propTypes = {
  slides: PropTypes.array.isRequired,
  currentSlideIndex: PropTypes.number.isRequired,
  setCurrentSlideIndex: PropTypes.func.isRequired
};

export default Machine;
