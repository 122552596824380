import React from 'react';

import {
  getVideoChat as getVideoChatApi,
  createVideoChat as createVideoChatApi,
  updateVideoChat as updateVideoChatApi,
} from 'api/appApi/offers/videoChat';

import { notify } from 'utils/notify';
import { getFilesFromMedia } from 'utils/services/validators/library';

import { useTalentId } from 'store/hooks/globalState/useUser';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useAddFilesToLibrary } from 'store/hooks/globalState/useFileLibrary';
import { useSetVideoChat } from 'store/hooks/globalState/videoChat';
import { useStateModal } from 'store/hooks/globalState/useModal';
import { useNavigate } from 'store/hooks/globalState/useSwitchNavigation';

import { OFFERINGS } from '_legacy/constants/routes';
import { PROMOTIONAL_MEDIA_ASK_MODAL } from '_legacy/components/Modal/modals-types';

import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

export function useFetchVideoChat() {
  const addFilesToFilesLibrary = useAddFilesToLibrary();
  const talentId = useTalentId();

  const setVideoChatData = useSetVideoChat((prev, data) => ({ ...prev, data }));
  const setVideoChatError = useSetVideoChat((prev, error) => ({ ...prev, error }));
  const setVideoChatLoading = useSetVideoChat((prev, loading) => ({ ...prev, loading }));
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    setVideoChatLoading(true);
    setVideoChatData(null);

    getVideoChatApi({ talentId })
      .then(({ videoChat }) => {
        const media = getFilesFromMedia(videoChat);
        addFilesToFilesLibrary(media);
        setVideoChatData(videoChat);
      })
      .catch(err => {
        handleResponseError(err)
        setVideoChatError(err);
        setVideoChatData({});
      })
      .finally(() => {
        setVideoChatLoading(false);
      });
  }, [addFilesToFilesLibrary, setVideoChatData, setVideoChatError, setVideoChatLoading, talentId]);
}

export function useCreateVideoChat() {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const navigate = useNavigate();
  const openAskModal = useStateModal(PROMOTIONAL_MEDIA_ASK_MODAL);

  const setVideoChatError = useSetVideoChat((prev, error) => ({ ...prev, error }));
  const setVideoChatLoading = useSetVideoChat((prev, loading) => ({ ...prev, loading }));

  return React.useCallback(data => {
    setVideoChatLoading(true);

    // todo: remove conditional when backend fixed it
    createVideoChatApi({ talentId, data })
      .then(({ isPromotionalMediaNeeded }) => {
        if (isPromotionalMediaNeeded) {
          notify(t('video-chat.form.message.create.successfully'));
          openAskModal();
        } else {
          navigate(`/${OFFERINGS}`, t('video-chat.form.message.create.successfully'));
        }
      })
      .catch(error => {
        notify(error.message, 'error');
        setVideoChatError(error);
      })
      .finally(() => {
        setVideoChatLoading(false);
      });
  }, [talentId, t, setVideoChatLoading, setVideoChatError]);
}

export function useUpdateVideoChat() {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const navigate = useNavigate();

  const setVideoChatLoading = useSetVideoChat((prev, loading) => ({ ...prev, loading }));
  const setVideoChatError = useSetVideoChat((prev, error) => ({ ...prev, error }));

  return React.useCallback(data => {
    setVideoChatLoading(true);

    updateVideoChatApi({ talentId, data })
      .then(() => {
        navigate(`/${OFFERINGS}`, t('video-message.form.message.update.successfully'));
      })
      .catch(error => {
        notify(error.message, 'error');
        setVideoChatError(error);
      })
      .finally(() => {
        setVideoChatLoading(false);
      });
  }, [talentId, t, setVideoChatLoading, setVideoChatError, navigate]);
}
