import React from 'react';

import {
  getVideoMessageForCommercialUse as getCommercialCommercialVideoMessageApi,
  createVideoMessageForCommercialUse as createCommercialVideoMessageApi,
  updateVideoMessageForCommercialUse as updateCommercialVideoMessageApi,
} from 'api/appApi/offers/videoMessageForCommercialUse';

import { notify } from 'utils/notify';
import { getFilesFromMedia } from 'utils/services/validators/library';

import { useTalentId } from 'store/hooks/globalState/useUser';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useAddFilesToLibrary } from 'store/hooks/globalState/useFileLibrary';
import { useSetCommercialVideoMessage } from 'store/hooks/globalState/commercialVideoMessage';
import { useNavigate } from 'store/hooks/globalState/useSwitchNavigation';
import { OFFERINGS } from '_legacy/constants/routes';
import { useStateModal } from 'store/hooks/globalState/useModal';
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import { PROMOTIONAL_MEDIA_ASK_MODAL } from '_legacy/components/Modal/modals-types';


export function useFetchCommercialVideoMessage() {
  const addFilesToFilesLibrary = useAddFilesToLibrary();
  const talentId = useTalentId();

  const setCommercialVideoMessageData = useSetCommercialVideoMessage((prev, data) => ({ ...prev, data }));
  const setCommercialVideoMessageError = useSetCommercialVideoMessage((prev, error) => ({ ...prev, error }));
  const setCommercialVideoMessageLoading = useSetCommercialVideoMessage((prev, loading) => ({ ...prev, loading }));
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    setCommercialVideoMessageLoading(true);
    setCommercialVideoMessageData(null);

    getCommercialCommercialVideoMessageApi({ talentId })
      .then(({ videoMessage }) => {
        const media = getFilesFromMedia(videoMessage);
        addFilesToFilesLibrary(media);
        setCommercialVideoMessageData(videoMessage);
      })
      .catch(err => {
        handleResponseError(err)
        setCommercialVideoMessageError(err);
        setCommercialVideoMessageData({});
      })
      .finally(() => {
        setCommercialVideoMessageLoading(false);
      });
  }, [addFilesToFilesLibrary, setCommercialVideoMessageLoading, setCommercialVideoMessageError, setCommercialVideoMessageLoading, talentId]);
}

export function useCreateCommercialVideoMessage() {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const navigate = useNavigate();
  const openAskModal = useStateModal(PROMOTIONAL_MEDIA_ASK_MODAL);

  const setCommercialVideoMessageError = useSetCommercialVideoMessage((prev, error) => ({ ...prev, error }));
  const setCommercialVideoMessageLoading = useSetCommercialVideoMessage((prev, loading) => ({ ...prev, loading }));

  return React.useCallback(data => {
    setCommercialVideoMessageLoading(true);

    createCommercialVideoMessageApi({ talentId, data })
      .then(({ isPromotionalMediaNeeded }) => {
        if (isPromotionalMediaNeeded) {
          notify(t('video-message.form.message.create.successfully'));
          openAskModal();
        } else {
          navigate(`/${OFFERINGS}`, t('video-message.form.message.create.successfully'));
        }
      })
      .catch(error => {
        notify(error.message, 'error');
        setCommercialVideoMessageError(error);
      })
      .finally(() => {
        setCommercialVideoMessageLoading(false);
      });
  }, [navigate, talentId, t, setCommercialVideoMessageLoading, setCommercialVideoMessageError]);
}

export function useUpdateCommercialVideoMessage() {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const navigate = useNavigate();

  const setCommercialVideoMessageLoading = useSetCommercialVideoMessage((prev, loading) => ({ ...prev, loading }));
  const setCommercialVideoMessageError = useSetCommercialVideoMessage((prev, error) => ({ ...prev, error }));

  return React.useCallback(data => {
    setCommercialVideoMessageLoading(true);

    updateCommercialVideoMessageApi({ talentId, data })
      .then(() => {
        navigate(`/${OFFERINGS}`, t('video-message.form.message.update.successfully'));
      })
      .catch(error => {
        notify(error.message, 'error');
        setCommercialVideoMessageError(error);
      })
      .finally(() => {
        setCommercialVideoMessageLoading(false);
      });
  }, [navigate, talentId, t, setCommercialVideoMessageLoading, setCommercialVideoMessageError]);
}
