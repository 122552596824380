import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RequestButton from './components/RequestButton';
import ArrowButton from './components/ArrowButton';

const WithoutArrows = ({
  children,
  slidesCount,
  topOffset = 0,
  padding,
  className,
  onRequestClick,
}) => {
  if (slidesCount === 0) {
    return (
      <StyledActions
        marginTop={-topOffset}
        padding={padding}
        className={className}
      >
        <RequestButton onClick={onRequestClick} />
      </StyledActions>
    );
  }
  return (
    <StyledActions
      marginTop={-topOffset}
      padding={padding}
      className={className}
    >
      {children}
    </StyledActions>
  );
};

const BasicActions = ({ onNext, onPrev, children, slidesCount, topOffset = 0, padding, ...props }) => (
  <WithoutArrows topOffset={topOffset} padding={padding} slidesCount={slidesCount} className='action-buttons' {...props}>
    <ArrowButton
      className="tbv-slider-actions-left-arrow"
      isMirrored={true}
      onClick={onPrev}
      disabled={slidesCount <= 1}
    />
    {children}
    <ArrowButton
      className="tbv-slider-actions-right-arrow"
      onClick={onNext}
      disabled={slidesCount <= 1}
    />
  </WithoutArrows>
);

const StyledActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
  margin-top: ${({ marginTop }) => marginTop}px;
  padding: ${({ padding }) => padding};
  @media screen and (max-width: 1024px) {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }
  @media screen and (max-width: 425px) {
    max-width: 270px;
  }
`

BasicActions.propTypes = WithoutArrows.propTypes = {
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  topOffset: PropTypes.number,
  padding: PropTypes.string,
  slidesCount: PropTypes.number,
};

BasicActions.WithoutArrows = WithoutArrows;

export default BasicActions;
