import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import PhoneConfirmButton from './PhoneConfirmButton';
import PhoneWithAsyncValidation from '_legacy/common/Input/PhoneWithAsyncValidation';


const PhoneConfirm = ({ fields, isEditable }) => {
  const { watch } = useFormContext();
  const value = watch([fields.isCodeConfirm, fields.isPhoneSand]);
  const isPhoneSand = get(value, fields.isPhoneSand);

  return (
    <PhoneConfirmWrapper>
      <PhoneWithAsyncValidation
        fields={fields}
        disabled={isPhoneSand}
        isEditable={isEditable}
      />
      <PhoneConfirmButton fields={fields} />
    </PhoneConfirmWrapper>
  );
};

PhoneConfirm.propTypes = {
  isEditable: PropTypes.bool,
  fields: PropTypes.object,
};

const PhoneConfirmWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;

export default PhoneConfirm;
