import React from 'react'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import PriceInput from '_legacy/common/Input/PriceInput'
import { Row } from '_legacy/common'

const AficionadoNoShowFee = () => {
  const t = useGetTranslate()

  return (
    <Row
      nameBlock={t('create-experience.form.aficionado-no-show-fee.label')}
      component={
        <AficionadoWrapper>
          <PriceInput
            name="aficionadoNoShowFee"
            placeholder={t('create-experience.form.aficionado-no-show-fee.placeholder')}
            rules={{
              min: {
                message: 'create-experience.form.aficionado-no-show-fee.errors.min',
                value: 5,
              },
            }}
          />
        </AficionadoWrapper>
      }
    />
  )
}

const AficionadoWrapper = styled.div`
  .input-mask {
    height: 60px;
  }
`

export default AficionadoNoShowFee
