import React from 'react'
// import { Link } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import isEmpty from 'lodash/isEmpty'
import compose from 'lodash/fp/compose'
import get from 'lodash/get'

import PageLoader from '_legacy/common/Template/PageLoader'
import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary';
import VideoMessageForm from '_legacy/components/VideoMessageForm';
import RequiredLabel from '_legacy/components/RegisterForms/shared/components/RequiredLabel'

import { useSetPathToNavigate } from 'store/hooks/globalState/useSwitchNavigation';
// import { useUser } from 'store/hooks/globalState/useUser'
import { useVideoMessage } from 'store/hooks/globalState/videoMessage'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useFetchVideoMessage, useUpdateVideoMessage } from 'requests/video-message'
import { getVideoMessageAdapter, submitVideoMessageAdapter, videoMessageDefaultValues } from '_legacy/components/VideoMessageForm/utils';
import { /* COMMERCIAL_ENGAGEMENTS,*/ OFFERINGS } from '_legacy/constants/routes'

const EditVideoMessage = () => {
  useSetPathToNavigate({ talentRoute: `/${OFFERINGS}`, agentRoute: '/' })

  const t = useGetTranslate()
  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: videoMessageDefaultValues,
  })

  // const talentNickname = useUser(state => get(state, ['talent', 'nickname']))

  const videoMessageData = useVideoMessage(state => get(state, 'data'))
  const videoMessageLoading = useVideoMessage(state => get(state, 'loading'))

  const fetchVideoMessage = useFetchVideoMessage()
  const updateVideoMessage = useUpdateVideoMessage()

  React.useEffect(() => {
    fetchVideoMessage()
  }, [])

  React.useEffect(() => {
    if (videoMessageData) {
      methods.reset(getVideoMessageAdapter(videoMessageData), { dirtyFields: false })
    }
  }, [videoMessageData])

  if (!videoMessageData) return <PageLoader />

  const onSubmit = compose(updateVideoMessage, submitVideoMessageAdapter)

  return (
    <FormProvider {...methods}>
      <ChangeRouteHandler
        isDraft={false}
        onSubmit={methods.handleSubmit(onSubmit)}
        shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
      />
      <div className="video-message">
        <h1 className="header">
          {t('video-message.header.title.edit')}
        </h1>
        {/* EN-3151 hide text and link */}
        {/*<h3 className="video-message__subheader">*/}
        {/*  {t('video-message.subheader.title')}&nbsp;*/}
        {/*  <Link to={`/${talentNickname}/${COMMERCIAL_ENGAGEMENTS}`}>*/}
        {/*    {t('video-message.subheader.link')}*/}
        {/*  </Link>*/}
        {/*</h3>*/}
        <RequiredLabel />
        <VideoMessageForm onSubmit={onSubmit} isLoading={videoMessageLoading} />
      </div>
    </FormProvider>
  )
}

export default withLibrary(EditVideoMessage)
