import cond from 'lodash/cond';
import constant from 'lodash/constant';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const isBlack = ({ isFocused, isError, isDirty }) => isFocused && isError || !isDirty;

const isGrey = ({ isError }) => !isError;

const isRed = ({ isFocused, isError, isTouched }) => isError && (!isFocused || isTouched);

const getErrorListItemColor = cond([
  [isGrey, constant('#9495A7')],
  [isBlack, constant('black')],
  [isRed, constant('red')],
]);

const PasswordErrorListItem = styled.li`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.625;
    transition: color .3s linear;

    color: ${getErrorListItemColor};
    ${p => p};
`;

PasswordErrorListItem.propTypes = {
  isDirty: PropTypes.bool,
  isError: PropTypes.bool,
  isFocused: PropTypes.bool,
  isTouched: PropTypes.bool,
};

export default PasswordErrorListItem;
