import React from 'react';
import { setYear } from 'date-fns'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

// components
import DatePicker from '_legacy/common/DatePicker/DatePicker';
import { Row } from '_legacy/common/Template';

const maxUserYear = 16;
const minDate = setYear(new Date(), 1900)

const DateOfBirth = ({ name = 'dateOfBirth', ...props }) => {
  const t = useGetTranslate();
  const date = new Date();
  const maxDate = new Date(date.setFullYear(date.getFullYear() - maxUserYear))

  return (
    <Row
      isRequired
      nameBlock={t('register-user.form.date-of-birth.label')}
      subNameBlock={t('register-user.form.date-of-birth.tip')}
      component={
        <DatePicker
          name={name}
          placeholder={t('register-user.form.date-of-birth.placeholder')}
          isRequired
          openToDate={date}
          rules={{
            validate: date => {
              if (date < minDate) return t('register.form.date-of-birth.errors.max-age')
              if (date > maxDate) return t('register.form.date-of-birth.errors.min-age')
            }
          }}
          {...props}
        />
      }
    />
  );
};

export default DateOfBirth;
