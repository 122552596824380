import React from 'react'
import { useParams } from 'react-router-dom'
import get from 'lodash/get'

import { getFullName } from 'utils/user.js'

import { Container, Title } from '_legacy/common'
import CommercialEngagementsRequestForm from './CommercialEngagementsRequestForm'

import PageLoader from '_legacy/common/Template/PageLoader'
import { useFetch } from 'store/hooks/useFetch'
import { Trans } from 'store/hooks/globalState/useTranslates'
import { getCommercialEngagement } from 'api/appApi/offers/commercialEngagements'


export const CommercialEngagementsRequest = () => {
  const { talentNickname } = useParams()

  const [{ data }, getTalentIfo] = useFetch(getCommercialEngagement)

  React.useEffect(() => {
    getTalentIfo({ talentNickname })
  }, [talentNickname]);

  const offerId = get(data, 'talent.commercialVideoMessage.id')

  if (!data) return <PageLoader />

  const fullName = getFullName(data.talent)

  return (
    <div className="commercial-engagements-request">
      <Container>
        <Title className='_title'><Trans tKey='request-video-mes-commercial.title' />
          <span className='fullName'>{fullName}</span>
        </Title>
        <CommercialEngagementsRequestForm offerId={offerId} fullName={fullName} />
      </Container>
    </div>
  )
}

export default CommercialEngagementsRequest
