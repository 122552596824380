import React from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from './AsyncSelectComponent';
import { loadAddressOptions } from 'utils/services/loaders/locations';

const AddressSelect = React.memo(function AddressSelect (props) {
  const locationsLoader = async (...rest) => {
    if (rest[0].trim().length === 0) return { options: [], hasMore: false };

    const response = await loadAddressOptions(...rest);

    if (props?.usedLocations) {
      return {
        ...response,
        options: response.options.filter(
          o => !props?.usedLocations.find(c => c.value === o.value),
        ),
      };
    }

    return response;
  };
  return (
    <AsyncSelect
      isShowArrow={false}
      openMenuOnClick={false}
      loadOptions={locationsLoader}
      {...props}
    />
  );
});

AddressSelect.propTypes = {
  placeholder: PropTypes.string,
  clearErrors: PropTypes.func,
  blackTheme: PropTypes.bool,
  name: PropTypes.string,
  rules: PropTypes.object,
  error: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  usedLocations: PropTypes.array,
};

export default AddressSelect;
