import React from 'react'
import { useForm } from 'react-hook-form'
import compose from 'lodash/fp/compose'
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useFetchVideoChat, useUpdateVideoChat } from 'requests/video-chat';

import Form from '_legacy/components/VideoChatForm'
import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler';
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'

import { useSetVideoChat, useVideoChat } from 'store/hooks/globalState/videoChat'
import { useSetPathToNavigate } from 'store/hooks/globalState/useSwitchNavigation';
import { getVideoChatAdapter, submitVideoChatAdapter } from '_legacy/components/VideoChatForm/utils';
import { OFFERINGS } from '_legacy/constants/routes';

const EditVideoChat = React.memo(() => {
  useSetPathToNavigate({ talentRoute: `/${OFFERINGS}`, agentRoute: '/' })

  const t = useGetTranslate()
  const updateVideoChat = useUpdateVideoChat()
  const fetchVideoChat = useFetchVideoChat()
  const videoChatData = useVideoChat(state => get(state, 'data'))
  const clearVideoChat = useSetVideoChat(() => null)

  const methods = useForm({
    shouldUnregister: false,
    mode: 'onChange',
  })

  const onSubmit = compose(
    updateVideoChat,
    submitVideoChatAdapter
  )

  React.useEffect(() => {
    fetchVideoChat()
  }, [])

  React.useEffect(() => {
    if (videoChatData) {
      methods.reset(getVideoChatAdapter(videoChatData, t), { dirtyFields: false })
    }
  }, [videoChatData])

  React.useEffect(() => () => clearVideoChat(), [])

  return (
    <div className="video-chat">
      <h1 className="header mb-62">{t('video-chat.header.title.edit')}</h1>
      <Form methods={methods} onSubmit={onSubmit} />
      <ChangeRouteHandler
        isDraft={false}
        onSubmit={methods.handleSubmit(onSubmit)}
        shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
      />
    </div>
  )
})

export default withLibrary(EditVideoChat)
