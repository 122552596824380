import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Body2 from '_legacy/common/Typography/Body2';
import H3 from '_legacy/common/Typography/H3';
import Charities from './Charities';
import Price from './Price';

import { useCommercialEngagements } from 'store/hooks/globalState/useCommercialEngagements';
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';

const Info = ({ className }) => {
  const t = useGetTranslate()
  const commercialEngagements = useCommercialEngagements();
  const displayName = get(commercialEngagements, 'displayName');

  return (
    <InfoContainer className={className}>
      <TalentContainer>
        <H3>{t('commercial-engagements.video-message.title')}</H3>
        <Body2>
          <Trans
            tKey='commercial-engagements.video-message.text'
            phs={[{ ph: '{{displayName}}', value: displayName }]}
          />
        </Body2>
      </TalentContainer>
      <Price />
      <Charities />
    </InfoContainer>
  );
};

const InfoContainer = styled.div``;

const TalentContainer = styled.div`
  grid-area: info;
  margin-bottom: 16px;

  ${H3} {
    margin-bottom: 8px;
  }

  ${Body2} {
    display: inline;
    b {
      font-weight: 700;
    }
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 24px;

    ${H3} {
      font-size: 23px;
      line-height: 29px;
      margin-bottom: 12px;
    }
  }

  @media screen and (max-width: 767px) {
    ${H3} {
      text-align: center;
    }

    ${Body2} {
      text-align: center;
    }
  }
`;

Info.propTypes = {
  className: PropTypes.string,
};

export default Info;
