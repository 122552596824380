import { ONE_HOUR, ONE_DAY, ONE_WEEK, getTimeOptions } from 'utils/secondsToWdhms/secondsToWdhms'

const listOfSeconds = [
  ONE_HOUR,
  ONE_HOUR * 2,
  ONE_HOUR * 3,
  ONE_HOUR * 4,
  ONE_HOUR * 5,
  ONE_HOUR * 7,
  ONE_HOUR * 10,
  ONE_HOUR * 12,
  ONE_HOUR * 20,
  ONE_DAY,
  ONE_DAY * 2,
  ONE_DAY * 3,
  ONE_WEEK,
  ONE_WEEK * 2,
  ONE_WEEK * 3,
  ONE_WEEK * 4,
]

export const getMinBookingTimeOptions = options => getTimeOptions(listOfSeconds, options)

export const minBookingTimeOptions = getMinBookingTimeOptions()
