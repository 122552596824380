import { pipe, propOr, T, cond, isEqual, complement, prop, compose, join, values, filter, pick, paths, compact, anyPass, allPass, where, head, props } from 'lodash/fp'
import { UserType, UserRegistrationType, UserProfileType } from 'shared'
import { routesList } from '../router/routesMap'

const DELETED_USER_FULL_NAME = 'Deleted User'
const DELETED_TALENT_FULL_NAME = 'Deleted Profile'

const withDefaultUserFullName = name => name || DELETED_USER_FULL_NAME
const withDefaultTalentFullName = name => name || DELETED_TALENT_FULL_NAME

export const getUser = propOr({}, 'user')

export const getTalent = prop('talent')

export const getType = prop('type')

export const getRegistrationType = prop('registrationType')

export const getId = prop('id')

export const getFileLibraryId = prop('fileLibrary.id')

export const isAgentType = isEqual(UserType.AGENT)

export const isManagerType = isEqual(UserType.MANAGER)

export const isTalentUserType = isEqual(UserType.TALENT_USER)

export const isAficionadoPersonType = isEqual(UserProfileType.AFICIONADO_PERSON)

export const isAficionadoType = isEqual(UserRegistrationType.AFICIONADO)

/**
 * Function to get User full name
 * @func getFullName
 * @param {Object} user - The default system user.
 * @param {string} user.firstName - The User first name.
 * @param {string} user.lastName - The User last name.
 * @return {string} A User full name
 *
 * @example
 *     useUser(getFullName)
 *     getFullName({ firstName: 'John', lastName: 'Doe' })
 *     getFullName(user)
 */
export const getFullName = compose(join(' '), values, filter(Boolean), pick(['firstName', 'lastName']))

export const getSaveFullName = compose(withDefaultUserFullName, getFullName)

export const getUserFullName = compose(withDefaultUserFullName, getFullName, getUser)

export const getTalentFullName = compose(withDefaultTalentFullName, getFullName, getTalent)

/**
 * Function to get User and Talent file library ids
 * @func getLibraryIds
 * @param {Object} viewer - The default system viewer.
 * @param {string} viewer.user.fileLibrary.id - The User file library id.
 * @param {string} viewer.talent.fileLibrary.id - The Talent file library id.
 * @return {(string|Array)} A User and Talent library ids
 *
 * @example
 *     useUser(getLibraryIds)
 *     getLibraryIds(user)
 */
export const getFileLibraryIds = compose(
  compact,
  paths([
    ['user', 'fileLibrary', 'id'],
    ['talent', 'fileLibrary', 'id'],
  ])
)

/**
 * Function to get user type
 * @func getUserType
 * @param {Object} viewer - The default system viewer.
 * @param {Object} viewer.user.type - The default system user type.
 * @return {string} The default system user type.
 *
 * @example
 *     useUser(getUserType)
 *     getUserType(user)
 */
export const getUserType = compose(getType, getUser)

export const getTalentType = compose(getType, getTalent)

/**
 * Function that checks that the user is agent
 * @func isAgent
 * @param {Object} viewer - The default system viewer.
 * @param {Object} viewer.user.type - The default system user type.
 * @return {boolean} Is the user is agent
 *
 * @example
 *     useUser(isAgent)
 *     isAgent(user)
 */
export const isAgent = compose(isAgentType, getUserType)

/**
 * Function that checks that the user is manager
 * @func isManager
 * @param {Object} viewer - The default system viewer.
 * @param {Object} viewer.user.type - The default system user type.
 * @return {boolean} Is the user is manager
 *
 * @example
 *     useUser(isManager)
 *     isManager(user)
 */
export const isManager = compose(isManagerType, getUserType)

/**
 * Function that checks that the user is manager or agent
 * @func isManager
 * @param {Object} viewer - The default system viewer.
 * @param {Object} viewer.user.type - The default system user type.
 * @return {boolean} Is the user is manager or agent
 *
 * @example
 *     useUser(isAgentOrManager)
 *     isAgentOrManager(user)
 */
export const isAgentOrManager = anyPass([isAgent, isManager])

/**
 * Function that checks that the user is talent
 * @func isTalentUser
 * @param {Object} viewer - The default system viewer.
 * @param {Object} viewer.user.type - The default system user type.
 * @return {boolean} Is the user is talent
 *
 * @example
 *     useUser(isTalentUser)
 *     isTalentUser(user)
 */
export const isTalentUser = compose(isTalentUserType, getUserType)

/**
 * Function to get talent id
 * @func getTalentId
 * @param {Object} viewer - The default system viewer.
 * @param {Object} viewer.talent.id - The talent id
 * @return {string} The talent id
 *
 * @example
 *     useUser(getTalentId)
 *     getTalentId(user)
 */
export const getTalentId = prop('talent.id')

/**
 * Function to get user id
 * @func getUserId
 * @param {Object} viewer - The default system viewer.
 * @param {Object} viewer.user.id - The user id
 * @return {string} The user id
 *
 * @example
 *     useUser(getUserId)
 *     getUserId(user)
 */
export const getUserId = compose(getId, getUser)

/**
 * Function that check that the user has talent
 * @func isUserHasTalent
 * @param {Object} viewer - The default system viewer.
 * @param {Object} viewer.talent.id - The talent id
 * @return {string} Is the user has talent
 *
 * @example
 *     useUser(isUserHasTalent)
 *     isUserHasTalent(user)
 */
export const isUserHasTalent = compose(Boolean, getTalentId)

/**
 * Function that check that the user has not talent
 * @func isUserHasNotTalent
 * @param {Object} viewer - The default system viewer.
 * @param {Object} viewer.talent.id - The talent id
 * @return {string} Is the user has talent
 *
 * @example
 *     useUser(isUserHasNotTalent)
 *     isUserHasNotTalent(user)
 */
export const isUserHasNotTalent = compose(complement(Boolean), getTalentId)

/**
 * Function to get user plaque nickname
 * @func getPlaqueNickname
 * @param {Object} viewer - The viewer
 * @param {Object} viewer.plaqueNickname - The plaque nickname
 * @return {string} The plaque nickname
 *
 * @example
 *     getPlaqueNickname(viewer)
 */
export const getPlaqueNickname = prop('plaqueNickname')

/**
 * Function to get user talent plaque nickname
 * @func getTalentPlaqueNickname
 * @param {Object} viewer - The default logged in system viewer.
 * @param {Object} viewer.talent.plaqueNickname - The talent plaque nickname
 * @return {string} The The plaque nickname
 *
 * @example
 *     getPlaqueNickname(user)
 *     useUser(getPlaqueNickname)
 */
export const getTalentPlaqueNickname = compose(getPlaqueNickname, getTalent)

/**
 * Function to get user plaque nickname
 * @func getUserPlaqueNickname
 * @param {Object} viewer - The default logged in system viewer.
 * @param {Object} viewer.user.plaqueNickname - The user plaque nickname
 * @return {string} The plaque nickname
 *
 * @example
 *     getUserPlaqueNickname(user)
 *     useUser(getUserPlaqueNickname)
 */
export const getUserPlaqueNickname = compose(getPlaqueNickname, getUser)

/**
 * Function that checks that the user is manager or agent and have active talent
 * @func isAgentWithTalent
 * @param {Object} viewer - The default system viewer.
 * @param {Object} viewer.user.type - The default system user type.
 * @return {boolean} If user have active talent
 *
 * @example
 *     useUser(isAgentWithTalent)
 *     isAgentWithTalent(user)
 */
export const isAgentWithTalent = allPass([isAgentOrManager, isUserHasTalent])

/**
 * Function that checks that the user is manager or agent and have not active talent
 * @func isAgentWithTalent
 * @param {Object} viewer - The default system viewer.
 * @param {Object} viewer.user.type - The default system user type.
 * @return {boolean} If user have not active talent
 *
 * @example
 *     useUser(isAgentWithoutTalent)
 *     isAgentWithoutTalent(user)
 */
export const isAgentWithoutTalent = allPass([isAgentOrManager, isUserHasNotTalent])

/**
 * Function that checks that the user is aficionado
 * @func isAficionado
 * @param {Object} viewer - The default system viewer.
 * @param {Object} viewer.registrationType - The default system registration user.
 * @return {boolean} If user aficionado
 *
 * @example
 *     isAficionado(user)
 *     useUser(isAficionado)
 */
export const isAficionado = compose(isAficionadoType, getRegistrationType, getUser)

export const getUserPublicViewLink = cond([
  [where({ type: isTalentUserType }), compose(routesList.talentPublicPage, getPlaqueNickname)],
  [where({ type: isAficionadoPersonType }), compose(routesList.userPublicPage, getPlaqueNickname)],
  [where({ type: isManagerType }), compose(routesList.userPublicPage, getPlaqueNickname)],
  [where({ type: isAgentType }), compose(routesList.userPublicPage, getPlaqueNickname)],
  [T, routesList.notFound],
])

export const getCurrentUserName = cond([
  [isAficionado, getUserFullName],
  [isTalentUser, getTalentFullName],
  [isAgentOrManager, getUserFullName]
])

/*
 * Function to get user time zone
 * @func getTimeZone
 * @param {Object} viewer - The viewer
 * @param {Object} viewer.timeZoneId - The time zone
 * @return {string} The user time zone
 *
 * @example
 *     getUserZone(viewer)
 */
export const getTimeZone = prop('timeZoneId')

/**
 * Function to get user time zone
 * @func getUserTimeZone
 * @param {Object} viewer - The viewer
 * @param {Object} viewer.timeZoneId - The time zone
 * @return {string} The user time zone
 *
 * @example
 *     getUserZone(viewer)
 *     useUser(getTimeZone)
 */
export const getUserTimeZone = compose(getTimeZone, getUser)

/**
 * Function to get talent id if exist or user id
 * @func getTalentOrUserId
 * @param {Object} viewer - The viewer
 * @param {Object} viewer.talent.id
 * @return {string} viewer.user.id
 *
 * @example
 *      const viewerWithTalent = {
 *        user: { id: 1 },
 *        talent: { id: 2 }
 *      }
 *     getTalentOrUserId(viewerWithTalent) // 2
 *
 *     const viewerWithoutTalent = {
 *       user: { id: 1 },
 *       talent: null
 *     }
 *     getTalentOrUserId(viewer) // 1
 */
export const getTalentOrUserId = compose(
  head,
  compact,
  props(['talent.id', 'user.id'])
)

export const getEntourageType = prop('entourageType')

export const getUserEntourageType = compose(
  getEntourageType,
  getUser
)

export const getTalentUserIdOrUserId = compose(
  head,
  compact,
  props(['talent.user.id', 'user.id'])
)


export const getShouldShowRegisterTalentLink = (props) => {
  const isRegistrationTypeTalent = props?.user?.registrationType === UserRegistrationType.TALENT
  const isProfileTypeTalent = props?.user?.type === UserProfileType.TALENT_USER
  const hasNoTalent = !props?.talent

  return isRegistrationTypeTalent && isProfileTypeTalent && hasNoTalent
}

/**
 * @func getUserLink
 * @param {Object} viewer
 * @param {Object} viewer.plaqueNickname
 * @return {string}
 */
export const getUserLink = pipe(
  getPlaqueNickname,
  routesList.userPublicPage
)

/**
 * @func getTalentLink
 * @param {Object} viewer
 * @param {Object} viewer.plaqueNickname
 * @return {string}
 */
export const getTalentLink = pipe(
  getPlaqueNickname,
  routesList.talentPublicPage
)
