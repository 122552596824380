import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { types } from 'shared'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { Checkbox } from '_legacy/common';
import { Row } from '_legacy/common/Template';
import SingleSelectLibrary from '_legacy/components/Library/SingleSelectLibrary';
import DropzoneBackdrop from '../Image/DropzoneBackdrop';
import MediaBackdrop from '../../../Library/configs/Media/MediaBackdrop';
import MediaPicture from '../../../Library/configs/Media/MediaPicture';

const { VIDEO } = types.file.Types

const Video = () => {
  const t = useGetTranslate();
  const { watch } = useFormContext()
  const video = !!watch('video', false)

  return (
    <StyledVideo
      nameBlock={t('create-experience.form.video.label')}
      component={
        <VideoWrapper>
          <SingleSelectLibrary
            name='video'
            libraryModalProps={{ enableTypes: VIDEO }}
            dropZoneProps={{ accept: VIDEO, hintComponent: DropzoneBackdrop }}
            components={{ customBackdrop: MediaBackdrop, customPicture: MediaPicture }}
          />
          <Checkbox
            disabled={!video}
            className='experience-video--checkbox'
            name='displayVideoInsteadOfImage'
            label={t('create-experience.form.video.checkbox.label')}
          />
        </VideoWrapper>
      }
    />
  );
};

const StyledVideo = styled(Row)`
  max-width: 100%;
  
  .library-inner {
    overflow: hidden;
  }

  .single-select__library-button {
    height: 230px;
  }

  .icon-camera {
    margin-top: 0;
    padding-top: 12px;
  }

  ${'' /* label span {
    color: #9495A7;
  } */}
`;

const VideoWrapper = styled.div`
  display: grid;
  grid-row-gap: 25px;
`;

export default Video;
