import React from 'react';
import get from 'lodash/get';

import {
  switchToTalent as switchToTalentApi,
  switchToManager as switchToManagerApi,
  getConnectedTalents as getConnectedTalentsApi,
} from 'api/appApi/auth/profiles';

import { useGetTranslate, useGetTranslateWithKey } from 'store/hooks/globalState/useTranslates';
import { setTokens } from 'utils/storage';
import { useSetUser, useSetIsUserLoading } from 'store/hooks/globalState/useUser';
import { useSetFileLibrary } from 'store/hooks/globalState/useFileLibrary';
import { useSetConnectedTalents } from 'store/hooks/globalState/useConnectedTalents';
import { libraryInitialState } from 'store/hooks/globalState/useGlobalState';
import { notify } from 'utils/notify';
import { getFullName } from 'utils/user'

import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

function getNotifyName (userData, t) {
  return get(userData, 'displayName') || getFullName(userData) || t('switch-profile.talent-profile');
}

export function useFetchConnectedTalents () {
  const setConnectedTalents = useSetConnectedTalents((prev, next) => next);

  return React.useCallback(() => {
    setConnectedTalents(null);
    getConnectedTalentsApi()
      .then(({ profiles }) => {
        setConnectedTalents(profiles);
      })
      .catch(error => console.warn(error));
  },
  [setConnectedTalents]);
}

export function useSwitchToTalent() {
  const t = useGetTranslate();
  const tKey = useGetTranslateWithKey();
  const setIsSwitching = useSetIsUserLoading((prev, next) => next)
  const setTalent = useSetUser((prev, next) => ({ ...prev, talent: next.talent, user: next.user }));
  const clearFileLibrary = useSetFileLibrary(() => libraryInitialState);
  const handleResponseError = useHandleResponseError()

  return React.useCallback((switchProfileId) => {
    setIsSwitching(true)

    switchToTalentApi(switchProfileId)
      .then(({ userData, accessToken, refreshToken, twilioAccessToken }) => {
        setTokens({ accessToken, refreshToken, twilioAccessToken });
        setTalent(userData);
        clearFileLibrary();
        const notifyLabel = tKey({
          tKey: 'switch-profile.notify.success',
          phs: [{ ph: '{{name}}', value: getNotifyName(userData?.talent, t) }]
        });

        setTimeout(() => notify(notifyLabel), 10);
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
      .finally(() => setIsSwitching(false))
  }, [setTalent, clearFileLibrary, tKey, t, setIsSwitching]);
}

export function useSwitchToManager() {
  const t = useGetTranslate();
  const tKey = useGetTranslateWithKey();
  const setIsSwitching = useSetIsUserLoading((prev, next) => next)
  const setUser = useSetUser((prev, next) => ({ ...prev, talent: undefined, user: next }));
  const clearFileLibrary = useSetFileLibrary(() => libraryInitialState);
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    setIsSwitching(true)

    switchToManagerApi()
      .then(({ userData, refreshToken, accessToken, twilioAccessToken }) => {
        setTokens({ accessToken, refreshToken, twilioAccessToken });
        setUser(userData.user);
        clearFileLibrary();

        const notifyLabel = tKey({
          tKey: 'switch-profile.notify.success',
          phs: [{ ph: '{{name}}', value: getNotifyName(userData?.talent, t) }]
        });

        setTimeout(() => notify(notifyLabel), 10);
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
      .finally(() => setIsSwitching(false))
  }, [setUser, clearFileLibrary, tKey, t, setIsSwitching]);
}
