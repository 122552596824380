import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import get from 'lodash/get'

import { Row } from '_legacy/common'
import { useGetRecurringOptions } from './config'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import SelectComponent from '../../../Form/Selects/SelectComponent' // todo: refactor with components
import CustomRecurrence from './ReccuringModal'

const name = 'recurring'

const Recurring = () => {
  const t = useGetTranslate()
  const { control, register } = useFormContext()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const dateOfExperience = useWatch({
    control,
    name: 'dateOfExperience',
    defaultValue: null,
  })
  const getRecurringOptions = useGetRecurringOptions()
  const recurringOptions = getRecurringOptions(dateOfExperience ? new Date(dateOfExperience) : new Date())

  const customOnChange = event => {
    const value = get(event, [0, 'value'])
    if (value === 'CUSTOM') {
      setIsModalOpen(true)
    }
  }

  // register recurrence modal
  React.useEffect(() => {
    register('recurringModal')
  }, [register])

  return (
    <>
      <Row
        isRequired
        nameBlock={t('create-experience.form.recurring.label')}
        component={
          <SelectComponent
            disabled={!dateOfExperience}
            name={name}
            placeholder={t('create-experience.form.recurring.placeholder')}
            options={recurringOptions}
            customOnChange={customOnChange}
            rules={{
              required: 'create-experience.form.recurring.errors.required',
            }}
          />
        }
      />
      <CustomRecurrence isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
    </>
  )
}

export default Recurring
