import React from 'react';
import PropTypes from 'prop-types';
import BasicMobileOverlay from '../../../Overlay/BasicMobileOverlay';
import Tooltip from '../../../../../Tooltip';
import cx from 'classnames'

const Overlay = ({ name, isVideo }) => {
  const classes = cx('tbv-slider-slide-overlay-live-experience', {
    'tbv-slider-slide-overlay-live-experience--video': isVideo,
  })

  return (
    <BasicMobileOverlay withGradientOverhead={true}>
      <div className={classes}>
        <Tooltip tooltipElement={name}>
          <h3 className="tbv-slider-slide-overlay-live-experience-name">
            {name}
          </h3>
        </Tooltip>
      </div>
    </BasicMobileOverlay>
  );
};

Overlay.propTypes = {
  name: PropTypes.string.isRequired,
  isVideo: PropTypes.bool,
};

export default Overlay;
