import styled from 'styled-components';

import ArrowScroll from '_legacy/components/Landing/ArrowScrollDown';

const ArrowScrollDown = styled(ArrowScroll)`
  .down-arrow-1,
  .down-arrow-2 {
    border-color: #000;
  }

  @media screen and (max-width: 1439px) {
    display: none;
  }
`;

export default ArrowScrollDown;
