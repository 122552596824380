import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import compose from 'lodash/fp/compose';
import get from 'lodash/get';

import Label from '_legacy/components/Form/Label';
import FormRow from '_legacy/components/Form/Row';
import legalTerms from '_legacy/constants/legalTerms';
import TermsModal from '_legacy/components/Modal/TermsModal';
import VideoMessageForm from '_legacy/components/VideoMessageForm';
import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler';
import withLibrary from '_legacy/containers/Offerings/components/withLibrary';
import RequiredLabel from '_legacy/components/RegisterForms/shared/components/RequiredLabel';

import { useModal } from 'store/hooks/useModal';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useCommercialVideoMessage } from 'store/hooks/globalState/commercialVideoMessage';
import { useCreateCommercialVideoMessage } from 'requests/commercial-video-message';
import { useSetPathToNavigate } from 'store/hooks/globalState/useSwitchNavigation';
import { submitVideoMessageAdapter, videoMessageDefaultValues } from '_legacy/components/VideoMessageForm/utils';
import { OFFERINGS } from '_legacy/constants/routes';

const CreateCommercialVideoMessage = () => {
  useSetPathToNavigate({ talentRoute: `/${OFFERINGS}`, agentRoute: '/' })

  const t = useGetTranslate()
  const [modalState, openModal, closeModal] = useModal(false)

  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: videoMessageDefaultValues,
  })

  const commercialVideoMessageLoading = useCommercialVideoMessage(state => get(state, 'loading'))

  const createCommercialVideoMessage = useCreateCommercialVideoMessage()
  const onSubmit = compose(createCommercialVideoMessage, submitVideoMessageAdapter)

  return (
    <FormProvider {...methods}>
      <ChangeRouteHandler
        isDraft={false}
        onSubmit={methods.handleSubmit(onSubmit)}
        shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
      />
      <div className="video-message-commercial-use">
        <h1 className="header">
          {t('vmcu.header.title.create')}
        </h1>
        <RequiredLabel />
        <FormRow
          leftItemStyle={{ marginTop: 0 }}
          left={<Label text={t('vmcu.information.label')} />}
          right={
            <div className="vmcu-information">
              <h5 className="vmcu-information__header">
                {t('vmcu.information.content.header')}
              </h5>
              <ul className="vmcu-information__list">
                {legalTerms.map(i => (
                  <li className="vmcu-information__list-item" key={i}>
                    {t(i)}
                  </li>
                ))}
              </ul>
              <button
                onClick={openModal}
                className="vmcu-information__legal-btn"
              >
                {t('vmcu.information.content.legal-button')}
              </button>
            </div>
          }
        />
        <VideoMessageForm {...methods} onSubmit={onSubmit} isLoading={commercialVideoMessageLoading} />

        <TermsModal
          closeModal={closeModal}
          isModalOpen={modalState}
          title="shared.terms.legal.title"
        />
      </div>
    </FormProvider>
  );
};

export default withLibrary(CreateCommercialVideoMessage);
