import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import MainMenu from './MainMenu/';
import AccountMenu from './UserAccount/AccountMenu/';
import PersonalMenu from './UserAccount/';

import { ReactComponent as Logo } from 'assets/logo.svg';
import { useIsUserLoggedIn } from 'store/hooks/globalState/useUser';

const Header = ({ themeType }) => {
  const isUserLoggedIn = useIsUserLoggedIn();

  const [isSignInModalOpen, toggleSignInModal] = useState(false);
  const [isAccountMenuActive, toggleAccountMenu] = useState(false);

  useEffect(() => {
    toggleAccountMenu(isUserLoggedIn);
  }, [isUserLoggedIn]);

  const personalMenuProps = {
    isUserLoggedIn: isUserLoggedIn,
    isSignInModalOpen: isSignInModalOpen,
    toggleSignInModal: () => toggleSignInModal,
    isAccountMenuActive: isAccountMenuActive,
    toggleAccountMenu: () => toggleAccountMenu(prevState => !prevState),
  };

  return (
    <header id='header' className={themeType}>
      <div className='g-p-relative'>
        <div className='blured-on-scroll' />
        <div className="main-menu wrapper content-wrapper">
          <Link to='/' className="main-menu__logo">
            <Logo className="icon-logo-white main-menu__logo--icon" />
          </Link>

          <MainMenu themeType={themeType} />

          <PersonalMenu
            {...personalMenuProps}
            themeType={themeType}
          />
        </div>

        {isAccountMenuActive && <AccountMenu />}
      </div>
    </header>
  );
};

Header.propTypes = {
  themeType: PropTypes.string,
};

export default (Header);
