import React from 'react'
import { useForm } from 'react-hook-form'
import compose from 'lodash/fp/compose'
import isNil from 'lodash/isNil'

import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useExperience,
  useSetExperience
} from 'store/hooks/globalState/offerings/experience'
import {
  useFetchExperienceDraft,
  useCreateExperienceDraft,
  usePublishExperienceDraft,
} from 'requests/offerings/experience'

import CreateExperienceForm from '_legacy/components/CreateExperience'
import OfferingTitle from '_legacy/containers/Offerings/components/OfferingTitle'
import OfferingTemplate from '_legacy/containers/Offerings/components/OfferingTemplate'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'
import PageLoader from '_legacy/common/Template/PageLoader'

import { getUserTimeZone } from 'utils/user'
import { createExperienceAdapter } from '_legacy/components/CreateExperience/utils/createExperienceAdapter'
import { getDefaultValues } from '_legacy/components/CreateExperience/utils/defaultValues'
import getExperienceAdapter from '_legacy/components/CreateExperience/utils/getExperienceAdapter'

const EditExperienceDraft = () => {
  const t = useGetTranslate()
  const publishExperienceDraft = usePublishExperienceDraft()
  const createExperienceDraft = useCreateExperienceDraft()
  const fetchExperienceDraft = useFetchExperienceDraft()
  const clearOccurrence = useSetExperience(() => null)
  const experienceDraft = useExperience()
  const timeZone = useUser(getUserTimeZone)

  const methods = useForm({
    defaultValues: getDefaultValues(t),
    mode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: false,
    shouldUnregister: false,
  })

  const onSubmit = compose(
    publishExperienceDraft,
    createExperienceAdapter
  )

  const onDraft = compose(
    publishExperienceDraft,
    createExperienceDraft,
    createExperienceAdapter,
    methods.getValues
  )

  React.useEffect(() => {
    fetchExperienceDraft()
    return () => {
      clearOccurrence()
    }
  }, [])

  React.useEffect(() => {
    if (!isNil(experienceDraft)) {
      methods.reset(getExperienceAdapter({ ...experienceDraft, timeZone }, t), { isDirty: false })
    }
  }, [experienceDraft])

  if (isNil(experienceDraft)) {
    return <PageLoader />
  }

  return (
    <OfferingTemplate>
      <OfferingTitle>{t('create-experience.header.edit-draft')}</OfferingTitle>
      <CreateExperienceForm
        methods={methods}
        onSubmit={onSubmit}
        onDraft={onDraft}
        isSubmitDisabled={false}
      />
    </OfferingTemplate>
  )
}

export default withLibrary(EditExperienceDraft)
