import React from 'react';
import BasicMobileOverlay from '../../../Overlay/BasicMobileOverlay';
import BookNowButton from '../../../components/BookNowButton';

const Overlay = () => {
  const onBookNowClick = () => {
    alert('This action isn\'t implemented');
  };

  return (
    <BasicMobileOverlay withGradientOverhead={true}>
      <div className="tbv-slider-slide-overlay-live-video-chats">
        <BookNowButton onClick={onBookNowClick} />
      </div>
    </BasicMobileOverlay>
  );
};

export default Overlay;
