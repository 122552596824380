import React from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'

import { notify } from 'utils/notify'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { submitAdapter as submitEditProfileAdapter } from '_legacy/components/RegisterForms/ProfileEdit/utils/submitAdapter'
import { useSetEditProfile } from 'store/hooks/globalState/useEditProfile'
import {
  useAddFilesToEntities,
  useAddFileToEntities,
} from 'store/hooks/globalState/useFileEntites';

import {
  editProfile as editProfileApi,
  getProfileToEdit as getProfileToEditApi,
  editTalentAsDraft as editTalentAsDraftApi
} from 'api/appApi/auth';
import { useNavigate } from 'store/hooks/globalState/useSwitchNavigation';
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

export function useFetchEditProfile() {
  const t = useGetTranslate();

  const addFileToEntities = useAddFileToEntities();
  const addFilesToEntities = useAddFilesToEntities();

  const setEditProfileData = useSetEditProfile((prev, data) => ({ ...prev, data }));
  const setEditProfileError = useSetEditProfile((prev, error) => ({ ...prev, error }));
  const setEditProfileLoading = useSetEditProfile((prev, loading) => ({ ...prev, loading }));
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    setEditProfileLoading(true);
    setEditProfileData(null);

    getProfileToEditApi()
      .then(data => {
        setEditProfileData(data);

        if (!isEmpty(get(data, 'user.profileImage'))) {
          addFileToEntities(
            data.user.profileImage.file.fileToken,
            data.user.profileImage
          );
        }

        if (!isEmpty(get(data, 'agent.profileMedia'))) {
          addFilesToEntities(data.agent.profileMedia.map(img => img.file));
        }

        if (!isEmpty(get(data, 'talent.fullBodyImage'))) {
          addFileToEntities(
            data.talent.fullBodyImage.file.fileToken,
            data.talent.fullBodyImage
          );
        }

        if (!isEmpty(get(data, 'talent.halfBodyImage'))) {
          addFileToEntities(
            data.talent.halfBodyImage.file.fileToken,
            data.talent.halfBodyImage
          );
        }

        if (!isEmpty(get(data, 'talent.profileImage'))) {
          addFileToEntities(
            data.talent.profileImage.file.fileToken,
            data.talent.profileImage
          );
        }

        if (!isEmpty(get(data, 'talent.fullBodyImages'))) {
          addFilesToEntities(data.talent.fullBodyImages.map(img => img.file));
        }
      })
      .catch(err => {
        handleResponseError(err)
        setEditProfileError(err);
        notify(err?.message, 'error');
      })
      .finally(() => {
        setEditProfileLoading(false);
      });
  }, [t, setEditProfileLoading, setEditProfileData, setEditProfileError]);
}

export function usePostEditProfile(methods) {
  const t = useGetTranslate();

  const setEditProfileData = useSetEditProfile((prev, data) => ({ ...prev, data }));
  const setEditProfileError = useSetEditProfile((prev, error) => ({ ...prev, error }));
  const setEditProfileLoading = useSetEditProfile((prev, loading) => ({ ...prev, loading }));

  const getFullBodyImages = values =>
    uniq([
      ...get(values, 'talent.fullBodyImages', []),
      get(values, 'talent.fullBodyImage'),
    ]);

  return React.useCallback((values, dirtyFields) => {
    const data = submitEditProfileAdapter(values, dirtyFields);
    setEditProfileLoading(true);

    editProfileApi(data)
      //TODO Sergii I removed data from here can you check is it needed here because it's not used
      .then(() => {
        methods.reset({
          ...values,
          talent: {
            ...values.talent,
            fullBodyImages: getFullBodyImages(values),
          },
        })
        notify(t('edit-profile.form.message.success'), 'success')
      })
      .catch(err => {
        setEditProfileError(err);
        notify(err?.message, 'error');
      })
      .finally(() => {
        setEditProfileLoading(false);
      });
  },
  [t, setEditProfileLoading, setEditProfileData, setEditProfileError]);
}

export function useEditDraftProfile() {
  const t = useGetTranslate();
  const navigate = useNavigate();

  return React.useCallback((talent) => {
    editTalentAsDraftApi({ talent })
      .then(() => {
        navigate('/', 'Edit draft successfully');
      })
      .catch((error) => {
        console.warn(error);
      });
  }, [t, navigate]);
}

export function useRegisterProfileFromDraft () {
  const t = useGetTranslate();
  const navigate = useNavigate();

  return React.useCallback((talent) => {
    editProfileApi({ talent })
      .then(() => {
        navigate('/', 'Create draft successfully');
      })
      .catch((error) => {
        console.warn(error);
      });
  }, [t]);
}
