// TODO: refactor component (Formatting, remove Styled, module structure)

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Row, Radio } from '_legacy/common';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const MakeTheMessagePublicField = React.memo(function MakeTheMessagePublicField({ name }) {
  const t = useGetTranslate();

  return (
    <StyledWrapper>
      <Row
        nameBlock={t('request-video-mes-commercial.company.message-public.label')}
        alignItems='center'
        isRequired
        component={
          <div className='radio-wrapper'>
            <Radio
              isRequired
              name={name}
              value='true'
              label='request-video-mes-commercial.company.message-public.yes'
              requiredMessage='request-video-mes-commercial.company.message-public.error'
            />
            <Radio
              isRequired
              name={name}
              value='false'
              label='request-video-mes-commercial.company.message-public.no'
              requiredMessage='request-video-mes-commercial.company.message-public.error'
            />
          </div>
        }
      />
    </StyledWrapper>
  );
});

MakeTheMessagePublicField.propTypes = {
  name: PropTypes.string.isRequired,
};

const StyledWrapper = styled.div`
  .radio-wrapper {
    display: flex;
  }
`;

export default MakeTheMessagePublicField;
