import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import props from 'lodash/fp/props'
import compose from 'lodash/fp/compose'
import propEq from 'lodash/fp/propEq'
import filter from 'lodash/fp/filter'
import cx from 'classnames'

import Icon from 'assets/icons/Icon'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { isFunction } from 'lodash/lang'

const checkItem = compose(propEq('length', 1), filter(Boolean), props(['target', 'onClick']))


const MobileMenuListItem = ({
  slug,
  setMobileMenuActive,
  selected,
  onItemSelect,
  icon,
  name,
  linkProps = [],
  isLink,
  linkTo,
  hasUnreadChatMessages,
  IconComponent
}) => {
  const t = useGetTranslate()
  const classes = cx('mobile-menu__item', {
    opened: selected,
    'unread-messages-indicator': hasUnreadChatMessages,
  })

  const handleClick = () => {
    setMobileMenuActive(false)
    onItemSelect(null)
  }

  return (
    <div className={classes}>
      <div className="mobile-menu__item--heading" onClick={() => onItemSelect(slug)}>
        {IconComponent || <i className={icon} />}
        {isLink ? (
          <Link to={linkTo}><span>{t(name)}</span></Link>
        ) : (
          <p>
            {t(name)}
            <Icon.ArrowDownSelectMenu />
          </p>
        )}
      </div>
      <ul className="mobile-menu__item--body">
        {linkProps.filter(checkItem).map(({ value, target, onClick, onLinkClick }) => (
          <li key={value} onClick={handleClick} className="mobile-menu__item--link">
            {target && (
              <Link
                to={target}
                onClick={() => {
                  if (isFunction(onLinkClick)) onLinkClick()
                }}
              >
                {t(value)}
              </Link>
            )}
            {onClick && (
              <button type="button" onClick={onClick}>
                {t(value)}
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

MobileMenuListItem.propTypes = {
  slug: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  linkProps: PropTypes.array,
  isLink: PropTypes.bool,
  linkTo: PropTypes.string,
  setMobileMenuActive: PropTypes.func,
  selected: PropTypes.bool,
  onItemSelect: PropTypes.func,
}

export default MobileMenuListItem
