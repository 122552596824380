import { api } from 'shared';

import { ReactComponent as LiveVideoChats } from 'assets/offerings-icons/live-video-chats.svg'
import { ReactComponent as InPersonExperience } from 'assets/offerings-icons/in-person-experience.svg'
import { ReactComponent as VideoMessage } from 'assets/offerings-icons/video-message.svg'
import { ReactComponent as Lessons } from 'assets/offerings-icons/lessons.svg'
import { ReactComponent as VideoMessageForCommercialUse } from 'assets/offerings-icons/video-message-for-commercial-use.svg'
import { ReactComponent as ReadyMadeLesson } from 'assets/offerings-icons/ready-made-lesson.svg'
import { ReactComponent as VirtualEvent } from 'assets/offerings-icons/virtual-event.svg'

import * as routes from '_legacy/constants/routes'

import { filters } from '_legacy/components/Table/Filters/config'

const {
  FOR_COMMERCIAL_USE, IN_PERSON_EXPERIENCE, LIVE_IN_PERSON_LESSON, READY_MADE_LESSON,
  VIDEO_CHAT, VIDEO_MESSAGE, VIDEO_VOICE_OVER_LESSON, VIRTUAL_EVENT, VIRTUAL_LESSON
} = api.talents.talent.offers.query.TypeFilter
export const offeringsTypesDraft = {
  IN_PERSON_EXPERIENCE_DRAFT: {
    label: 'table.filters.offerings-types.in-person-experience',
    Icon: InPersonExperience,
    subText: 'table.filters.offerings-types.in-person-experience',
    href: `/${routes.OFFERINGS}/${routes.EXPERIENCES}`,
  },
}
export const offeringsTypes = {
  [IN_PERSON_EXPERIENCE]: {
    label: 'table.filters.offerings-types.in-person-experience',
    Icon: InPersonExperience,
    subText: 'table.filters.offerings-types.in-person-experience',
    href: `/${routes.OFFERINGS}/${routes.EXPERIENCES}/${routes.RECURRING}/${routes.LIST}`,
  },
  [VIDEO_MESSAGE]: {
    label: 'table.filters.offerings-types.video-message',
    Icon: VideoMessage,
    subText: 'table.filters.offerings-types.video-message',
  },
  [VIDEO_CHAT]: {
    label: 'table.filters.offerings-types.live-video-chat',
    Icon: LiveVideoChats,
    subText: 'table.filters.offerings-types.live-video-chat',
  },
  [VIDEO_VOICE_OVER_LESSON]: {
    label: 'table.filters.offerings-types.video-voice-over-lesson',
    Icon: Lessons,
    subText: 'table.filters.offerings-types.lessons',
  },
  [VIRTUAL_LESSON]: {
    label: 'table.filters.offerings-types.virtual-lesson',
    Icon: Lessons,
    subText: 'table.filters.offerings-types.lessons',
  },
  [LIVE_IN_PERSON_LESSON]: {
    label: 'table.filters.offerings-types.live-in-person-lesson',
    Icon: Lessons,
    subText: 'table.filters.offerings-types.lessons',
  },
  [VIRTUAL_EVENT]: {
    label: 'table.filters.offerings-types.virtual-event',
    Icon: VirtualEvent,
    subText: 'table.filters.offerings-types.virtual-event',
    href: `/${routes.OFFERINGS}/${routes.LIVE_VIRTUAL_EVENTS}/${routes.RECURRING}/${routes.LIST}`,
  },
  [READY_MADE_LESSON]: {
    label: 'table.filters.offerings-types.ready-made-lesson',
    Icon: ReadyMadeLesson,
    subText: 'table.filters.offerings-types.ready-made-lesson',
  },
  [FOR_COMMERCIAL_USE]: {
    label: 'table.filters.offerings-types.video-message-for-commercial-use',
    Icon: VideoMessageForCommercialUse,
    subText: 'table.filters.offerings-types.video-message-for-commercial-use',
  },
}
const offeringsTypesOptions = Object.keys(offeringsTypes).map(key => ({ value: key, label: offeringsTypes[key].label }))

const { DRAFT, PUBLISHED, UNPUBLISHED } = api.talents.talent.offers.query.StatusFilter;
export const statuses = {
  [DRAFT]: 'table.filters.statuses.draft',
  [PUBLISHED]: 'table.filters.statuses.published',
  [UNPUBLISHED]: 'table.filters.statuses.unpublished'
};
const statusOptions = Object.keys(statuses).map(key => ({ value: key, label: statuses[key] }))

const { MULTIPLE_OCCURRENCES, ONE_TIME } = api.talents.talent.offers.query.ScheduleTimeFilter;
export const scheduleType = {
  [MULTIPLE_OCCURRENCES]: 'table.filters.schedule-types.multiple-occurrences',
  [ONE_TIME]: 'table.filters.schedule-types.one-time'
};
const scheduleTypeOptions = Object.keys(scheduleType).map(key => ({ value: key, label: scheduleType[key] }))

export const filtersConfig = [
  {
    type: filters.search,
    placeholder: 'table.filters.search',
    field: 'nameLike'
  },
  {
    type: filters.select,
    placeholder: 'table.filters.offering-type',
    field: 'typeEq',
    options: offeringsTypesOptions
  },
  {
    type: filters.select,
    placeholder: 'table.filters.status',
    field: 'statusEq',
    options: statusOptions
  },
  {
    type: filters.select,
    placeholder: 'table.filters.schedule-type',
    field: 'scheduleTypeEq',
    options: scheduleTypeOptions
  }
]

export const counterConfig = {
  field: 'count',
  label: 'table.filters.offering',
  pluralLabel: 'table.filters.offerings'
}

const { DATE, LOCATION, PRICE, STATUS, TITLE } = api.talents.talent.offers.query.SortField;

const sortFields = {
  [TITLE]: 'table.header.offering',
  [DATE]: 'table.header.date-time',
  [LOCATION]: 'table.header.location',
  [PRICE]: 'table.header.price',
  [STATUS]: 'table.header.status'
};
const sortFieldOptions = Object.keys(sortFields).map(key => ({ value: key, label: sortFields[key] }))

export const subFiltersConfig = [
  {
    label: 'table.filters.by',
    field: 'sortField',
    options: sortFieldOptions
  },
  {
    label: 'table.filters.show',
    field: 'limit',
    options: [{ value: 10, label: '10' }, { value: 25, label: '25' }, { value: 50, label: '50' }]
  }
]

const additionalFields = [
  'sortOrder'
]

export const fields = [
  ...filtersConfig.map(({ field }) => field),
  ...subFiltersConfig.map(({ field }) => field),
  ...additionalFields
]
