export const sharedErrors = {
  ['PHONE_IS_NOT_UNIQUE']: 'shared.form.phone.errors.not-unique',
  ['PHONE_KEY_IS_NOT_VALID']: 'shared.form.phone.errors.not-valid',
  ['BAD_ANSWER']: 'shared.form.phone.errors.bad-answer',
  ['ALREADY_USE']: 'shared.form.phone.errors.already-use',
  ['INVALID_PHONE']: 'shared.form.phone.errors.invalid-phone',
  ['PHONE_IS_NOT_VALID']: 'shared.form.phone.errors.invalid-phone',
  ['ALREADY_SENT']: 'shared.form.phone.errors.already-sent',
  ['CONFIRM_PHONE']: 'shared.form.phone.errors.confirm-phone',
  ['CONFIRM_CODE']: 'shared.form.phone.errors.confirm-code',
  ['PHONE_SEND']: 'shared.form.phone.errors.phone-send',
  ['CODE_CONFIRM']: 'shared.form.phone.errors.confirm-code',
};
