import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual'

import { useUser } from 'store/hooks/globalState/useUser'
import { useCurrentPage } from 'store/hooks/globalState/useUtils'

import { sanitizeFilterFields } from '../utils'
import paginateLinkBuilder from 'utils/paginateLinkBuilder'


export default function TableLoader ({
  fields, getFiltersFn, fetchTableFn, isMobile, shouldHandleUser = true
}) {
  const user = useUser()
  const history = useHistory()
  const page = useCurrentPage()
  const fetchTableData = fetchTableFn({ isMobile })
  const filtersOptions = getFiltersFn(current => sanitizeFilterFields({ current, fields }), [fields])

  const prevUser = React.useRef(user)
  const prevFiltersOptions = React.useRef(filtersOptions)

  const deps = shouldHandleUser
    ? [filtersOptions, prevFiltersOptions, page, history, user]
    : [filtersOptions, prevFiltersOptions, page, history]

  React.useEffect(() => {
    if (page !== 1 && (!isEqual(prevFiltersOptions.current, filtersOptions) || !isEqual(prevUser.current, user))) {
      history.replace(paginateLinkBuilder(1))
    } else {
      fetchTableData({ ...filtersOptions, page }, user)
    }
    prevUser.current = user
    prevFiltersOptions.current = filtersOptions
  }, deps)

  return null
}

TableLoader.propTypes = {
  fields: PropTypes.array,
  getFiltersFn: PropTypes.func.isRequired,
  fetchTableFn: PropTypes.func.isRequired,
  shouldHandleUser: PropTypes.bool
}
