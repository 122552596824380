import React from 'react';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import PublicSlider from '_legacy/components/PublicSlider/'

import { useCommercialEngagements } from 'store/hooks/globalState/useCommercialEngagements';

const CommercialSlider = () => {
  const commercialEngagements = useCommercialEngagements();
  const media = get(commercialEngagements, 'commercialVideoMessage.media', []);

  const sortMedia = sortBy(media, ({ isPrimary }) => !isPrimary)

  return <PublicSlider covers={sortMedia} />
};


export default CommercialSlider;
