import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useNavigate, useSetShouldNavigate } from 'store/hooks/globalState/useSwitchNavigation'
import { useSetModal, useStateModal } from 'store/hooks/globalState/useModal'

import * as r from '_legacy/constants/routes'
import { PROMOTIONAL_MEDIA_LIBRARY_MODAL } from '_legacy/components/Modal/modals-types'

import Button from '_legacy/components/Button'
import ModalPortal from 'portals/ModalPortal'

const PromotionalMediaAskModal = ({ withoutRedirect }) => {
  const t = useGetTranslate()
  const navigate = useNavigate()
  const setShouldNavigate = useSetShouldNavigate()
  const closeModal = useSetModal(() => null)
  const openLibraryModal = useStateModal(PROMOTIONAL_MEDIA_LIBRARY_MODAL)

  const onConfirm = () => {
    openLibraryModal()
  }

  const onCancel = () => {
    if (!withoutRedirect) {
      navigate(`/${r.OFFERINGS}`)
    }
    closeModal()
  }

  React.useEffect(() => {
    setShouldNavigate(true)
  }, [])

  return (
    <ModalPortal isOpen onClose={onCancel} showCloseButton isMobileFullScreen>
      <ModalWrapper className="check-email-modal">
        <h2 className="check-email-modal__header">
          {t('promotional-media.ask-modal.message')}
        </h2>

        <p className="check-email-modal__desc">
          <Trans tKey='promotional-media.ask-modal.description' />
        </p>

        <ButtonContainer>
          <Button
            stretch
            primary
            text={t('promotional-media.ask-modal.cancel-button')}
            typeButton="button"
            handleOnClick={onCancel}
            paddingVertical={13}
          />
          <Button
            stretch
            text={t('promotional-media.ask-modal.confirm-button')}
            typeButton="button"
            handleOnClick={onConfirm}
            paddingVertical={13}
          />
        </ButtonContainer>
      </ModalWrapper>
    </ModalPortal>
  )
}

const ModalWrapper = styled.div`
  min-width: 320px;
  width: 660px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 15px 40px;
  border-radius: 4px;
  @media screen and (max-width: 1280px) {
    width: 628px;
    padding: 16px 24px;
  }
  @media screen and (max-width: 768px) {
    width: 550px;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }
  .check-email-modal {
    &__header {
      font-size: 32px;
      margin-bottom: 24px;
      @media screen and (max-width: 1280px) {
        font-size: 28px;
      }
      @media screen and (max-width: 575px) {
        margin-top: auto;
      }
    }
    &__desc {
      margin-top: 0;
      margin-bottom: 32px;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.625;
      color: ${({ theme }) => theme.colors.grey};
      @media screen and (max-width: 575px) {
        text-align: center;
      }
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  @media screen and (max-width: 575px) {
    margin-top: auto;
  }
  .button:not(:last-of-type) {
    margin-right: 16px;
  }

  @media screen and (max-width: 575px) {
    flex-direction: column-reverse;
    width: 100%;

    .button:not(:last-of-type) {
      margin-right: 0;
      margin-top: 16px;
    }
  }
`

PromotionalMediaAskModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
}

export default PromotionalMediaAskModal
