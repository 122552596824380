import React from 'react'

// Utils
import init from 'init'

// Components
import Routers from 'router'
import NoConnection from 'components/NoConnection'


const App = () => {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine)

  const onOnlineCallback = () => setIsOnline(true)
  const onOfflineCallback = () => setIsOnline(false)

  React.useEffect(() => {
    window.addEventListener('online', onOnlineCallback)
    window.addEventListener('offline', onOfflineCallback)

    return () => {
      window.removeEventListener('online', onOnlineCallback)
      window.removeEventListener('offline', onOfflineCallback)
    }
  })

  if(isOnline) {
    const AppInstance = init(Routers)
    return <AppInstance />
  } else {
    return <NoConnection />
  }
}

export default App
