import React from 'react';
import { useLocation } from 'react-router-dom';
import { PromotionalMediaType } from 'shared';
import get from 'lodash/get';

import * as r from '_legacy/constants/routes';
import { notify } from 'utils/notify';
import { setToPromotionalMedia as setToPromotionalMediaApi } from 'api/appApi/talent/promotionalMedia'

import { useTalentId } from 'store/hooks/globalState/useUser';
import { useNavigate } from 'store/hooks/globalState/useSwitchNavigation';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useSetModal } from 'store/hooks/globalState/useModal';


const config = {
  [`/${r.OFFERINGS}/${r.LESSONS}`]: {
    type: PromotionalMediaType.LESSONS_PROMO,
    message: 'promotional-media.notify.success.lessons',
  },
  [`/${r.OFFERINGS}/${r.VIDEO_CHAT}/${r.NEW}`]: {
    type: PromotionalMediaType.VIDEO_CHAT_PROMO,
    message: 'promotional-media.notify.success.video-chat',
  },
  [`/${r.OFFERINGS}/${r.VIDEO_MESSAGE}/${r.NEW}`]: {
    type: PromotionalMediaType.VIDEO_MESSAGE_PROMO,
    message: 'promotional-media.notify.success.video-message',
  },
  [`/${r.OFFERINGS}/${r.EXPERIENCES}/${r.NEW}`]: {
    type: PromotionalMediaType.IN_PERSON_EXPERIENCE_PROMO,
    message: 'promotional-media.notify.success.experience',
  },
  [`/${r.OFFERINGS}/${r.LIVE_VIRTUAL_EVENTS}/${r.NEW}`]: {
    type: PromotionalMediaType.LIVE_VIRTUAL_EVENT_PROMO,
    message: 'promotional-media.notify.success.live-virtual-event',
  },
  [`/${r.OFFERINGS}/${r.MASTER_CLASS}/${r.NEW}`]: {
    type: PromotionalMediaType.LIVE_VIRTUAL_EVENT_PROMO,
    message: 'promotional-media.notify.success.live-virtual-event',
  },
  [`/${r.OFFERINGS}/${r.READY_MADE_EVENT_LESSON}/${r.NEW}`]: {
    type: PromotionalMediaType.READY_MADE_LESSON_PROMO,
    message: 'promotional-media.notify.success.ready-made-lessons',
  },
};


export function useCreatePromotionalMedia() {
  const { pathname } = useLocation();
  const t = useGetTranslate();
  const talentId = useTalentId();
  const { type, message } = get(config, pathname);
  const navigate = useNavigate();
  const closeModal = useSetModal(() => null);

  return React.useCallback(fileToken => {
    setToPromotionalMediaApi({ promotionalMediaTypes: [type], fileToken })
      .then(() => {
        closeModal();
        if (type !== PromotionalMediaType.LESSONS_PROMO) {
          navigate(`/${r.OFFERINGS}`);
        }
        notify(t(message), 'success');
      });
  }, [talentId]);
}
