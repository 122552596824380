import React from 'react'
import { types } from 'shared'
import { isEmpty } from 'lodash/fp'
import { FormProvider, useForm } from 'react-hook-form'

import { useModal } from 'store/hooks/useModal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { getTimezoneAbbr, parseDate } from 'utils/services/timezone'
import { getMonthDayYearDate, getTimeRange } from 'utils/date'
import { getLocationTimeZone } from 'utils/location'
import { buildLocations, buildOccurrences, fillSelectedLocation, getJoinButtonKey, getProposeButtonKey } from '../utils'

import JoinModal from '_legacy/components/PublicView/JoinModal'
import PublicInfoForm from '_legacy/components/PublicView/PublicInfoForm'
import SelectFormItem from '_legacy/components/PublicView/SelectFormItem'
import PublicFormItem from '_legacy/components/PublicView/PublicFlexibleFormItemContext'
import PublicFixedFormItem from '_legacy/components/PublicView/PublicFixedFormItem'
import { useBookExperience, useBookOccurrence } from 'requests/public-views/experience/experience-booking'
import BookingCalendar from 'components/Calendars/BookingCalendar'

const { FLEXIBLE } = types.experiences.TypesOfLocation

const SELECT_KEYS = {
  JOIN: 'JOIN',
  PROPOSE: 'PROPOSE',
}


const AnyAvailableDayTime = React.memo(({ experience }) => {
  const { occurrencesLimit, id, maxNumberOfParticipants, locations, pricePerParticipant, typesOfLocation, duration, title, minBookingTimeBeforeExperience, travelTime, talent } = experience
  const isFlexibleTypeOfLocation = React.useMemo(() => typesOfLocation === FLEXIBLE, [])

  const t = useGetTranslate()
  const methods = useForm({ shouldUnregister: false })

  const [isSubmitting, setIsSubmitting] = React.useState()
  const [selectedOccurrence, setSelectedOccurrence] = React.useState(null)
  const bookExperience = useBookExperience(setIsSubmitting)
  const bookOccurrence = useBookOccurrence(setIsSubmitting)

  // ****** modal state ******
  const [isJoinModalOpen, openJoinModal, closeJoinModal] = useModal(false)
  const [isTalentCalendarModalOpened, openTalentCalendarModal, closeTalentCalendarModal] = useModal(false)

  // ****** form state ******
  const watchedLocation = methods.watch('location') || locations?.[0]?.id

  const { availableOccurrences, locationTimeZone, locationTimeZoneAbbr, locationOptions, participantTitle, selectedRange, dateTimeTitle, dateTimeSubTitle } = React.useMemo(() => {
    const availableOccurrences = buildOccurrences(experience)

    // ****** location options ******
    const filledLocations = selectedOccurrence?.bookedLocation
      ? fillSelectedLocation([selectedOccurrence?.bookedLocation], locations)
      : locations
    const locationOptions = buildLocations(filledLocations)
    const selectedLocation = locations.find(location => location.id === watchedLocation)
    const locationTimeZone = getLocationTimeZone(selectedLocation)
    const locationTimeZoneAbbr = getTimezoneAbbr(locationTimeZone)

    // ****** time label ******
    const selectedStartTime = parseDate(selectedOccurrence?.start, locationTimeZone)
    const selectedEndTime = parseDate(selectedOccurrence?.end, locationTimeZone)
    const dateTimeTitle =  selectedOccurrence
      ? getMonthDayYearDate(selectedStartTime)
      : t('experience-public-view.select.choose')
    const dateTimeSubTitle = selectedOccurrence
      ? getTimeRange([selectedStartTime, selectedEndTime])
      : ''

    // ****** participant label ******
    const participantTitle = selectedOccurrence
      ? `${selectedOccurrence.participantsCount} of ${maxNumberOfParticipants}`
      : '-'

    return {
      availableOccurrences,
      locationTimeZoneAbbr,
      locationOptions,
      participantTitle,
      selectedRange,
      dateTimeTitle,
      dateTimeSubTitle,
      locationTimeZone,
    }
  }, [t, experience, watchedLocation, selectedOccurrence])


  const dateTimeOptions = React.useMemo(() => [
    { value: SELECT_KEYS.JOIN, label: t(getJoinButtonKey(experience)), disabled: isEmpty(availableOccurrences) },
    { value: SELECT_KEYS.PROPOSE, label: t(getProposeButtonKey(experience)), disabled: availableOccurrences.length === occurrencesLimit },
  ], [t, occurrencesLimit, availableOccurrences, experience])


  // ****** handlers ******
  const customOnChange = React.useCallback((value) => {
    if (value === SELECT_KEYS.JOIN) {
      openJoinModal()
    }

    if (value === SELECT_KEYS.PROPOSE) {
      openTalentCalendarModal()
    }
  }, [])

  const onCalendarConfirm = React.useCallback(({ start, end }) => {
    setSelectedOccurrence({ start, end, participantsCount: 0, maxNumberOfParticipants })
  }, [])

  // ****** submit ******
  const onSubmit = React.useCallback(() => {
    if (selectedOccurrence.id) {
      bookOccurrence({
        offerExperienceOccurrenceId: selectedOccurrence.id,
        location: watchedLocation,
        includedFriends: [],
        invitedFriends: []
      })
    } else {
      bookExperience({
        experienceId: id,
        startTime: selectedOccurrence.start,
        endTime: selectedOccurrence.end,
        location: watchedLocation,
        includedFriends: [],
        invitedFriends: []
      })
    }
  }, [selectedOccurrence, watchedLocation])

  return (
    <FormProvider {...methods}>
      <PublicInfoForm
        isSubmitting={isSubmitting}
        onSubmit={methods.handleSubmit(onSubmit)}
        pricePerParticipant={pricePerParticipant}
        buttonProps={{ disabled: isEmpty(selectedOccurrence) }}
      >
        <h3>{t('experience.info.heading')}</h3>
        <p className="public-view__timezone-reminder">
          {t('experience.info.timezone-reminder-location')}:<div>{locationTimeZoneAbbr}</div>
        </p>

        <div className="public-view__info-list">

          {/*date select*/}
          <SelectFormItem
            header={t('experience-public-view.select.choose')}
            options={dateTimeOptions}
            customTitle={dateTimeTitle}
            subtitle={dateTimeSubTitle}
            onChange={customOnChange}
          />

          {/*location*/}
          {!isEmpty(locationOptions) && isFlexibleTypeOfLocation && (
            <PublicFormItem
              name="location"
              title={t('experience.info.location')}
              items={locationOptions}
            />
          )}

          {/*participant label*/}
          <PublicFixedFormItem
            title={t('experience.info.current-participant')}
            fixedItem={participantTitle}
          />
        </div>
      </PublicInfoForm>

      <JoinModal
        isOpen={isJoinModalOpen}
        onClose={closeJoinModal}
        list={availableOccurrences}
        joinHandler={setSelectedOccurrence}
      />

      <BookingCalendar
        isOpen={isTalentCalendarModalOpened}
        onClose={closeTalentCalendarModal}
        timeZone={locationTimeZone}
        travelTime={travelTime}
        minBookingTimeBeforeExperience={minBookingTimeBeforeExperience}
        eventTitle={title}
        eventDuration={duration}
        onConfirm={onCalendarConfirm}
        orderSlot={selectedOccurrence}
        setOrderSlot={setSelectedOccurrence}
        talentId={talent.id}
      />
    </FormProvider>
  )
})

export default AnyAvailableDayTime
