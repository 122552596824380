import React from 'react'
import styled from 'styled-components'
import compose from 'lodash/fp/compose'
import isEmpty from 'lodash/isEmpty'
import { useForm } from 'react-hook-form'
import { types } from 'shared'
const { PHOTO, VIDEO } = types.file.Types

import { OFFERINGS } from '_legacy/constants/routes';

import { useSetPathToNavigate } from 'store/hooks/globalState/useSwitchNavigation';
import { useTalentLibraryId } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import {
  useCreateReadyMadeEvent,
  useCreateReadyMadeEventDraft,
} from 'requests/ready-made-video-events'
import { useFetchFileLibrary } from 'requests/file-library'

import { Container, Title } from '_legacy/common'
import ReadyMadeEventForm from '_legacy/components/FormReadyMadeEvent/ReadyMadeEventForm'

import submitAdapter from '_legacy/components/FormReadyMadeEvent/submitAdapter'
import defaultValues from '_legacy/components/FormReadyMadeEvent/defaultValues'
import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler'

const CreateReadyMadeEvents = () => {
  useSetPathToNavigate({ talentRoute: `/${OFFERINGS}`, agentRoute: '/' })

  const t = useGetTranslate()
  const fileLibraryId = useTalentLibraryId()
  const fetchFileLibrary = useFetchFileLibrary()
  const createReadyMadeEvent = useCreateReadyMadeEvent()
  const createReadyMadeEventDraft = useCreateReadyMadeEventDraft()

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    shouldUnregister: false,
  })

  // fetch file library
  React.useEffect(() => {
    fetchFileLibrary({ fileLibraryId, types: [PHOTO, VIDEO] })
  }, [fileLibraryId])

  const onCreate = compose(createReadyMadeEvent, submitAdapter)
  const onDraft = compose(createReadyMadeEventDraft, submitAdapter, methods.getValues)

  return (
    <>
      <ChangeRouteHandler
        isDraft={true}
        shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
        onDraft={onDraft}
      />
      <StyledWrapper>
        <Container>
          <Title>{t('offerings.ready-made.title')}</Title>
          <h3 className="subtitle">{t('offerings.ready-made.subtitle')}</h3>
          <ReadyMadeEventForm
            methods={methods}
            onDraft={onDraft}
            onCreate={onCreate}
          />
        </Container>
      </StyledWrapper>
    </>
  )
}

const StyledWrapper = styled.div`
  margin-top: 30px;
  .subtitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 1.5;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.grey};
    margin: 40px 0;
    @media only screen and (max-width: 575px) {
      font-size: 18px;
      margin: 20px 0;
    }
  }
`

export default CreateReadyMadeEvents
