import React from 'react'
import prop from 'lodash/fp/prop'
import PropTypes from 'prop-types'

import { Row } from '_legacy/common'
import PaymentsSelect from '_legacy/components/PaymentsSelect'
import PaymentSettings from '_legacy/components/PaymentSettings'
import AccountTemplate from '_legacy/containers/Account/AccountTemplate'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { usePaymentCards } from 'store/hooks/globalState/usePaymentCards'
import { useFetchPaymentCard } from 'requests/payments'
import { isAficionado, isAgentWithTalent } from 'utils/user'

const Payments = ({ user }) => {
  const t = useGetTranslate()
  const paymentCards = usePaymentCards()
  const fetchPaymentCard = useFetchPaymentCard()
  const isStripeAccountReady = prop('talent.isStripeAccountReady', user)
  const isUserAficionado = isAficionado(user)
  const agentWithTalent = isAgentWithTalent(user)

  const withdrawalAccountTitle = isStripeAccountReady
    ? t('account.menu.payments.settings.label')
    : t('account.menu.payments.setup.label')

  React.useEffect(() => fetchPaymentCard(), [])

  return (
    <AccountTemplate title="account.menu.payments.title">
      {!isUserAficionado && (
        <Row
          className="account-setting--row"
          nameBlock={withdrawalAccountTitle}
          component={<PaymentSettings isStripeAccountReady={isStripeAccountReady} />}
        />
      )}
      {!agentWithTalent && (
        <Row
          className="account-setting--row"
          nameBlock={t('account.menu.payments.methods.label')}
          component={<PaymentsSelect listCard={paymentCards} placeholder="account.menu.payments.methods.placeholder" />}
        />
      )}
    </AccountTemplate>
  )
}

Payments.propTypes = {
  user: PropTypes.shape({
    talent: PropTypes.shape({
      isStripeAccountReady: PropTypes.bool,
    }),
  }),
}

export default Payments
