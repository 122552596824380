import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// hooks
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

// components
import PasswordErrorListItem from './PasswordErrorListItem';

const PasswordErrorList = ({ errors, isFocused, isDirty, isTouched, errorsConfig }) => {
  const t = useGetTranslate();
  return (
    <StyledPasswordErrorsList>
      {errorsConfig.map(error => (
        <PasswordErrorListItem
          key={error.key}
          isDirty={isDirty}
          isError={errors?.[error.key]}
          isFocused={isFocused}
          isTouched={isTouched}
        >
          {t(error.message)}
        </PasswordErrorListItem>
      ))}
    </StyledPasswordErrorsList>
  );
};

PasswordErrorList.propTypes = {
  errors: PropTypes.object,
  isFocused: PropTypes.bool,
  isDirty: PropTypes.bool,
  isTouched: PropTypes.bool,
  errorsConfig: PropTypes.array,
};

const StyledPasswordErrorsList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 40px;
  padding-left: 20px;
  list-style: disc;
  margin: 20px 0;
`;

export default PasswordErrorList
