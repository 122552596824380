// TODO: refactor component (Formatting, remove Styled, module structure)

import React from 'react';
import PropTypes from 'prop-types';

import { Row } from '_legacy/common';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import LegalTermsList from './LegalTermsList';

const LegalTermsBlock = ({ className = '' }) => {
  const t = useGetTranslate();

  return (
    <Row
      nameBlock={t('Terms:')}
      className={className}
      component={<LegalTermsList />}
    />
  );
};

LegalTermsBlock.propTypes = {
  className: PropTypes.string,
};

export default LegalTermsBlock;
