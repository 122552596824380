import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import DatePicker from '_legacy/common/DatePicker/DatePicker';
import TimeSelector from '../Time/TimeSelector';

const WindowItem = ({
  filterTime,
  datePickerName,
  startTimeName,
  endTimeName,
  datePickerDefaultValue,
  startTimeDefaultValue,
  endTimeDefaultValue,
}) => {
  const t = useGetTranslate();
  const { watch } = useFormContext();
  const date = watch(datePickerName);

  return (
    <TimeWindowWrapper>
      <DatePicker
        minDate={Date.now()}
        isRequired
        placeholder={t('create-experience.form.time-window-date.label')}
        defaultValue={datePickerDefaultValue}
        name={datePickerName}
      />
      <TimeSelector
        filterTime={filterTime}
        selectedDate={date}
        startTimeDefaultValue={startTimeDefaultValue}
        endTimeDefaultValue={endTimeDefaultValue}
        startTimeName={startTimeName}
        endTimeName={endTimeName}
      />
    </TimeWindowWrapper>
  );
};

const TimeWindowWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`;

WindowItem.propTypes = {
  datePickerName: PropTypes.object,
  startTimeName: PropTypes.object,
  endTimeName: PropTypes.object,
  datePickerDefaultValue: PropTypes.object,
  startTimeDefaultValue: PropTypes.object,
  endTimeDefaultValue: PropTypes.object,
  filterTime: PropTypes.func
};

export default WindowItem;
