import React from 'react'
import PropTypes from 'prop-types'
import { addYears, differenceInDays } from 'date-fns'

import { Row } from '_legacy/common'
import DatePicker from '_legacy/common/DatePicker/DatePicker'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const DateOfExperience = ({ name = 'dateOfExperience', isRequired = true }) => {
  const t = useGetTranslate()

  return (
    <Row
      nameBlock={t('create-experience.form.date-of-experience.label')}
      component={
        <DatePicker
          isRequired={isRequired}
          name={name}
          placeholder={t('create-experience.form.date-of-experience.placeholder')}
          minDate={Date.now()}
          maxDate={addYears(new Date(), 1)}
          rules={{
            required: isRequired && 'create-experience.form.date-of-experience.errors.required',
            validate: date => differenceInDays(new Date(date), new Date()) >= 0 || 'shared.words.invalid-date',
          }}
        />
      }
    />
  )
}

DateOfExperience.propTypes = {
  name: PropTypes.string,
  isRequired: PropTypes.bool,
}

export default DateOfExperience
