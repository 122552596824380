import React from 'react'
import { Link } from 'react-router-dom'

import * as r from '_legacy/constants/routes'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import PopperTooltip from '_legacy/components/Tooltip/PopperTooltip'
import Loader from '_legacy/components/Table/table-components/types/common/Loader'

import './index.scss'

const Offering = React.memo(({ isLoading, id, type, title, config, status }) => {
  const t = useGetTranslate()
  if (isLoading) {
    return (
      <div className='loaders'>
        <Loader className='loader' />
        <Loader className='loader' />
      </div>
    )
  }
  if (!config) {
    console.warn(`Offering: ${type} not implemented`)
    return null
  }
  const { Icon, subText, href } = config
  const Component = props => (href ? <Link {...props} /> : <span {...props} />)

  const formattedHref = status === 'DRAFT' ? `${href}/${id}/${r.DRAFT}` : `${href}/${id}`

  return (
    <Component to={formattedHref} className='table-component-offering'>
      <PopperTooltip Component={title}>
        <div className='header'>{title}</div>
      </PopperTooltip>
      <div className='category'>
        <Icon className='icon' />
        <span className='text'>
          {t(subText)}
        </span>
      </div>
    </Component>
  )
})

export default Offering
