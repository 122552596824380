import React from 'react'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useClearSearchFilters } from 'store/hooks/globalState/useSearchList'
import Button from '_legacy/components/Button'

import * as r from '_legacy/constants/routes'

const EmptyTalentList = () => {
  const t = useGetTranslate()
  const clearFilters = useClearSearchFilters()

  return (
    <div className="empty-list">
      <h3>{t('search.list.talent.not-found.header')}</h3>
      <p>{t('search.list.talent.not-found.content')}</p>
      <Button
        isLink
        path={`/${r.SEARCH}/${r.TALENT}`}
        text={t('search.list.talent.not-found.button')}
        handleOnClick={clearFilters}
      />
    </div>
  )
}

export default EmptyTalentList
