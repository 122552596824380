import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useFormContext } from 'react-hook-form';
import { Draggable } from 'react-beautiful-dnd';
import get from 'lodash/get'

import Icon from 'assets/icons/Icon';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const BiographyItem = ({
  idx,
  item,
  move,
  remove,
  isDownButtonDisable,
  name,
  isRemoveButtonDisable,
}) => {
  const t = useGetTranslate();
  const { register, watch, errors } = useFormContext();
  const error = get(errors, name)

  return (
    <Draggable draggableId={`item-${idx}`} index={idx}>
      {(provided) => (
        <li
          key={item.id}
          className="biography-item"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="biography-header">
            <h3
              {...provided.dragHandleProps}
              className="biography-header__logo"
            >
              <Icon.DragIndicator />&nbsp;
              <span>{t('edit-profile.form.biographies.logo')}</span>&nbsp;
              {idx + 1}
            </h3>
            <div className="biography-header__controls">
              <button
                type='button'
                disabled={isDownButtonDisable}
                onClick={() => move(idx, idx + 1)}
              >
                <Icon.ArrowDragDown />
              </button>
              <button
                type='button'
                className="biography-header__up-button"
                disabled={idx === 0}
                onClick={() => move(idx, idx - 1)}
              >
                <Icon.ArrowDragUp />
              </button>
              <button
                type='button'
                className="biography-header__remove-button"
                disabled={isRemoveButtonDisable}
                onClick={() => remove(idx)}
              >
                <Icon.DragRemove />
              </button>
            </div>
          </div>
          <div className="biography-inputs">
            <div className="biography-inputs__item">
              <input
                className={cx(
                  'experience-input',
                  { error: error?.header }
                )}
                ref={register({ maxLength: 7 })}
                type="text"
                name={`${name}.header`}
                defaultValue={item.header}
                placeholder={t(
                  'edit-profile.form.biographies.header.placeholder'
                )}
              />
              <p className="char-tip">
                {watch(`${name}.header`, '').length}&nbsp;
                / 7 {t('shared.words.character.plural')}
              </p>
            </div>
            <div className="biography-inputs__item">
              <input
                className={cx(
                  'experience-input',
                  { error: error?.subheader }
                )}
                ref={register({ maxLength: 40 })}
                type="text"
                name={`${name}.subheader`}
                defaultValue={item.subheader}
                placeholder={t(
                  'edit-profile.form.biographies.subheader.placeholder'
                )}
              />
              <p className="char-tip">
                {watch(`${name}.subheader`, '').length}&nbsp;
                / 40 {t('shared.words.character.plural')}
              </p>
            </div>
          </div>
          <div className="biography-textarea-wrapper">
            <textarea
              className={cx(
                'biography-textarea',
                { error: error?.description }
              )}
              ref={register({ maxLength: 70 })}
              name={`${name}.description`}
              defaultValue={item.description}
              placeholder={t(
                'edit-profile.form.biographies.description.placeholder'
              )}
            />
            <p className="char-tip">
              {watch(`${name}.description`, '').length}&nbsp;
              / 70 {t('shared.words.character.plural')}
            </p>
          </div>
        </li>
      )}
    </Draggable>
  );
};

BiographyItem.propTypes = {
  idx: PropTypes.objectOf([
    PropTypes.string, PropTypes.number
  ]),
  item: PropTypes.object,
  move: PropTypes.func,
  remove: PropTypes.func,
  register: PropTypes.func,
  watch: PropTypes.func,
  isDownButtonDisable: PropTypes.bool,
  isRemoveButtonDisable: PropTypes.bool,
  name: PropTypes.string,
  error: PropTypes.object,
};

export default BiographyItem;
