import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import compose from 'lodash/fp/compose'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useCreateExperience,
  useCreateExperienceDraft,
} from 'requests/offerings/experience'

import CreateExperienceForm from '_legacy/components/CreateExperience'
import OfferingTitle from '_legacy/containers/Offerings/components/OfferingTitle'
import OfferingTemplate from '_legacy/containers/Offerings/components/OfferingTemplate'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'

import { createExperienceAdapter } from '_legacy/components/CreateExperience/utils/createExperienceAdapter'
import { getDefaultValues } from '_legacy/components/CreateExperience/utils/defaultValues'

const CreateExperience = () => {
  const t = useGetTranslate()

  const [isLoading, setIsLoading] = useState(false)

  const methods = useForm({
    defaultValues: getDefaultValues(t),
    mode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: false,
    shouldUnregister: false,
  })


  const createExperience = useCreateExperience(setIsLoading, methods)
  const createExperienceDraft = useCreateExperienceDraft()

  const onSubmit = compose(
    createExperience,
    createExperienceAdapter,
  )

  const onDraft = compose(
    createExperienceDraft,
    createExperienceAdapter,
    methods.getValues
  )

  return (
    <OfferingTemplate>
      <OfferingTitle>{t('create-experience.header.create')}</OfferingTitle>
      <CreateExperienceForm
        methods={methods}
        onSubmit={onSubmit}
        onDraft={onDraft}
        isLoading={isLoading}
      />
    </OfferingTemplate>
  )
}

export default withLibrary(CreateExperience)
