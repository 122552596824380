import React from 'react';
import { init } from 'onfido-sdk-ui'
import { debounce, get } from 'lodash';
import { notify } from 'utils/notify';

import { createApplicant } from './state/api'

import { useCheckOnfidoApplicant, useGetSDKToken } from './state/request'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useFormContext } from 'react-hook-form';
import {
  useOnfidoToken,
  useOnfioCheckResult,
} from './state/store'

import { Row } from '_legacy/common';
import Button from '_legacy/components/Button';
import { ErrorMessage } from '_legacy/common/ErrorMessage'

import { sharedErrors } from './utils';

const IDVerification = () => {
  const t = useGetTranslate();
  const token = useOnfidoToken()
  const checkApplicant = useCheckOnfidoApplicant()
  const checkResult = useOnfioCheckResult()
  const getSDKToken = useGetSDKToken()

  // get check results from socket
  const { result, status } = checkResult || {}

  const [isLoading, setIsLoading] = React.useState(false)
  const [buttonText, setButtonText] = React.useState('register.id-verification.button.placeholder')
  const [isDisabled, setIsDisabled] = React.useState(false)

  const { setValue, register, unregister, errors, watch, getValues, setError } = useFormContext()

  const requiredText = t('shared.words.required')

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const applicant = getValues('applicantId');
  const identityError = get(errors, 'identityIsVerified');
  const applicantError = get(errors, 'applicantId');
  const errorMessage = identityError || applicantError

  // Create Applicant ID and token to be able to run onfido SDK
  const runCreateApplicant = async ({ firstName, lastName }) => {
    try {
      await createApplicant({ firstName, lastName })
        .then(({ applicantId }) => {
          setValue('applicantId', applicantId)
          getSDKToken({ applicantId })
        })
        .catch(err => console.warn(err))
    } catch (e) {
      console.warn(e)
    }
  };


  const debounceRequest = React.useCallback(debounce(runCreateApplicant, 500), []);
  React.useEffect(() => {
    if (firstName.length !== 0 && lastName.length !== 0) {
      debounceRequest({ firstName, lastName });
    }
  }, [firstName, lastName])


  React.useEffect(() => {
    register('applicantId', {
      required: requiredText,
      validate: value => value?.length > 0
    })
    register('identityIsVerified', {
      required: requiredText,
      validate: value => value !== false
    })

    return () =>  {
      unregister('applicantId')
      unregister('identityIsVerified')
    }
  }, [register, unregister])


  let onfido = {}
  const triggerOnfido = () => {
    onfido = init({
      useModal: true,
      isModalOpen: true,
      onModalRequestClose: function () {
        onfido.setOptions({ isModalOpen: false })
      },
      token,
      onComplete: function () {
        checkApplicant(applicant)
        setIsLoading(true)
        setButtonText('register.id-verification.button.in-progress')
        onfido.setOptions({ isModalOpen: false })
      },
      onError: function () {
        const message = t(sharedErrors['check'])
        setError('applicantId', { message })
        notify(message, 'error')
        onfido.setOptions({ isModalOpen: false })
      },
      steps: [
        'welcome',
        'userConsent',
        'document',
        'face',
        'complete'
      ],
    });
  }

  React.useEffect(() => {
    if (status === 'complete') {
      setIsLoading(false)
      if (result === 'clear') {
        setValue('identityIsVerified', true, { shouldValidate: true })
        notify(t('register.id-verification.verified'), 'success')
        setButtonText('register.id-verification.button.verified')
        setIsDisabled(true)
      }
      if (result === 'consider') {
        setValue('identityIsVerified', false)
        const message = t(sharedErrors['consider']);
        setError('applicantId', { message });
        notify(message, 'error');
        setButtonText('register.id-verification.button.restart-verification')
      }
    }
  }, [status, result])

  return (
    <div className="id-verification">
      <div id="onfido-mount"></div>
      <Row
        nameBlock={t('register.id-verification.text')}
        component={
          <div>
            <Button
              handleOnClick={() => triggerOnfido()}
              text={t(buttonText)}
              classBtn='id-verification--button'
              loader={isLoading}
              fontSize={0.75}
              primary
              disabled={isDisabled || !token}
              paddingVertical={18}
            />
            {errorMessage && <ErrorMessage className="error" message={errorMessage.message} />}
            <p className="id-verification--description">{t('register.id-verification.description')}</p>
          </div>
        }
      />
    </div>
  )
};

export default IDVerification
