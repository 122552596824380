import React from 'react'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg'
import { ReactComponent as ShareIcon } from 'assets/share-icon.svg'

import FilePreview from '_legacy/components/Library/components/Library/FilePreview'
import Button from '_legacy/components/Button'

const VideoMessageBookingDownload = ({ media }) => {
  const t = useGetTranslate()
  //const share
  const handleDownload = () => window.open(media?.fullUrl, '_blank')

  return (
    <div className="od__answer-grid mb-24">
      <FilePreview file={media} className="od__answer-media" />
      <div className="od__column">
        <h6 className="od--main-text mb-12 vm--column-text">
          {t('booking-details.download.video-message.text')}
        </h6>
        <div className="vm--button-wrapper">
          <Button
            primary
            stretch
            text={t('booking-details.share-video.button')}
            typeButton="button"
            handleOnClick={() => alert('action is not implemented')}
            paddingVertical={9}
            fontSize="0.75"
            iconComponent={<ShareIcon />}
          />
          <Button
            stretch
            text={t('booking-details.share.download-button')}
            typeButton="button"
            handleOnClick={handleDownload}
            paddingVertical={4}
            fontSize="0.75"
            className="vm-download-button"
            iconComponent={<DownloadIcon/>}
          />
        </div>
      </div>
    </div>
  )
}

export default VideoMessageBookingDownload
