import React from 'react';
import PropTypes from 'prop-types';

import Slider from '_legacy/components/TalentPublicView/Slider/Slider'
import Slide from '_legacy/components/TalentPublicView/Slider/Slide'

import Actions from '_legacy/components/TalentPublicView/Slider/prototypes/VideoMessagesPublic/Desktop/Actions'
import Empty from '_legacy/components/TalentPublicView/Slider/prototypes/VideoMessagesPublic/Desktop/Empty'

const VideoMessagesPublic = ({ firstName, covers = [] }) => {
  const slides = covers.map(cover => (
    <Slide.DesktopSlide key={cover.id} file={cover} id={cover.id} />
  ))

  return (
    <Slider
      size="big"
      className="video-message-public"
      slides={slides}
      empty={<Empty firstName={firstName} />}
      Actions={Actions}
      withGradientOverhead={true}
      bottomSpace={40}
    />
  )
}

VideoMessagesPublic.propTypes = {
  firstName: PropTypes.string.isRequired,
  covers: PropTypes.array.isRequired,
}

export default VideoMessagesPublic
