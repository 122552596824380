import React from 'react'
import PropTypes from 'prop-types'
import ExampleHeadshotImg from 'assets/images/example-headshot.png'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import CommonHeadshot from './CommonHeadshot'
import { Row } from '_legacy/common';

const HeadshotWithExample = ({ name }) => {
  const t = useGetTranslate()

  return (
    <Row
      nameBlock={t('register.upload.headshot')}
      subNameBlock={t('register.upload.headshot-label')}
      component={
        <div className="headshot-container">
          <div className="headshot-item">
            <div className="headshot-item__image-wrapper">
              <CommonHeadshot name={name} />
            </div>
            <div className="upload-label upload-label--light">
              {t('register.upload.hint.desc.half-body')}
            </div>
          </div>
          <div className="headshot-item headshot-item--ml">
            <div className="headshot-item__image-wrapper">
              <img className="headshot-item__image" src={ExampleHeadshotImg} alt="" />
            </div>
            <div className="upload-label upload-label--dark">
              {t('shared.words.example')}
            </div>
          </div>
        </div>
      }
    />
  )
}

HeadshotWithExample.propTypes = {
  name: PropTypes.string,
}

export default HeadshotWithExample
