import React from 'react';

import Input from '../../../shared/components/Input';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const SocialSecurityOrTIN = (props) => {
  const t = useGetTranslate();

  return (
    <Input
      placeholder={t('register-user.form.social-security-or-tin.placeholder')}
      name='socialSecurityOrTIN'
      label={t('register-user.form.social-security-or-tin.label')}
      {...props}
    />
  );
};

export default SocialSecurityOrTIN;
