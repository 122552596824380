import React from 'react'
import { useHistory } from 'react-router'
import { api } from 'shared'
import without from 'lodash/without'

const offeringTypes = api.talents.talent.offers.query.TypeFilter;
const offeringStatuses = api.talents.talent.offers.query.StatusFilter;

import * as routes from '_legacy/constants/routes'
import { fields } from '_legacy/configs/table/manageOfferings'

import {
  SUCCESS_NOTIFICATION,
  TABLE_ACTION_ERROR_LIST,
  useCurrentPage,
  useTableActionsNotifications,
} from 'store/hooks/globalState/useUtils'
import {
  useSetMyOfferings,
  useSetManageOfferingsFilters,
  useSetManageOfferingsTable,
  useManageOfferingsFilters,
  useManageOfferingsTable,
} from 'store/hooks/globalState/useManageOfferings'
import { useTalentId, useUser } from 'store/hooks/globalState/useUser'

import { getTalentId } from 'utils/user'
import normalizeParams from 'utils/normalizeParams'
import { sanitizeFilterFields } from '_legacy/components/Table/utils'
import {
  deleteOffers,
  getManageOffers,
  getMyOfferings,
  publishOffers,
  unPublishOffers,
} from 'api/appApi/talent/offers'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

const {
  REQUIRED_MORE_DATA,
  ALREADY_PUBLISHED,
  OFFER_ALREADY_UNPUBLISHED,
  DRAFT_CANT_BE_UNPUBLISHED,
} = TABLE_ACTION_ERROR_LIST

const isPlural = (array = []) => array.length > 1

export function useFetchMyOfferings() {
  const setMyOfferings = useSetMyOfferings((prev, next) => next)
  const handleResponseError = useHandleResponseError()

  const talentId = useTalentId()
  return React.useCallback(() => {
    getMyOfferings({ talentId })
      .then(data => {
        setMyOfferings(data)
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [talentId, setMyOfferings])
}

const offeringMap = id => ({
  [offeringTypes.VIDEO_CHAT]: {
    [offeringStatuses.DRAFT]: `/${routes.OFFERINGS}/${routes.VIDEO_CHAT}/${routes.EDIT}`,
    [offeringStatuses.PUBLISHED]: `/${routes.OFFERINGS}/${routes.VIDEO_CHAT}/${routes.EDIT}`,
  },
  [offeringTypes.VIDEO_MESSAGE]: {
    [offeringStatuses.DRAFT]: `/${routes.OFFERINGS}/${routes.VIDEO_MESSAGE}/${routes.EDIT}`,
    [offeringStatuses.PUBLISHED]: `/${routes.OFFERINGS}/${routes.VIDEO_MESSAGE}/${routes.EDIT}`,
  },
  [offeringTypes.VIDEO_VOICE_OVER_LESSON]: {
    [offeringStatuses.DRAFT]: `/${routes.OFFERINGS}/${routes.LESSONS}`,
    [offeringStatuses.PUBLISHED]: `/${routes.OFFERINGS}/${routes.LESSONS}`,
  },
  [offeringTypes.VIRTUAL_LESSON]: {
    [offeringStatuses.DRAFT]: `/${routes.OFFERINGS}/${routes.LESSONS}`,
    [offeringStatuses.PUBLISHED]: `/${routes.OFFERINGS}/${routes.LESSONS}`,
  },
  [offeringTypes.LIVE_IN_PERSON_LESSON]: {
    [offeringStatuses.DRAFT]: `/${routes.OFFERINGS}/${routes.LESSONS}`,
    [offeringStatuses.PUBLISHED]: `/${routes.OFFERINGS}/${routes.LESSONS}`,
  },
  [offeringTypes.READY_MADE_LESSON]: {
    [offeringStatuses.DRAFT]: `/${routes.OFFERINGS}/${routes.READY_MADE_EVENT_LESSON}/${id}/${routes.DRAFT}`,
    [offeringStatuses.PUBLISHED]: `/${routes.OFFERINGS}/${routes.READY_MADE_EVENT_LESSON}`,
  },
  [offeringTypes.FOR_COMMERCIAL_USE]: {
    [offeringStatuses.DRAFT]: `/${routes.OFFERINGS}/${routes.COMMERCIAL_VIDEO_MESSAGE}/${routes.EDIT}`,
    [offeringStatuses.PUBLISHED]: `/${routes.OFFERINGS}/${routes.COMMERCIAL_VIDEO_MESSAGE}/${routes.EDIT}`,
  },
  [offeringTypes.VIRTUAL_EVENT]: {
    [offeringStatuses.DRAFT]: `/${routes.OFFERINGS}/${routes.LIVE_VIRTUAL_EVENTS}/${id}/${routes.DRAFT}`,
    [offeringStatuses.PUBLISHED]: `/${routes.OFFERINGS}/${routes.LIVE_VIRTUAL_EVENTS}/${id}/${routes.EDIT}`,
  },
  [offeringTypes.IN_PERSON_EXPERIENCE]: {
    [offeringStatuses.DRAFT]: `/${routes.OFFERINGS}/${routes.EXPERIENCES}/${id}/${routes.DRAFT}`,
    [offeringStatuses.PUBLISHED]: `/${routes.OFFERINGS}/${routes.EXPERIENCES}/${id}/${routes.EDIT}`,
  },
})

function getHref(offering) {
  const { status, type, id } = offering
  const offerStatus = status === offeringStatuses.UNPUBLISHED ? offeringStatuses.PUBLISHED : status
  return offeringMap(id)[type][offerStatus]
}

export function useFetchManageOfferings() {
  const setManageOfferingsFilters = useSetManageOfferingsFilters(
    (prev, next) => ({ ...prev, ...next })
  )
  const setManageOfferingsTable = useSetManageOfferingsTable(
    (prev, next) => next
  )
  const handleResponseError = useHandleResponseError()

  return React.useCallback(
    (options, user) => {
      const query = normalizeParams(options)
      setManageOfferingsTable(null)
      getManageOffers({ query, talentId: getTalentId(user) })
        .then(({ pageInfo, results }) => {
          setManageOfferingsFilters(pageInfo)
          setManageOfferingsTable(results)
        })
        .catch(error => {
          handleResponseError(error)
          console.warn(error)
        })
    },
    [setManageOfferingsFilters, setManageOfferingsTable]
  )
}

export function usePublishOffer(onSuccess) {
  const fetchManageOfferings = useFetchManageOfferings()
  const filtersOptions = useManageOfferingsFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )
  const page = useCurrentPage()
  const tableActionsNotifications = useTableActionsNotifications()
  const user = useUser()

  return React.useCallback(
    offerIds => {
      publishOffers({ talentId: getTalentId(user), offerIds })
        .then(({ results }) => {
          fetchManageOfferings({ ...filtersOptions, page }, user)
          const config = {
            [SUCCESS_NOTIFICATION]: 'table.notifications.mo.published',
            [REQUIRED_MORE_DATA]: 'table.notifications.mo.error.drafts-needs-more-data',
            [ALREADY_PUBLISHED]: 'table.notifications.mo.error.already-published',
          }
          tableActionsNotifications({ results, config })

          if (onSuccess) onSuccess()
        })
        .catch(error => {
          console.warn(error)
        })
    },
    [user, fetchManageOfferings, filtersOptions, page, tableActionsNotifications]
  )
}

export function useUnpublishOffer(onSuccess) {
  const fetchManageOfferings = useFetchManageOfferings()
  const filtersOptions = useManageOfferingsFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )
  const page = useCurrentPage()
  const tableActionsNotifications = useTableActionsNotifications()
  const user = useUser()

  return React.useCallback(
    offerIds => {
      unPublishOffers({ offerIds, talentId: getTalentId(user) })
        .then(({ results }) => {
          fetchManageOfferings({ ...filtersOptions, page }, user)
          const config = {
            [SUCCESS_NOTIFICATION]: 'table.notifications.mo.unpublished',
            [OFFER_ALREADY_UNPUBLISHED]: 'table.notifications.mo.error.already-unpublished',
            [DRAFT_CANT_BE_UNPUBLISHED]: 'table.notifications.mo.error.drafts-cant-be-unpublished',
          }
          tableActionsNotifications({ results, config })

          if (onSuccess) onSuccess()
        })
        .catch(error => {
          console.warn(error)
        })
    },
    [
      user,
      fetchManageOfferings,
      filtersOptions,
      page,
      tableActionsNotifications,
    ]
  )
}

export function useDeleteOffer() {
  const fetchManageOfferings = useFetchManageOfferings()
  const filtersOptions = useManageOfferingsFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )
  const page = useCurrentPage()
  const tableActionsNotifications = useTableActionsNotifications()
  const removeIdFromSelected = useSetManageOfferingsFilters((prev, ids) => ({
    ...prev,
    selected: without(prev.selected, ...ids),
  }))
  const user = useUser()

  return React.useCallback(
    ({ offerIds, cancelReasonDescription, cancelReason }) => {
      deleteOffers({ talentId: getTalentId(user), offerIds, cancelReasonDescription, cancelReason })
        .then(({ results }) => {
          fetchManageOfferings({ ...filtersOptions, page }, user)
          const config = {
            [SUCCESS_NOTIFICATION]: 'table.notifications.mo.deleted',
          }
          const pluralConfig = {
            [SUCCESS_NOTIFICATION]: 'table.notifications.mo.deleted-plural',
          }
          tableActionsNotifications({ results, config: isPlural(offerIds) ? pluralConfig : config })
          removeIdFromSelected(offerIds)
        })
        .catch(error => {
          console.warn(error)
        })
    },
    [user, fetchManageOfferings, filtersOptions, page, tableActionsNotifications, removeIdFromSelected]
  )
}

export function useNavigateToEditOffer() {
  const history = useHistory()
  const offerings = useManageOfferingsTable()

  return React.useCallback(
    ([offerId]) => {
      const offering = offerings.find(({ id }) => id === offerId)
      const href = getHref(offering)

      history.push(href)
    },
    [history, offerings]
  )
}
