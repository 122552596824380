// Modules
import React from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import set from 'lodash/set'

// Assets
import Icon from 'assets/icons/Icon'

// Routing
import * as r from '_legacy/constants/routes'

// Store (Hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useIsLoadingChat } from 'store/hooks/globalState/useChats'
import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'
import { useStartChat } from 'requests/chats'
import { useCheckComplaint } from 'requests/complaint'
import { useFetchExperienceBookingDetails } from './state/requests'
import { useExperienceBookingDetails, useSetExperienceBookingDetails } from './state/store'

// Utils
import { getFirstLocationLabel, getFirstLocationPlace } from 'utils/location'
import { getPlaqueNickname, getSaveFullName, getTalentLink, getUserTimeZone } from 'utils/user'
import { getDateTimeProps, getDaysTo, getLocale, getMonthDayOnly, getTimeRange } from 'utils/date'
import { getTimeZoneFromOccurrenceLocation } from 'utils/forms/adapters/timezone'
import { getSteps } from 'components/OfferingDetails/components/StatusBar/utils/getSteps'
import { getAficionadoRefundText, getCancelText, getReasonText } from 'components/OfferingDetails/utils'
import { defaultRoute, getDetailsConfig } from './utils/order-details-config'

// Components
import StatusBar from 'components/OfferingDetails/components/StatusBar'
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingDetailsWaiting from 'components/OfferingDetails/components/OfferingDetailsWaiting'
import OfferingDetailsReviewStars from 'components/OfferingDetails/components/OfferingDetailsReviewStars'
import OfferingsDetailsHeaderHold from 'components/OfferingDetails/components/OfferingsDetailsHeaderHold'
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'
import OfferingDetailsGenericStatus from 'components/OfferingDetails/components/OfferingDetailsGenericStatus'
import OfferingDetailsBookingContact from 'components/OfferingDetails/components/OfferingDetailsBookingContact'

// Legacy components
import Avatar from '_legacy/components/Avatar'
import Rating from '_legacy/components/Rating'
import CustomMedia from '_legacy/common/CustomMedia'
import PageLoader from '_legacy/common/Template/PageLoader'
import ContentTabs from '_legacy/containers/ContentTabs'
import { getLiveInPersonLessonBookingConfig } from '../LiveInPersonLessonBookingsDetails/utils'
import ComplaintInfo from '../../components/ComplaintInfo'
import CancelStatusWithContact from '../../components/CancelStatusWithContact'

const ExperienceBookingsDetails = () => {
  const t = useGetTranslate()
  const { orderId } = useParams()
  const userTimeZone = useUser(getUserTimeZone)
  const userLang = useUserLang()

  const isLoadingChat = useIsLoadingChat()
  const details = useExperienceBookingDetails()
  const clearDetails = useSetExperienceBookingDetails(() => null)

  const { review, occurrence, number, statusTimeline } = details || {}

  const menuItems = getDetailsConfig(orderId)

  const fetchBookingsDetails = useFetchExperienceBookingDetails()
  const checkComplaint = useCheckComplaint()
  const startChat = useStartChat(occurrence?.talent?.id)
  const onReviewSuccess = React.useCallback(() => {
    fetchBookingsDetails()
    checkComplaint(orderId)
  }, [orderId])

  const handleContact = () => {
    if (!isLoadingChat && occurrence?.talent?.id) {
      return startChat()
    }
  }

  // effects
  React.useEffect(() => {
    fetchBookingsDetails()
    return () => {
      clearDetails()
    }
  }, [])

  if (!occurrence) {
    return <PageLoader />
  }

  const { title, maxNumberOfParticipants, photo, price, offer, location, participantsCount, talent, pendingAction } = occurrence || {}

  const timeZone = getTimeZoneFromOccurrenceLocation(occurrence)
  const { formattedDate, formattedTimeRange, startTime, endTime } = getDateTimeProps({ ...occurrence, timeZone })
  const plaqueNickname = getPlaqueNickname(talent)

  const fullName = getSaveFullName(talent)
  const daysLeft = getDaysTo(new Date(pendingAction?.due), { locale: getLocale(userLang) })
  const occurrenceWithTimeLine = set(occurrence, 'order.statusTimeline', statusTimeline)

  const reasonText = getReasonText({ t, ...details })
  const cancelText = getCancelText({ t, ...details })
  const refundText = getAficionadoRefundText({ t, refundRequests: details.refundRequests })

  const infoConfig = [
    {
      icon: <Icon.Calendar />,
      item: formattedDate,
      subitem: formattedTimeRange,
    },
    {
      icon: <Icon.Pin />,
      item: getFirstLocationPlace(location.place),
      subitem: getFirstLocationLabel(location.place),
    },
    {
      icon: <Icon.Participants />,
      item: `${participantsCount} of ${maxNumberOfParticipants}`,
      subitem: '',
    },
    {
      icon: <Icon.Pricetag />,
      item: `$${price} ${t('currency.usd')}`,
      subitem: t('order-details.price-per-person'),
    },
  ]

  const c = getLiveInPersonLessonBookingConfig(details)

  return (
    <div className="in-person-experience-order od">
      <OfferingDetailsTitle
        typeHeader={t('shared.offers.experience')}
        title={`${t('booking-details.main.title')} #${number}`}
      />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <StatusBar steps={getSteps(occurrenceWithTimeLine, userTimeZone, true)} />
          <div className="od__row od__column-m">
            <div className="od__media-aside">
              {photo ? <CustomMedia file={{ ...photo.file, originalName: '' }} /> : <img alt={''} />}
              <div className="od__media-aside--overlay">
                <div className="od__media-aside--talent-wrapper">
                  <p className="mb-8">{t('booking-details.talent.offered-by')}</p>
                  <Avatar className="mb-8" file={talent.profileImage?.file} alt={fullName} isDark />
                  <h6 className="mb-8">{fullName}</h6>
                  <Rating rating={talent.reviewsInfo.averageRating} leftColor="#fff" rightColor="#626473" />
                </div>
              </div>
            </div>
            <div className="od__column od--padding-info">
              <h6 className="od--event-name od--capitalized mb-24">{title}</h6>
              {infoConfig.filter(Boolean).map((item, i) => {
                return <OfferingsDetailsHeaderItem key={i} {...item} />
              })}
              <Link
                className="od__row od__public-link"
                to={`/${r.EXPERIENCES}/${plaqueNickname}/${offer.sequentialId}`}
              >
                <div className="od--main-text od--accented od__public-link--text">
                  {t('order-details.info.link-text')}
                </div>
                <div className="od__public-link--arrow" />
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div className="od--shadow-box od--centered">
            {c.isShowWaitingForApprove && (
              <OfferingDetailsWaiting
                waitingTitle={t('order-details.experience.header')}
                waitingText={t('booking-details.talent.time-before-reject')}
                timeLeft={daysLeft}
              >
                {/* Hidden for now due to changes from Chris */}
                {/* <Button
                  stretch
                  handleOnClick={() => openCancelModal()}
                  primary
                  text={t('booking-details.cancel.request')}
                  typeButton="submit"
                  fontSize="0.875"
                  paddingVertical="16"
                /> */}
              </OfferingDetailsWaiting>
            )}

            {c.isShowContact && (
              <OfferingDetailsBookingContact
                title={t('order-details.experience.header')}
                text={t('booking-details.contact.text')}
                contactText={t('booking-details.contact.title')}
                contactCallback={handleContact}
                // cancelText={t('booking-details.cancel.request')}
                // cancelCallback={() => openCancelModal()}
              />
            )}

            {c.isShowHold && (
              <OfferingsDetailsHeaderHold
                title={t('order-details.experience.header')}
                date={getMonthDayOnly(startTime)}
                duration={getTimeRange([startTime, endTime])}
                locationMain={getFirstLocationPlace(location.place)}
                locationFull={getFirstLocationLabel(location.place)}
                typeText={t('order-details.experience.header')}
              />
            )}

            {c.isShowRejected && (
              <OfferingDetailsGenericStatus
                title={t('order-details.experience.header')}
                type={t('order.header.rejected')}
                reasonText={reasonText}
              />
            )}

            {c.isShowCanceled && (
              <CancelStatusWithContact
                refundText={refundText}
                cancelText={cancelText}
                title={t('order-details.experience.header')}
              />
            )}

            {/* TODO clarify if we need cancel button if event in progress */}
            {c.isShowInProgress && (
              <OfferingDetailsGenericStatus
                title={t('order-details.experience.header')}
                type={t('order.header.in-progress')}
              />
            )}

            {c.isShowCompleted && (
              <div className="od--centered">
                <OfferingDetailsGenericStatus
                  title={t('order-details.experience.header')}
                  type={t('booking-details.main-completed.title')}
                />
                <p className="od--main-text mb-12">{t('booking-details.main-completed.text')}</p>
                <OfferingDetailsReviewStars
                  orderId={orderId}
                  review={review}
                  onSuccess={onReviewSuccess}
                  userLink={getTalentLink(talent)}
                  userFullName={getSaveFullName(talent)}
                  offerType={t('order-details.experience.header')}
                  reviewType={'order.answer.rate.by'}
                />
              </div>
            )}
          </div>

          <ComplaintInfo
            orderId={orderId}
            userLink={getTalentLink(talent)}
            userName={fullName}
            onSuccess={fetchBookingsDetails}
          />
        </div>
      </div>

      <ContentTabs menuItems={menuItems} defaultRoute={defaultRoute} />
    </div>
  )
}

export default ExperienceBookingsDetails
