import React from 'react';
import { object } from 'prop-types';

import CalendarButton from '_legacy/components/Button/CalendarButton'
import Slider from '../Slider';
import CommercialEngagementBook from '../CommercialEngagementBook/';
import ImageLayer from '../ImageLayer/';
import { getSlides } from '../utils.js';


const DesktopSlidersBlock = React.memo(function DesktopSlidersBlock({ talentData, openCalendar }) {
  const {
    nickname,
    firstName,
    lastName,
    publicView,
    videoMessage,
    videoChat,
    plaqueNickname,
    liveVirtualEvents,
  } = talentData

  const {
    LiveExperiencesCover,
    LiveVideoChatsCover,
    LiveVirtualEventsCover,
    VideoMessagesCover,
    LessonsCover,
    ReadyMadeEventsCover,
  } = getSlides(talentData)

  return (
    <div className="desktop-slider-block">
      <div className="desktop-slider-block__sliders">
        <Slider.LiveExperiences
          covers={LiveExperiencesCover}
          plaqueNickname={plaqueNickname}
          firstName={firstName}
        />
        <Slider.LiveVideoChats
          covers={LiveVideoChatsCover}
          plaqueNickname={plaqueNickname}
          offerSequentialId={videoChat?.sequentialId}
          firstName={firstName}
        />
        <Slider.LiveVirtualEvents
          covers={LiveVirtualEventsCover}
          plaqueNickname={plaqueNickname}
          offerSequentialId={liveVirtualEvents?.sequentialId}
          firstName={firstName}
        />
      </div>

      <div className="desktop-slider-block__center-items">
        <ImageLayer
          className="main-image"
          layers={publicView?.layers}
          altImg={`${firstName} ${lastName}`}
        />
        <div className="desktop-slider-block__center-items__button">
          <CalendarButton className='view-calendar-mode' onClick={openCalendar} />
          <CommercialEngagementBook nickname={nickname} />
        </div>
      </div>

      <div className="desktop-slider-block__sliders">
        <Slider.VideoMessages
          covers={VideoMessagesCover}
          plaqueNickname={plaqueNickname}
          offerSequentialId={videoMessage?.sequentialId}
          firstName={firstName}
        />
        <Slider.Lessons
          covers={LessonsCover}
          plaqueNickname={plaqueNickname}
          firstName={firstName}
        />
        <Slider.ReadyMadeEvents
          covers={ReadyMadeEventsCover}
          plaqueNickname={plaqueNickname}
          firstName={firstName}
        />
      </div>
    </div>
  )
});

DesktopSlidersBlock.propTypes = {
  talentData: object.isRequired,
};

export default DesktopSlidersBlock;
