import React from 'react'
import './index.scss'

import EntouragePublicPage from './components/EntouragePublicPage'

import { pageConfig, statusKeys } from './configs/config'

const EntouragePage = React.memo(() => {
  return <EntouragePublicPage pageConfig={pageConfig} initialStatus={statusKeys.lite} />
})

export default EntouragePage
