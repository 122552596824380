import { api } from 'shared';

import { ReactComponent as AcceptedIcon } from 'assets/accepted.svg'
import { ReactComponent as PendingIcon } from 'assets/pending.svg'
import { ReactComponent as RejectedIcon } from 'assets/rejected.svg'
import { ReactComponent as DisconnectedIcon } from 'assets/disconnected.svg'

import { filters } from '_legacy/components/Table/Filters/config'

const { AGENT, MANAGER } = api.talents.talent.profileManagers.query.role;
export const roles = {
  [AGENT]: 'table.filters.roles.agent',
  [MANAGER]: 'table.filters.roles.manager'
};
const roleOptions = Object.keys(roles).map(key => ({ value: key, label: roles[key] }))

const { ACCEPTED, PENDING, REJECTED, DISCONNECTED, CANCELED } = api.talents.talent.profileManagers.query.status;

export const statuses = {
  [ACCEPTED]: {
    Icon: AcceptedIcon,
    label: 'table.filters.statuses.accepted',
    color: 'accepted'
  },
  [PENDING]: {
    Icon: PendingIcon,
    label: 'table.filters.statuses.pending',
    color: 'lightGrey'
  },
  [REJECTED]: {
    Icon: RejectedIcon,
    label: 'table.filters.statuses.rejected',
    color: 'rejected'
  },
  [DISCONNECTED]: {
    Icon: DisconnectedIcon,
    label: 'table.filters.statuses.disconnected',
    color: 'rejected'
  },
  [CANCELED]: {
    Icon: RejectedIcon,
    label: 'table.filters.statuses.cancelled',
    color: 'rejected'
  }
}
const statusOptions = Object.keys(statuses).map(key => ({ value: key, label: statuses[key].label }))

export const filtersConfig = [
  {
    type: filters.search,
    placeholder: 'table.filters.search',
    field: 'input'
  },
  {
    type: filters.select,
    placeholder: 'table.filters.role',
    field: 'role',
    options: roleOptions
  },
  {
    type: filters.select,
    placeholder: 'table.filters.status',
    field: 'status',
    options: statusOptions
  }
]

export const counterConfig = {
  field: 'count',
  label: 'table.filters.user',
  pluralLabel: 'table.filters.users',
}

const { NAME, ROLE, STATUS } = api.talents.talent.profileManagers.query.sortField;
const sortFields = {
  [NAME]: 'table.header.name',
  [ROLE]: 'table.header.role',
  [STATUS]: 'table.header.status'
};
const sortFieldOptions = Object.keys(sortFields).map(key => ({ value: key, label: sortFields[key] }))

export const subFiltersConfig = [
  {
    label: 'table.filters.by',
    field: 'sortField',
    options: sortFieldOptions
  },
  {
    label: 'table.filters.show',
    field: 'limit',
    options: [{ value: 10, label: '10' }, { value: 25, label: '25' }, { value: 50, label: '50' }]
  }
]

const additionalFields = [
  'sortOrder'
]

export const fields = [
  ...filtersConfig.map(({ field }) => field),
  ...subFiltersConfig.map(({ field }) => field),
  ...additionalFields
]
