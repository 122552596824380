import { getLocationFormattedAddress } from 'utils/location.js'


export const getLocationAdapter = location => {
  if (!location) return

  return {
    value: location.id,
    label: getLocationFormattedAddress(location),
  }
}
