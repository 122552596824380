import formatDuration from 'date-fns/formatDuration'

const ONE_MINUTE = 60
const ONE_HOUR = 60 * 60
const ONE_DAY = ONE_HOUR * 24
const ONE_WEEK = ONE_DAY * 7

function secondsToWdhms(incomingSeconds) {
  let seconds = incomingSeconds

  const weeks = Math.floor(seconds / ONE_WEEK)
  seconds -= weeks * ONE_WEEK

  const days = Math.floor(seconds / ONE_DAY)
  seconds -= days * ONE_DAY

  const hours = Math.floor(seconds / ONE_HOUR)
  seconds -= hours * 3600

  const minutes = Math.floor(seconds / ONE_MINUTE)
  seconds -= minutes * 60

  return {
    weeks,
    days,
    hours,
    minutes,
    seconds,
  }
}

// formatTimeLabel :: Number -> String
const formatTimeLabel = (seconds, options) => {
  const duration = secondsToWdhms(seconds)
  return formatDuration(duration, options)
}


function getTimeOptions(listOfSeconds = [], options = {}) {
  return listOfSeconds.map(seconds => ({
    value: seconds,
    label: formatTimeLabel(seconds, options)
  }))
}

export {
  getTimeOptions,
  secondsToWdhms,
  ONE_WEEK,
  ONE_MINUTE,
  ONE_HOUR,
  ONE_DAY,
}
