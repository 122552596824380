import { _get, _post, _patch } from '../../../utils';

export const createVideoMessageForCommercialUse = ({ talentId, data }) => _post(
  `/talents/${talentId}/offers/commercial-video-message`,
  { needAuthorization: true, body: data }
);
export const getVideoMessageForCommercialUse = ({ talentId }) => _get(
  `/talents/${talentId}/offers/commercial-video-message`,
  { needAuthorization: true }
);
export const updateVideoMessageForCommercialUse = ({ talentId, data }) => _patch(
  `/talents/${talentId}/offers/commercial-video-message`,
  { needAuthorization: true, body: data }
);
