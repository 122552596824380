import React from 'react'
import Icon from 'assets/icons/Icon'
import * as r from '_legacy/constants/routes'
import { routesList } from 'router/routesMap'

// ****** calendar ******
// talent
const getCalendarNavItem = () => ({
  slug: 'calendar',
  icon: 'icon-calendar-alt',
  name: 'account.seeker.menu.calendar.label',
  linkProps: [
    {
      value: 'account.seeker.menu.calendar.all',
      target: `/${r.CALENDAR}?view=week`,
    },
    {
      value: 'account.seeker.menu.calendar.day',
      target: `/${r.CALENDAR}?view=day`,
    },
  ],
})

// ****** my profile ******
// talent
const getTalentProfileNavItem = ({ userNameLink, inviteManagerHandler, isMobile }) => ({
  slug: 'profile',
  icon: 'icon-user-circle',
  name: 'account.menu.profile.title',
  linkProps: [
    {
      value: 'account.menu.profile.preview-profile',
      target: `/${userNameLink}`,
    },
    {
      value: 'account.menu.profile.edit-profile',
      target: isMobile ? `/${r.ACCOUNT}` : `/${r.ACCOUNT}/${r.EDIT_PROFILE}`,
    },
    {
      value: 'account.menu.profile.profile-managers',
      target: `/${r.PROFILE_MANAGERS}/${r.LIST}`,
    },
    {
      value: 'account.menu.profile.invite-manager',
      onClick: inviteManagerHandler,
    },
    {
      value: 'account.menu.profile.how-to-videos',
      target: `/${r.HOW_TO_VIDEOS}`,
    },
    {
      value: 'account.menu.profile.resource-center',
      target: `/${r.RESOURCE_CENTER}`,
    },
  ],
})

// seeker
const getSeekerProfileNavItem = ({ plaqueNickname, isMobile, shouldShowRegisterTalentLink }) => ({
  slug: 'profile',
  icon: 'icon-user-circle',
  name: 'account.seeker.menu.profile.title',
  linkProps: [
    shouldShowRegisterTalentLink && {
      value: 'account.menu.profile.create-talent-profile',
      target: `/${r.REGISTER}/${r.TALENT}`,
    },
    {
      value: 'account.seeker.menu.profile.my-library',
      target: `/${r.MEDIA}/${r.LIST}`,
    },
    {
      value: 'account.seeker.menu.profile.preview-profile',
      target: `/${r.USER}/${plaqueNickname}`,
    },
    {
      value: 'account.seeker.menu.profile.edit-profile',
      target: isMobile ? `/${r.ACCOUNT}` : `/${r.ACCOUNT}/${r.EDIT_PROFILE}`,
    },
    {
      value: 'account.menu.profile.resource-center',
      target: `/${r.RESOURCE_CENTER}`,
    },
  ].filter(Boolean),
})

// ****** my offerings ******
// talent
const getOfferingsNavItem = () => ({
  slug: 'offerings',
  icon: 'icon-pricetag',
  name: 'account.menu.offerings.title',
  linkProps: [
    {
      value: 'account.menu.offerings.new-offerings',
      target: `/${r.OFFERINGS}`,
    },
    {
      value: 'account.menu.offerings.manage-my-offerings',
      target: `/${r.LISTING}/${r.LIST}`,
    },
    {
      value: 'account.menu.offerings.my-orders',
      target: `/${r.ORDERS}/${r.LIST}`,
    },
    {
      value: 'account.menu.offerings.my-library',
      target: `/${r.MEDIA}/${r.LIST}`,
    },
    {
      value: 'account.menu.offerings.my-promos',
      target: `/${r.PROMO_CODES}/${r.LIST}`,
    },
  ],
})

// ****** my booking ******
// talent
const getTalentBookingsNavItem = () => ({
  slug: 'bookings',
  icon: 'icon-bookmark',
  name: 'account.menu.bookings.title',
  linkProps: [
    {
      value: 'account.menu.bookings.orders',
      target: `/${r.BOOKINGS}/${r.LIST}`,
    },
    {
      value: 'account.seeker.menu.bookings.favorites',
      target: `/${r.FAVORITES}/${r.TALENT}`,
    },
  ],
})

// seeker
const getSeekerBookingNavItem = ({
  underReviewHandler,
  approvedHandler,
  rejectedHandler,
  bookedHandler,
  adminReviewHandler,
  completedHandler,
  cancelledHandler,
}) => ({
  slug: 'bookings',
  icon: 'icon-bookmark',
  name: 'account.seeker.menu.bookings.title',
  linkProps: [
    {
      value: 'account.seeker.menu.bookings.favorites',
      target: `/${r.FAVORITES}/${r.TALENT}`,
    },
    {
      value: 'account.seeker.menu.bookings.under-review',
      target: `/${r.BOOKINGS}/${r.LIST}`,
      onLinkClick: underReviewHandler,
    },
    {
      value: 'account.seeker.menu.bookings.approved',
      target: `/${r.BOOKINGS}/${r.LIST}`,
      onLinkClick: approvedHandler,
    },
    {
      value: 'account.seeker.menu.bookings.rejected',
      target: `/${r.BOOKINGS}/${r.LIST}`,
      onLinkClick: rejectedHandler,
    },
    {
      value: 'account.seeker.menu.bookings.booked',
      target: `/${r.BOOKINGS}/${r.LIST}`,
      onLinkClick: bookedHandler,
    },
    {
      value: 'account.seeker.menu.bookings.admin-review',
      target: `/${r.BOOKINGS}/${r.LIST}`,
      onLinkClick: adminReviewHandler,
    },
    {
      value: 'account.seeker.menu.bookings.completed',
      target: `/${r.BOOKINGS}/${r.LIST}`,
      onLinkClick: completedHandler,
    },
    {
      value: 'account.seeker.menu.bookings.cancelled',
      target: `/${r.BOOKINGS}/${r.LIST}`,
      onLinkClick: cancelledHandler,
    },
  ],
})

// ****** my account ******
// talent
// const getTalentMyAccountNavItem = () => ({
//   slug: 'account',
//   icon: 'icon-my-account',
//   name: 'account.menu.my-account.title',
//   linkProps: [
//     {
//       value: 'account.menu.my-account.dashboard',
//       target: '/',
//     },
//     {
//       value: 'account.menu.my-account.cart',
//       target: '/',
//     },
//     {
//       value: 'account.menu.my-account.my-tax-documentation',
//       target: '/',
//     },
//   ],
// })

// seeker
// const getSeekerMyAccountNavItem = () => ({
//   slug: 'account',
//   icon: 'icon-my-account',
//   name: 'account.menu.my-account.title',
//   linkProps: [
//     {
//       value: 'account.menu.my-account.cart',
//       target: '/',
//     },
//     {
//       value: 'account.menu.my-account.my-tax-documentation',
//       target: '/',
//     },
//   ],
// })

// ****** reviews ******
// common
const getReviewsNavItem = () => ({
  slug: 'reviews',
  icon: 'icon-review',
  name: 'account.menu.reviews.title',
  linkProps: [
    {
      value: 'account.menu.reviews.by-me',
      target: `/${r.REVIEWS}/${r.LIST}/${r.BY_ME}`,
    },
    {
      value: 'account.menu.reviews.of-me',
      target: `/${r.REVIEWS}/${r.LIST}/${r.TO_ME}`,
    },
  ],
})

// ****** notification ******
// common
const getNotificationNavItem = ({ remindersHandler, communityCommunicationHandler, accountRelatedHandler }) => ({
  slug: 'notifications',
  icon: 'icon-bell',
  name: 'account.menu.notifications.title',
  linkProps: [
    {
      value: 'account.menu.notifications.reminders',
      target: `/${r.NOTIFICATIONS}`,
      onLinkClick: remindersHandler,
    },
    {
      value: 'account.menu.notifications.community-communication',
      target: `/${r.NOTIFICATIONS}`,
      onLinkClick: communityCommunicationHandler,
    },
    {
      value: 'account.menu.notifications.account-related',
      onLinkClick: accountRelatedHandler,
      target: `/${r.NOTIFICATIONS}`,
    },
  ],
})

// ****** secure chat ******
// common
const getSecureChatNavItem = ({ hasUnreadChatMessages }) => ({
  slug: 'chat',
  icon: 'icon-chat',
  name: 'account.menu.secure-chat',
  isLink: true,
  linkTo: `/${r.SECURE_CHAT}`,
  hasUnreadChatMessages,
})

// ****** language ******
// common
const getLanguageAndCurrencyNavItem = ({ isMobile, languageAndCurrencyLabel }) =>
  isMobile && {
    slug: 'languageAndCurrency',
    IconComponent: <Icon.Earth />,
    name: languageAndCurrencyLabel,
    isLink: true,
    linkTo: routesList.currencyAndLanguage(),
  }

export const talentMenuConfig = [
  getCalendarNavItem,
  getTalentProfileNavItem,
  getOfferingsNavItem,
  getTalentBookingsNavItem,
  // here and above commented due to ui changes (will be uncommented and further developed in phase 2)
  //getTalentMyAccountNavItem,
  getReviewsNavItem,
  getNotificationNavItem,
  getSecureChatNavItem,
  getLanguageAndCurrencyNavItem
]

export const seekerMenu = [
  getCalendarNavItem,
  getSeekerProfileNavItem,
  getSeekerBookingNavItem,
  // here and above commented due to ui changes (will be uncommented and further developed in phase 2)
  //getSeekerMyAccountNavItem,
  getReviewsNavItem,
  getNotificationNavItem,
  getSecureChatNavItem,
  getLanguageAndCurrencyNavItem
]

export const getMenuConfig = ({ isTalent, ...props }) => {
  const config = isTalent ? talentMenuConfig : seekerMenu
  return config.map(fn => fn(props)).filter(Boolean)
}
