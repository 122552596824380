import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Input } from '_legacy/common';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import CodeConfirmButton from './CodeConfirmButton';


const CodeConfirm = ({ fields }) => {
  const t = useGetTranslate();

  return (
    <CodeConfirmWrapper>
      <Input
        isRequired
        name={fields.confirm}
        placeholder={t('register-user.form.phone.confirm.placeholder')}
        shouldShowError={false}
        className='_input'
        type="tel"
        auto-complete="one-time-code"
      />
      <CodeConfirmButton fields={fields} />
    </CodeConfirmWrapper>
  );
};

const CodeConfirmWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  ._input input {
    height: 100%;
  }
`;

CodeConfirm.propTypes = {
  fields: PropTypes.object,
};

export default CodeConfirm;
