import { experiences } from 'shared'
import pipe from 'lodash/fp/pipe'
import cond from 'lodash/fp/cond'
import prop from 'lodash/fp/prop'
import isNumber from 'lodash/fp/isNumber'
import add from 'lodash/fp/add'
import T from 'lodash/fp/T'
import always from 'lodash/fp/always'
import propEq from 'lodash/fp/propEq'
import { compose } from 'lodash/fp'
import { getFirstLocationSubtitle, getFirstLocationTitle } from '../../../utils/location'

const TypesOfLocation = experiences.TypesOfLocation


export const getOccurrencesLimit = (t, offering) => pipe(
  cond([
    [prop('occurrencesLimit'), prop('occurrencesLimit')],
    [prop('recurring.options.ends'), prop('recurring.options.ends')],
  ]),
  cond([
    [isNumber, add(t('recurring-list.info.occurrence-limit'))],
    [T, always('')],
  ])
)(offering)


export const getExperienceDateType = (t, offering) => cond([
  [propEq('typesOfDate', TypesOfLocation.FLEXIBLE), () => t('recurring-list.info.flexible')],
  [propEq('typesOfDate', TypesOfLocation.FIXED), () => t('recurring-list.info.fixed')],
  [T, () => t('recurring-list.info.fixed')],
])(offering)

export const getOfferingDateLabel = (t, offering) => {
  const experienceDateType = getExperienceDateType(t, offering)
  const occurrenceLimit = getOccurrencesLimit(t, offering)

  return `${experienceDateType} ${occurrenceLimit}`
}


export const getOfferLocationTitle = (t, offering) => cond([
  [propEq('typesOfLocation', TypesOfLocation.FIXED), compose(getFirstLocationTitle, prop('locations'))],
  [propEq('typesOfLocation', TypesOfLocation.FLEXIBLE), () => t('recurring-list.locations.flexible')],
])(offering)


export const getOfferLocationSubtitle = (t, offering) => cond([
  [propEq('typesOfLocation', TypesOfLocation.FIXED), compose(getFirstLocationSubtitle, prop('locations'))],
  [propEq('typesOfLocation', TypesOfLocation.FLEXIBLE), ({ locations }) => `${locations.length} ${t('recurring-list.info.locations')}`],
])(offering)
