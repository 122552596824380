import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import omit from 'lodash/omit'
import get from 'lodash/get'
import { isFuture } from 'date-fns'
import { types, CalendarSlotType } from 'shared'
import { includes, __, propEq, cond, always, T, prop, compose } from 'lodash/fp'
import { useLocation } from 'react-router-dom';
import cx from 'classnames'

import { useUser } from 'store/hooks/globalState/useUser'
import { useModal } from 'store/hooks/useModal'
import { useEditEvent } from './state/requests/edit-event'
import { useDeleteEvent } from './state/requests/delete-event'
import { timeSlotAdapter, useCreateEvent } from './state/requests/create-event'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useFetchTalentCalendar } from 'components/Calendar/TalentCalendar/state/requests/talent-calendar'
import {
  useTalentCalendarSlots,
  useSetTalentCalendarSlots,
  useTalentCalendarFilters,
  useSetTalentCalendarFilters,
  INITIAL_STATE,
} from 'components/Calendar/TalentCalendar/state/store/useCalendar'

import { parseRecurring } from 'utils/forms/adapters/recurring'
import { scrollToRef } from 'utils/scrollToRef'
import { getTalentId } from 'utils/user'
import { eventInfoAdapter } from 'components/Calendar/components/InfoSidebar/utils'
import { recurringTypesOptions, typeOptions } from 'components/Calendar/components/NewEventForm/options'
import { talentCalendarConfig } from './config.js'
import { createDraftEventTimeSlot } from 'components/Calendar/BaseCalendar/utils'
import { getUserTimeZone } from 'utils/user'
import { getTimezoneAbbr } from 'utils/services/timezone'
import { applyTimeToDate } from '../utils/applyTimeToDate'
import { initialView } from 'components/Calendar/BaseCalendar/configs/calendar-config'

import Button from '_legacy/components/Button'

import BaseCalendar from 'components/Calendar/BaseCalendar'
import Drawer from 'components/Calendar/components/Drawer'
import NewEventForm from 'components/Calendar/components/NewEventForm'
import InfoSidebar from 'components/Calendar/components/InfoSidebar'
import CalendarHeader from 'components/Calendar/components/CalendarHeader/'
import CategoriesSidebar from 'components/Calendar/components/CategoriesSidebar'
import DeleteEventModal from 'components/Calendar/components/DeleteEventModal'
import EditEventModal from 'components/Calendar/components/EditEventModal'

import { withDotMenu, withHoursFormat, withShowWeekends, withBusinessHours } from 'components/Calendar/hocs/'

const TalentBaseCalendar = compose(
  withBusinessHours,
  withShowWeekends,
  withHoursFormat,
  withDotMenu,
)(BaseCalendar)

const DOES_NOT_REPEAT = types.experiences.recurrence.RecurringDefinedInAdvanceValues.DOES_NOT_REPEAT

export const isAvailableToEdit = includes(__, [CalendarSlotType.TIME_UNAVAILABLE, CalendarSlotType.MY_EVENT])

export const isDraftSlot = propEq('draft', true)

export const isSlotEditing = propEq('editing', true)

export const getDrawerHeader = cond([
  [isSlotEditing, prop('eventHeading')],
  [isDraftSlot, always('calendar.new-event-form.header')],
  [T, prop('eventHeading')],
])


const TalentCalendar = () => {
  const t = useGetTranslate()
  const createEvent = useCreateEvent()
  const talentId = useUser(getTalentId)
  const editEvent = useEditEvent()
  const deleteEvent = useDeleteEvent()
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search)
  const initialFormat = queryParams.get('view')

  const timeZone = useUser(getUserTimeZone)

  const [hour12Format, setHour12Format] = React.useState(true)

  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useModal(false)
  const [isEditModalOpen, openEditModal, closeEditModal] = useModal(false)

  const [isDrawerOpen, openDrawer, closeDrawer, toggleDrawer] = useModal(false)
  const [selectedSlot, setSelectedSlot] = React.useState(null)
  const [showSelectDateFormInitialState, setShowSelectDateFormInitialState] = React.useState(false)

  const calendarSlots = useTalentCalendarSlots()
  const fetchCalendar = useFetchTalentCalendar(talentId)

  const calendarFilters = useTalentCalendarFilters()
  const clearFilters = useSetTalentCalendarFilters(() => INITIAL_STATE.TALENT_CALENDAR_FILTERS)

  const addTimeSlot = useSetTalentCalendarSlots((prev, slot) => [...prev, slot])
  const removeTimeSlot = useSetTalentCalendarSlots((prev, slotId) => prev.filter(slot => slot.id !== slotId))
  const updateTimeSlot = useSetTalentCalendarSlots((prev, slotId, updatedSlot) =>
    prev.map(slot => (slot.id === slotId ? { ...slot, ...updatedSlot } : slot))
  )

  const getRecurringOption = value => {
    const option = recurringTypesOptions.find(propEq('value', value))
    return option && { ...option, label: t(option.label) }
  }

  const getEventTypeOption = value => {
    const option = typeOptions.find(propEq('value', value))
    return option && { ...option, label: t(option.label) }
  }

  const initialState = {
    date: null,
    startTime: null,
    endTime: null,
    eventType: '',
    title: '',
    recurring: getRecurringOption(DOES_NOT_REPEAT),
    'repeat-on': '',
  }
  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: initialState
  })

  const drawerRef = React.useRef(null)

  React.useEffect(() => {
    isDrawerOpen && scrollToRef(drawerRef)
  }, [isDrawerOpen])

  const formState = methods.watch()
  React.useEffect(() => {
    if (isDraftSlot(selectedSlot) && !isSlotEditing(selectedSlot) && selectedSlot?.id && formState) {
      updateTimeSlot(selectedSlot.id, {
        startTime: applyTimeToDate(formState.date, formState.startTime),
        endTime: applyTimeToDate(formState.date, formState.endTime),
        title: formState.title,
        type: formState.eventType.value,
      })
    }
  }, [formState, selectedSlot])

  const createSlot = event => {
    const start = event.start
    const end = event.end

    methods.setValue('date', start)
    methods.setValue('startTime', start)
    methods.setValue('endTime', end)

    const draftEventTimeSlot = createDraftEventTimeSlot(event)
    addTimeSlot(draftEventTimeSlot)
    setSelectedSlot(draftEventTimeSlot)
  }

  const onAddEventClick = () => {
    setShowSelectDateFormInitialState(true)
    createSlot({})
    toggleDrawer()
  }

  // calendar handler
  const onAllow = event => {
    return isFuture(event.start)
  }

  const onSelect = event => {
    methods.reset(initialState)

    if (isDraftSlot(selectedSlot) && !isSlotEditing(selectedSlot)) {
      removeTimeSlot(selectedSlot.id)
    }

    createSlot(event)
    openDrawer()
  }

  const onChange = info => {
    const start = info.event.start
    const end = info.event.end

    methods.setValue('date', start)
    methods.setValue('startTime', start)
    methods.setValue('endTime', end)
  }

  const onEventClick = eventInfo => {
    if (isDraftSlot(selectedSlot) && !isSlotEditing(selectedSlot)) {
      removeTimeSlot(selectedSlot.id)
    }

    setSelectedSlot(eventInfoAdapter(eventInfo))
    openDrawer()
  }

  // form handlers
  const onCreate = formValues => {
    createEvent(formValues, () => {
      updateTimeSlot(formValues.id, { isDraft: false })
      closeDrawer()
    })
  }

  const onEdit = () => {
    editEvent(
      { slotId: selectedSlot.id, data: omit(timeSlotAdapter(methods.getValues()), 'type') },
      () => {
        closeDrawer()
        closeEditModal()
        setSelectedSlot(null)
      }
    )
  }

  const onDelete = () => {
    deleteEvent({ slotId: selectedSlot.id }, () => {
      closeDrawer()
      closeDeleteModal()
    })
  }

  const onCancel = () => {
    if (selectedSlot && selectedSlot.id.startsWith('time-slot-')) {
      removeTimeSlot(selectedSlot.id)
    } else {
      const slot = calendarSlots.find(s => selectedSlot.id === s.id)
      updateTimeSlot(selectedSlot.id, slot)
    }
    methods.reset()
    closeDrawer()
    setSelectedSlot(null)
  }

  const onOpenEdit = () => {
    setSelectedSlot(prev => ({ ...prev, draft: true, editing: true }))
    updateTimeSlot(selectedSlot.id, { editable: true })

    const slot = calendarSlots.find(s => selectedSlot.id === s.id)

    methods.reset({
      date: new Date(slot.startTime),
      startTime: new Date(slot.startTime),
      endTime: new Date(slot.endTime),
      eventType: getEventTypeOption(slot.type),
      title: slot.title,
      recurring: getRecurringOption(slot.recurring?.type || DOES_NOT_REPEAT),
      ...parseRecurring(slot, t)
    })
  }

  const isAddEventButtonDisabled = !calendarSlots

  React.useEffect(() => {
    clearFilters()
  }, [talentId])

  React.useEffect(() => {
    fetchCalendar(calendarFilters)
  }, [calendarFilters, talentId])

  return (
    <FormProvider {...methods}>
      <div className="talent-calendar">
        <CalendarHeader
          text={t('calendar.talent.heading')}
          timeZoneAbbr={getTimezoneAbbr(timeZone)}
        />

        <Button
          className={'talent-calendar__add-event'}
          text={t('calendar.button.add-event')}
          handleOnClick={onAddEventClick}
          disabled={isAddEventButtonDisabled}
        />

        <div className="talent-calendar__wrapper">
          <div className="talent-calendar__sidebar">
            <CategoriesSidebar />

            <Drawer ref={drawerRef} isOpen={isDrawerOpen} close={onCancel} headerLabel={t(getDrawerHeader(selectedSlot))}>
              {selectedSlot && isDraftSlot(selectedSlot) && (
                <NewEventForm
                  talentId={talentId}
                  recurringId={selectedSlot?.recurringId}
                  showTypeSelect={!selectedSlot?.createdAt}
                  onCancel={onCancel}
                  showSelectDateFormInitialState={isSlotEditing(selectedSlot) || showSelectDateFormInitialState}
                  onSubmit={isSlotEditing(selectedSlot) ? openEditModal : onCreate}
                  submitBtnText={isSlotEditing(selectedSlot) && t('calendar.new-event-form.button.save')}
                  hour12Format={hour12Format}
                />
              )}

              {selectedSlot && !isDraftSlot(selectedSlot) && (
                <InfoSidebar {...selectedSlot} onEdit={onOpenEdit} onDelete={openDeleteModal} hour12Format={hour12Format} />
              )}
            </Drawer>
          </div>

          <div className={cx('talent-calendar_inner', { ['drawer-opened']: isDrawerOpen })}>
            <TalentBaseCalendar
              slots={calendarSlots}
              select={onSelect}
              eventChange={onChange}
              eventAllow={onAllow}
              selectAllow={onAllow}
              eventClick={onEventClick}
              hour12Format={hour12Format}
              setHour12Format={setHour12Format}
              talentId={talentId}
              initialDate={calendarFilters?.startTime}
              initialView={get(initialView, initialFormat, initialView.week)}
              {...talentCalendarConfig}
            />
          </div>
        </div>
      </div>

      <DeleteEventModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal} deleteHandler={onDelete} />
      <EditEventModal isOpen={isEditModalOpen} onClose={closeEditModal} editHandler={onEdit} />
    </FormProvider>
  )
}

export default TalentCalendar
