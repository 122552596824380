import React from 'react';
import PropTypes from 'prop-types';

import BasicActions from '_legacy/components/TalentPublicView/Slider/BasicActions';
import BookNowButton from '_legacy/components/TalentPublicView/Slider/components/BookNowButton';

import { VIDEO_CHAT } from '_legacy/constants/routes';

const Actions = ({ slidesCount, onNext, onPrev, plaqueNickname, offerSequentialId, isPromo }) => {
  const onRequestClick = () => {
    alert('This action isn\'t implemented');
  };

  return (
    <BasicActions
      slidesCount={slidesCount}
      onNext={onNext}
      onPrev={onPrev}
      topOffset={45}
      padding="0 14px 0 0"
      onRequestClick={onRequestClick}
    >
      <BookNowButton disabled={isPromo} margin="0" isLink path={`/${VIDEO_CHAT}/${plaqueNickname}/${offerSequentialId}`} />
    </BasicActions>
  );
};

Actions.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  slidesCount: PropTypes.number.isRequired,
  plaqueNickname: PropTypes.string.isRequired,
  offerSequentialId: PropTypes.number.isRequired,
};

export default Actions;
