// Modules
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { filter, prop } from 'lodash/fp'
import { isEqual } from 'date-fns'

// Assets
import Icon from 'assets/icons/Icon'

// Routing
import { routesList } from 'router/routesMap'

// Store (Hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useModal } from 'store/hooks/useModal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { updateLiveVirtualLessonTime, useFetchLiveVirtualLessonOrderDetails } from './state/requests'

import { useLiveVirtualLessonOrderDetails, useSetLiveVirtualLessonOrderDetails } from './state/store'

// Utils
import { getFullName, getSaveFullName, getUserLink, getUserTimeZone } from 'utils/user'
import { getDateTimeProps } from 'utils/date'
import { prepareDate } from 'utils/services/timezone'
import { getSteps } from 'components/OfferingDetails/components/StatusBar/utils/getSteps'
import {
  getCharitiesTitle, getComplaintText,
  getTalentRefundText,
  isDateFuture,
  isOccurrenceFullyBooked,
} from 'components/OfferingDetails/utils'

// Components
import StatusBar from 'components/OfferingDetails/components/StatusBar'
import PendingAction from 'components/OfferingDetails/components/PendingAction'
import BookingCalendar from 'components/Calendars/BookingCalendar'
import OrderUploadFiles from 'components/OfferingDetails/components/OrderUploadFiles'
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingDetailsCountdown from 'components/OfferingDetails/components/OfferingDetailsCountdown'
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'
import OfferingDetailsReviewStars from 'components/OfferingDetails/components/OfferingDetailsReviewStars'
import OfferingDetailsEscrowFunds from 'components/OfferingDetails/components/OfferingDetailsEscrowFunds'

// Legacy components
import Button from '_legacy/components/Button'
import UserInfo from '_legacy/components/UserInfo'
import PageLoader from '_legacy/common/Template/PageLoader'
import DashButton from '_legacy/components/Button/DashButton'
import { getLiveVirtualLessonOrderConfig } from './utils'
import ComplaintInfo from '../../components/ComplaintInfo'
import CancelOrderButton from '../../components/CancelOrderButton'
import CancelStatus from '../../components/CancelStatus'

const LiveVirtualLessonOrderDetails = () => {
  const t = useGetTranslate()
  const { orderId: occurrenceId } = useParams()
  const history = useHistory()

  // state
  const talent = useUser(prop('talent'))
  const timeZone = useUser(getUserTimeZone)

  const occurrence = useLiveVirtualLessonOrderDetails()
  const clearOccurrence = useSetLiveVirtualLessonOrderDetails(() => null)

  const [orderSlot, setOrderSlot] = React.useState(null)

  const { review, order } = occurrence || {}

  // requests
  const fetchOccurrence = useFetchLiveVirtualLessonOrderDetails()
  const updateOrderTime = updateLiveVirtualLessonTime()

  const [isTalentCalendarModalOpen, openTalentCalendarModal, closeTalentCalendarModal] = useModal(false)

  // handlers
  const onCalendarConfirm = formData => {
    updateOrderTime({
      id: occurrenceId,
      startTime: prepareDate(formData?.start, timeZone),
      endTime: prepareDate(formData?.end, timeZone),
    })

    closeTalentCalendarModal()
  }

  const handleStartEvent = () => history.push(routesList.liveVirtualLessonRoom(occurrenceId))

  // effects
  React.useEffect(() => {
    fetchOccurrence()
  }, [])

  React.useEffect(() => {
    if (occurrence) {
      setOrderSlot({
        title: occurrence.title,
        start: occurrence.startTime,
        end: occurrence.endTime,
      })
    }
  }, [occurrence])

  React.useEffect(
    () => () => {
      clearOccurrence()
    },
    []
  )

  if (!occurrence) return <PageLoader />

  const {
    price,
    startTime: _startTime,
    user,
    title,
    userUploads,
    lengthOfTime,
    pendingAction,
    totalCharityFee,
  } = occurrence || {}

  // date/time
  const { formattedDate, formattedTimeRange, startTime, endTime } = getDateTimeProps({ ...occurrence, timeZone })

  const beforeTransform = filter(slot => !isEqual(new Date(slot.start), new Date(occurrence.startTime)))

  const c = getLiveVirtualLessonOrderConfig(occurrence)

  const cancelOrderButton = <CancelOrderButton orderId={order.id} onSuccess={fetchOccurrence} />
  const refundText = getTalentRefundText({ t, refundRequests: occurrence.order.refundRequests })
  const complaintText = getComplaintText({ complaints: occurrence.complaints })

  return (
    <div className="live-virtual-lesson-order od__margins">
      <OfferingDetailsTitle
        typeHeader={t('order-details.live-virtual-lesson.heading')}
        title={`${t('order-details.live-virtual-lesson.title')} ${getFullName(user)}`}
      />

      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <StatusBar steps={getSteps(occurrence, timeZone)} />
          {pendingAction && (
            <PendingAction action={pendingAction} title={t('order-details.live-virtual-lesson.heading')} />
          )}

          <div className="od__inner-wrapper">
            <div className="od__row od__column-m">
              <div className="od__column">
                {/* TODO fix first-block */}
                <div className="od__column">
                  <div className="mb-24">
                    <h6 className="od--uppercase-title mb-16">{t('order.video-message.aficionado')}</h6>
                    <UserInfo user={user} />
                  </div>

                  {startTime && endTime && (
                    <div className="od__row">
                      <OfferingsDetailsHeaderItem
                        icon={<Icon.Calendar />}
                        item={formattedDate}
                        subitem={formattedTimeRange}
                      />
                      {isDateFuture(startTime) && isOccurrenceFullyBooked(occurrence) && (
                        <div className="od__column">
                          <DashButton
                            className="mr-16"
                            onClick={openTalentCalendarModal}
                            text={t('order-details.virtual-chats.change-date')}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">
                  {t('order-details.live-virtual-lesson.submitted-by')} {user?.firstName}
                </h6>
                <p className="od--main-text od--accented">
                  <OrderUploadFiles files={userUploads} />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="od--shadow-box od--centered">
            {c.isShowCompleted && (
              <div className="od--shadow-box">
                <OfferingDetailsEscrowFunds
                  title={t('order-details.live-virtual-lesson.heading')}
                  subtitle={t('order.description.funds')}
                  price={price}
                  description={t('order.description.live-virtual-lesson')}
                />
                <OfferingDetailsReviewStars
                  orderId={order.id}
                  review={review}
                  onSuccess={fetchOccurrence}
                  userLink={getUserLink(user)}
                  userFullName={getSaveFullName(user)}
                  offerType={t('order-details.live-virtual-lesson.heading')}
                  reviewType={'order.answer.rate.with'}
                />
              </div>
            )}

            {c.isShowCalendar && (
              <div className="od__column od--with-padding">
                <p className="od--uppercase-title mb-24">{t('order-details.live-virtual-lesson.heading')}</p>
                <Button
                  handleOnClick={openTalentCalendarModal}
                  text={t('order-details.propose-date-text')}
                  iconComponent={<Icon.ViewCalendar fill="#fff" />}
                  typeButton="submit"
                  fontSize="0.875"
                  paddingVertical="16"
                  className="mb-16"
                />
                {cancelOrderButton}
              </div>
            )}

            {c.isShowCounter && (
              <div className="od--with-padding">
                <p className="od--uppercase-title">{t('order-details.live-virtual-lesson.heading')}</p>
                <OfferingDetailsCountdown
                  countdownDate={_startTime}
                  handleClick={handleStartEvent}
                  title={t('order-details.live-virtual-lesson.start-event')}
                />

                <div className="od__heading__buttons-wrapper">{cancelOrderButton}</div>
              </div>
            )}

            {c.isShowClosed && (
              <div className="od--centered">
                <OfferingDetailsEscrowFunds
                  title={t('order-details.live-virtual-lesson.heading')}
                  subtitle={t('order.description.received')}
                  price={order?.totalPrice}
                />
                {!!totalCharityFee && order?.totalPrice && (
                  <OfferingDetailsEscrowFunds
                    className="od--bordered"
                    subtitle={getCharitiesTitle(t, order?.totalPrice, totalCharityFee)}
                    price={totalCharityFee}
                    description={t('order.description.charities.text')}
                  />
                )}
                <OfferingDetailsReviewStars
                  orderId={order.id}
                  review={review}
                  onSuccess={fetchOccurrence}
                  userLink={getUserLink(user)}
                  userFullName={getSaveFullName(user)}
                  offerType={t('order-details.live-virtual-lesson.heading')}
                  reviewType={'order.answer.rate.with'}
                />
              </div>
            )}

            {c.isShowCanceled && (
              <CancelStatus
                refundText={refundText}
                complaintText={complaintText}
                title={t('shared.offers.virtual-lesson')}
              />
            )}
          </div>

          <ComplaintInfo
            orderId={order?.id}
            onSuccess={fetchOccurrence}
            userLink={getUserLink(user)}
            userName={getSaveFullName(user)}
          />
        </div>
      </div>

      <BookingCalendar
        isOpen={isTalentCalendarModalOpen}
        onClose={closeTalentCalendarModal}
        talentId={talent.id}
        eventDuration={lengthOfTime}
        eventTitle={title}
        minBookingTimeBeforeExperience={0}
        onConfirm={onCalendarConfirm}
        orderSlot={orderSlot}
        setOrderSlot={setOrderSlot}
        beforeTransform={beforeTransform}
      />
    </div>
  )
}

export default LiveVirtualLessonOrderDetails
