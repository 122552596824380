import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Row } from '_legacy/common/Template';
import LocationsSelect from '_legacy/components/Form/Selects/basic/LocationsSelect';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const FlexibleLocations = ({ name = 'locations' }) => {
  const t = useGetTranslate();
  const { setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({ name });
  const values = watch(name);

  const appendHandler = () => append({ label: null, value: null });

  React.useEffect(() => {
    if (fields.length === 0) {
      appendHandler();
    }
  }, [fields]);

  const removeHandler = (index) => {
    if (index === 0 && fields.length === 1) {
      setValue(name, [{ value: null, label: '' }]);
    } else {
      remove(index);
    }
  };

  const showRemoveButton = idx => {
    if (idx !== 0) return true;
    return !!values?.[0].value;
  };

  return (
    <>
      {fields.map((location, index) => (
        <FlexibleLocationsWrapper
          margin={(index !== fields.length - 1) || index === 4}
          key={location.id}
          nameBlock={`${t('create-experience.form.flexible-location.label')} ${index + 1}: *`}
          component={
            <div className='location-input-container'>
              <LocationsSelect
                usedLocations={values}
                name={`${name}.${index}`}
                placeholder={t('create-experience.form.flexible-location.placeholder')}
                defaultValue={location}
                rules={{ validate: value => !!value?.label === true || 'create-experience.form.flexible-location.errors.required' }}
              />
              {showRemoveButton(index) && (
                <button
                  className='form-row__remove form-row__remove--black'
                  type='button'
                  onClick={() => removeHandler(index)}
                  title={t('shared.words.remove')}
                />
              )}
            </div>
          }
        />
      ))}
      {fields.length < 5 && (
        <Row
          component={
            <button
              type="button"
              className="multiple-location__button"
              onClick={appendHandler}
            >
              + {t('create-experience.form.flexible-location.add-button')}
            </button>
          }
        />)}
    </>
  );
};

FlexibleLocations.propTypes = {
  name: PropTypes.string,
};

const FlexibleLocationsWrapper = styled(Row)`
  margin-bottom: ${({ margin }) => margin ? '50px' : 0};
`;


export default FlexibleLocations;
