import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import get from 'lodash/get';
import pick from 'lodash/pick';

import TalentInfoBlock from './TalentInfoBlock/';
import RatingAndMoney from './RatingAndMoney/';
import Biography from './Biography/';
import { useWindowDimensions } from 'store/hooks';
import DesktopSlidersBlock from './DesktopSlidersBlock/';
import MobileBlock from './MobileBlock/';
import FollowButton from '_legacy/components/Button/FollowButton'
import CalendarButton from '_legacy/components/Button/CalendarButton'
import PageLoader from '_legacy/common/Template/PageLoader';
import TalentReview from './TalentReview'
import TalentPublicViewCalendar from 'components/Calendars/TalentPublicViewCalendar'
import { toTalentPublicViewCalendarEvents } from 'components/Calendars/utils/adapters'
import { talentPublicViewCalendarSidebarFilters } from 'components/Calendars/configs/filters'

import { NOT_FOUND } from '_legacy/constants/routes';
import { useModal } from 'store/hooks/useModal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import './index.scss';

const TalentPublicView = ({ talentData, error, loading, currentUserPersonalData }) => {
  const t = useGetTranslate()
  const [isCalendarOpen, openCalendar, closeCalendar] = useModal(false)
  const { width } = useWindowDimensions(current => get(current, 'width'))

  // TODO: investigate do we need padding for that particular loader
  if (loading) return <PageLoader className="with-padding" />;

  if (error) return <Redirect to={NOT_FOUND} />;

  if (!talentData) return null;

  const {
    id,
    firstName,
    lastName,
    ownWords,
    biographies,
    rating,
    totalCharityFee
  } = talentData;
  const {
    isFollowed
  } = pick(currentUserPersonalData, ['isFollowed'])

  return (
    <div className="tpv-page__wrapper">
      <div className="tpv-page__title-wrapper">
        <h2 className="title--default">
          {firstName} <span>{lastName}</span>
        </h2>
        <div className='buttons'>
          <CalendarButton className="view-calendar-mode view-calendar" onClick={openCalendar} />
          <FollowButton
            id={id}
            type='TALENT'
            className='saved button-like light'
            isFollowed={isFollowed}
            text={t('shared.words.save')}
          />
        </div>
      </div>
      <div className="tpv-page__content">
        {width >= 1025
          ? <DesktopSlidersBlock talentData={talentData} openCalendar={openCalendar}  />
          : <MobileBlock width={width} talentData={talentData} />
        }
        {width >= 1025 &&
          <div className="tpv-page__content-bottom">
            <div className='tpv-page__content-bottom__column'>
              <TalentReview />
              <RatingAndMoney rating={rating} totalCharityFee={totalCharityFee} />
              <TalentInfoBlock talent={talentData} />
            </div>
            {ownWords && (<p className="tpv-page__description">{ownWords}</p>)}
          </div>
        }
        <Biography biographies={biographies} />
      </div>

      <TalentPublicViewCalendar
        header={`${firstName}'s Calendar`}
        onClose={closeCalendar}
        isOpen={isCalendarOpen}
        talentId={id}
        slotMapper={toTalentPublicViewCalendarEvents}
        options={talentPublicViewCalendarSidebarFilters}
      />
    </div>
  );
};

TalentPublicView.propTypes = {
  talentData: PropTypes.object,
  currentUserPersonalData: PropTypes.object,
  error: PropTypes.bool,
  loading: PropTypes.bool,
}

export default TalentPublicView;
