import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { appApi } from 'api';
import Button from '_legacy/components/Button';
import { useFetch } from 'store/hooks/useFetch';
import { sharedErrors } from '_legacy/common/Input/phoneErrorMessages';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const phoneConfirm = appApi.verify.phone.confirm;

const CodeConfirmButton = ({ disabled, fields }) => {
  const t = useGetTranslate();
  const { getValues, setValue, setError } = useFormContext();
  const [{ data, error }, sendCode] = useFetch(phoneConfirm);

  const onClick = () => {
    sendCode({
      phone: getValues(fields.phone),
      code: getValues(fields.confirm),
    });
  };

  React.useEffect(() => {
    if (data) {
      setValue(fields.verifyPhoneKey, data.key, { shouldValidate: true, shouldDirty: true });
      setValue(fields.isCodeConfirm, true, { shouldValidate: true });
      setValue(fields.oldPhone, getValues(fields.phone));
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      setError(fields.confirm, {
        type: 'server',
        message: t(sharedErrors[error.name]),
      });
    }
  }, [error]);

  return (
    <Button
      handleOnClick={onClick}
      text={t('register-user.form.phone.confirm.button')}
      paddingVertical="18"
      disabled={disabled}
    />
  );
};

CodeConfirmButton.propTypes = {
  fields: PropTypes.object,
  disabled: PropTypes.bool,
};

export default CodeConfirmButton;
