import { types } from 'shared'
import { compareAsc, isFuture, set } from 'date-fns'
import { pipe, filter, map, sortBy, uniqBy, propOr, cond, propEq, prop, gt, __, isEmpty } from 'lodash/fp'
import { getMonthDayYearDate } from 'utils/date'
import { getLocationFormattedAddress, getLocationName } from 'utils/location'

const { RECURRING_DAY_TIME_OPTION, UP_TO_5_DAY_TIME_OPTIONS } = types.experiences.DaysTimesType

export const filterTimeWindows = filter(window => isFuture(new Date(window.startTime)))

const buildRecurringTimeWindow = ({ availableRecurringDates, timeWindows }) => {
  const { endTime, startTime } = timeWindows[0]
  const endDate = new Date(endTime)
  const startDate = new Date(startTime)

  return availableRecurringDates.map(recurring => {
    return {
      date: recurring,
      endTime: set(new Date(recurring), { hours: endDate.getHours(), minutes: startDate.getMinutes() }),
      startTime: set(new Date(recurring), { hours: startDate.getHours(), minutes: startDate.getMinutes() }),
    }
  })
}

export const buildTimeWindow = pipe(
  cond([
    [propEq('daysTimesType', RECURRING_DAY_TIME_OPTION), buildRecurringTimeWindow],
    [propEq('daysTimesType', UP_TO_5_DAY_TIME_OPTIONS), propOr([], 'timeWindows')],
  ]),
  filterTimeWindows
)

export const buildInitialDates = pipe(
  buildTimeWindow,
  sortBy('date'),
  uniqBy('date'),
  map(window => ({
    value: window.date,
    label: getMonthDayYearDate(window.date),
  }))
)
export const buildOccurrences = ({ availableOccurrences = [], maxNumberOfParticipants, myBookedOccurrences = [] }) => {
  const bookedOccurrencesIds = (myBookedOccurrences || []).map(occurrence => occurrence.id)

  return (availableOccurrences || []).map(occurrence => ({
    id: occurrence.id,
    bookedLocation: occurrence.bookedLocation,
    participantsCount: occurrence.participantsCount,
    maxNumberOfParticipants: maxNumberOfParticipants,
    start: occurrence.startTime,
    end: occurrence.endTime,
    booked: bookedOccurrencesIds.includes(occurrence.id),
  }))
}

export const buildLocations = (locations = []) => {
  return (locations || []).map(location => ({
    value: location.id,
    title: getLocationName(location),
    label: getLocationFormattedAddress(location),
  }))
}

export const buildParticipantLabel = (count, max) => `${count} of ${max}`

export const buildSlots = ({ locations, availableOccurrences = [] }) => {
  return (availableOccurrences || [])
    .map(occurrence => ({
      ...occurrence,
      locations: occurrence.bookedLocation ? [occurrence.bookedLocation] : locations,
    }))
    .filter(occurrence => occurrence.startTime)
    .sort((leftOccurrence, rightOccurrence) =>
      compareAsc(new Date(leftOccurrence.startTime), new Date(rightOccurrence.startTime))
    )
}

const isMultipleLocation = pipe(
  prop('locations.length'),
  gt(__, 1)
)

const isAvailableOccurrencesEmpty = pipe(
  prop('availableOccurrences'),
  isEmpty,
)

export const getJoinButtonKey = experience => {
  return [
    'experience-public-view.select.',
    isMultipleLocation(experience) ? 'join-with-place' : 'join'
  ].join('')
}

export const getProposeButtonKey = experience => {
  return [
    'experience-public-view.select.',
    isAvailableOccurrencesEmpty(experience) ? 'propose' : 'propose-additional',
    isMultipleLocation(experience) ? '-with-place' : '',
  ].join('')
}

export const fillSelectedLocation = (selectedOccurrenceLocations = [], experienceLocations = []) => {
  return selectedOccurrenceLocations.map(selectedOccurrenceLocation =>
    experienceLocations.find(location => location.id === selectedOccurrenceLocation.id)
  )
}
